/*
Theme Name: Iteck     
Theme URI: https://themeforest.net/
Author: ThemesCamp 
Author URI:  http://themeforest.net/user/themescamp/ 
Description: Iteck is a Modern Software and Technology HTML Theme for agencies to show their creative works.
Version: 1.0.0     
*/
/* -----------------------------------------------------------
TABLE OF CONTENTS:-                                                                                                      
--------------------------------------------------------------
* variable
	- text colors
	- background colors
	- border colors
* global
	- Typography
	- To top style
	- inner page style  
	- font family
* helper         
	- font-weight       
	- font-size 
	- line-height
	- text-transform  
	- letter-spacing 
	- Overlay Effect Bg image     
	- margin-top
	- margin-bottom 
	- padding-top
	- padding-bottom
	- the spacing
	- social-media-colors
* buttons
* section title
* top navbar sections
* navbar sections
* header Sections
* about Sections
* services Sections
* choose-us Sections
* portfolio Sections
* testimonials Sections
* pricing Sections
* team Sections
* blog Sections
* faq Sections
* community Sections
* screenshots Sections
* features Sections
* numbers Sections
* clients Sections
* chat-banner Sections
* contact Sections
* download Sections
* footer Sections
* portfolio_page style
* single_project style
* services_page style
* about_page style
* contact_page style
* blog_page style
* shop_page style
* product_page style
* Responsive

------------------------------------------------------------*/
/* ===============================================================
			[ * variable Section ] 
================================================================*/
/* --------------- variable --------------- */
:root {
  --color-main:#0066ff;
  --color-primary:#0066ff;
  --color-secondary:#02b5ff;
  --color-blue2: #4a00e1;
  --color-blue4: #3a6cb4;
  --color-blue5: #157efb;
  --color-blue6: #6640f4;
  --color-blue7: #8169f1;
  --color-lightBlue:#15f1ff;
  --color-darkBlue:#010049;
  --color-darkBlue2:#1f227d;
  --color-horange1:#ff7a41;
  --color-orange2:#f6c463;
  --color-green:#00c057;
  --color-gray:#eef4f8;
  --color-gray2:#f0eff5;
  --color-main-grad: linear-gradient(to right, #0c3df4 0%, #02b5ff 100%); }

.color-main {
  color: var(--color-main) !important; }

.border-main {
  border-color: var(--color-main) !important; }

.bg-main {
  background-color: var(--color-main) !important;
  border-color: var(--color-main) !important; }

.color-lightBlue {
  color: var(--color-lightBlue) !important; }

.border-lightBlue {
  border-color: var(--color-lightBlue) !important; }

.bg-lightBlue {
  background-color: var(--color-lightBlue) !important; }

.color-darkBlue {
  color: var(--color-darkBlue) !important; }

.border-darkBlue {
  border-color: var(--color-darkBlue) !important; }

.bg-darkBlue {
  background-color: var(--color-darkBlue) !important; }

.hover-darkBlue:hover {
  color: var(--color-darkBlue) !important; }

.color-darkBlue2 {
  color: var(--color-darkBlue2) !important; }

.border-darkBlue2 {
  border-color: var(--color-darkBlue2) !important; }

.bg-darkBlue2 {
  background-color: var(--color-darkBlue2) !important; }

.hover-darkBlue2:hover {
  color: var(--color-darkBlue2) !important; }

.bg-gradient {
  background-image: var(--color-main-grad) !important; }

.bg-gray {
  background: var(--color-gray) !important; }

.bg-gray2 {
  background-color: var(--color-gray2) !important; }

.color-blue2 {
  color: var(--color-blue2) !important; }

.bg-blue2 {
  background-color: var(--color-blue2) !important; }

.border-blue2 {
  border-color: var(--color-blue2) !important; }

.color-blue4 {
  color: var(--color-blue4) !important; }

.bg-blue4 {
  background-color: var(--color-blue4) !important; }

.border-blue4 {
  border-color: var(--color-blue4) !important; }

.color-blue5 {
  color: var(--color-blue5) !important; }

.bg-blue5 {
  background-color: var(--color-blue5) !important; }

.border-blue5 {
  border-color: var(--color-blue5) !important; }

.color-blue6 {
  color: var(--color-blue6) !important; }

.bg-blue6 {
  background-color: var(--color-blue6) !important; }

.border-blue6 {
  border-color: var(--color-blue6) !important; }

.color-blue7 {
  color: var(--color-blue7) !important; }

.bg-blue7 {
  background-color: var(--color-blue7) !important; }

.border-blue7 {
  border-color: var(--color-blue7) !important; }

.hover-blue7 {
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease; }
  .hover-blue7:hover {
    background-color: var(--color-blue7) !important; }

.color-orange1 {
  color: var(--color-orange1) !important; }

.bg-orange1 {
  background-color: var(--color-orange1) !important; }

.border-orange1 {
  border-color: var(--color-orange1) !important; }

.hover-orange1 {
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease; }
  .hover-orange1:hover {
    background-color: var(--color-orange1) !important; }

.color-orange2 {
  color: var(--color-orange2) !important; }

.bg-orange2 {
  background-color: var(--color-orange2) !important; }

.border-orange2 {
  border-color: var(--color-orange2) !important; }

.hover-orange2 {
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease; }
  .hover-orange2:hover {
    background-color: var(--color-orange2) !important; }

.color-green {
  color: var(--color-green) !important; }

.bg-green {
  background-color: var(--color-green) !important; }

.border-green {
  border-color: var(--color-green) !important; }

.hover-green {
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease; }
  .hover-green:hover {
    background-color: var(--color-green) !important; }
    .hover-green:hover * {
      color: #fff; }

/* ===============================================================
			[ * global Section ] 
================================================================*/
/* --------------- global --------------- */
*,
* button:focus {
  outline: 0; }

html::-webkit-scrollbar {
  height: 20px;
  width: 10px;
  background: #f1f1f1;
  border-radius: 10px; }

html::-webkit-scrollbar-thumb {
  background: #999;
  -webkit-border-radius: 0;
  border-radius: 10px; }

html::-webkit-scrollbar-corner {
  background: #999;
  border-radius: 10px; }

body {
  font-family: "Inter", sans-serif;
  text-decoration: none;
  font-size: 0.9em;
  overflow-x: hidden;
  position: relative;
  font-weight: 400;
  line-height: 1.5; }
  body.style-6 {
    padding: 30px 30px 0; }

.home-style-7 {
  color: #000; }
  .home-style-7 a:hover {
    color: var(--color-blue7); }
  .home-style-7 #preloader:after,
  .home-style-7 #preloader:before {
    background: -webkit-gradient(linear, right top, left top, from(#501e9c), color-stop(30%, #8169f1), color-stop(30%, #8169f1), color-stop(73%, #a44cee), to(#ff847f));
    background: -webkit-linear-gradient(right, #501e9c 0%, #8169f1 30%, #8169f1 30%, #a44cee 73%, #ff847f 100%);
    background: -o-linear-gradient(right, #501e9c 0%, #8169f1 30%, #8169f1 30%, #a44cee 73%, #ff847f 100%);
    background: linear-gradient(to left, #501e9c 0%, #8169f1 30%, #8169f1 30%, #a44cee 73%, #ff847f 100%); }

.home-style-8 {
  color: #000; }
  .home-style-8 a:hover {
    color: var(--color-main); }

.home-style-11 .btn * {
  text-transform: uppercase;
  font-weight: 400; }

.container,
.container-fluid {
  position: relative;
  z-index: 5; }

.container, .container-lg, .container-md, .container-sm, .container-xl {
  max-width: 1170px;
  padding-right: 15px;
  padding-left: 15px; }

.row:not(.gx-0):not(.gx-1):not(.gx-2):not(.gx-3):not(.gx-4):not(.gx-5) {
  margin-right: -15px;
  margin-left: -15px; }
  .row:not(.gx-0):not(.gx-1):not(.gx-2):not(.gx-3):not(.gx-4):not(.gx-5) > * {
    padding-right: 15px;
    padding-left: 15px; }

h1,
h2,
h3,
h4 {
  font-weight: 700;
  margin: 0;
  line-height: 1.4; }

h5,
h6 {
  font-weight: 500;
  margin: 0;
  line-height: 1.4; }

body a:hover {
  text-decoration: none; }

body a:focus {
  outline: 0; }

body ul {
  list-style-type: none;
  margin: 0; }

.btn-default:active,
.btn-default:active:focus,
.btn-default:active:hover,
.btn-default:focus,
.btn-default:hover,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn:not(:disabled):not(.disabled).active,
.btn:not(:disabled):not(.disabled):active {
  -webkit-appearance: none; }

.btn:active,
.btn:active:focus,
.btn:active:hover,
.btn:focus,
.btn:hover,
.btn:not(:disabled):not(.disabled).active,
.btn:not(:disabled):not(.disabled):active {
  -webkit-transition: all 0.6s ease;
  -moz-transition: all 0.6s ease;
  -o-transition: all 0.6s ease;
  transition: all 0.6s ease;
  outline: 0; }

a,
span,
img {
  display: inline-block;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  color: inherit;
  text-decoration: none !important; }

ul {
  padding: 0; }

img {
  max-width: 100%;
  max-height: 100%; }

p {
  font-size: 14px;
  line-height: 1.7;
  margin: 0; }

.bi {
  display: inline-block; }

.text {
  font-size: 14px;
  line-height: 2; }

.small, small {
  font-size: 13px;
  line-height: 1.7;
  display: inline-block; }

small.text {
  font-size: 12px; }

.text-gray {
  color: #666; }

.bg-gray5 {
  background-color: #f3f7fe; }

.section-padding {
  padding: 100px 0; }

.swiper-pagination {
  width: 100%;
  bottom: 0; }

.pagination_circle .swiper-pagination-bullet {
  margin: 0 20px;
  position: relative; }
  .pagination_circle .swiper-pagination-bullet::before {
    position: absolute;
    content: "";
    left: -10px;
    top: -10px;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    border: 1px solid transparent; }
  .pagination_circle .swiper-pagination-bullet.swiper-pagination-bullet-active::before {
    border: 1px solid var(--color-lightBlue); }

.pagi_dark .swiper-pagination-bullet {
  background-color: #000; }

.pagi_white .swiper-pagination-bullet {
  background-color: #fff; }

.swiper-pagination-bullet {
  margin: 0 5px; }

.swiper-pagination-bullet-active {
  background-color: var(--color-main);
  border-radius: 5px; }

.swiper-button-next:after,
.swiper-button-prev:after {
  color: var(--color-main);
  font-size: 15px;
  font-weight: bold; }

.swiper-button-next,
.swiper-button-prev {
  width: 40px;
  height: 40px;
  border-radius: 5px;
  background: #fff; }
  .swiper-button-next:hover,
  .swiper-button-prev:hover {
    background: var(--color-main-grad); }
    .swiper-button-next:hover::after,
    .swiper-button-prev:hover::after {
      color: #fff; }

.slider-style-6 {
  position: relative; }
  .slider-style-6 .swiper-pagination-bullet-active {
    background-color: var(--color-blue6); }
  .slider-style-6 .swiper-button-next,
  .slider-style-6 .swiper-button-prev {
    border-radius: 50%;
    width: 45px;
    height: 45px; }
    .slider-style-6 .swiper-button-next:hover,
    .slider-style-6 .swiper-button-prev:hover {
      background: var(--color-blue6);
      -webkit-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      transition: all 0.3s ease; }
  .slider-style-6 .swiper-button-next, .slider-style-6 .swiper-container-rtl .swiper-button-prev {
    right: -60px; }
  .slider-style-6 .swiper-button-prev, .slider-style-6 .swiper-container-rtl .swiper-button-next {
    left: -60px; }

/* --------------- dropdown --------------- */
.dropdown-toggle::after {
  position: relative;
  top: 3px;
  opacity: 0.8;
  content: "\f078";
  font-weight: 900;
  font-family: "Font Awesome 5 pro";
  border: 0;
  font-size: 80%; }

.dropdown-menu {
  min-width: 170px;
  padding: 15px 0;
  border-radius: 15px;
  border: 0;
  -webkit-box-shadow: 0 0 20px #0001;
  box-shadow: 0 0 20px #0001;
  top: 100%; }
  .dropdown-menu .dropdown-item {
    padding: 8px 20px;
    font-size: 12px; }

.form-control:focus {
  -webkit-box-shadow: none;
  box-shadow: none; }

.form-switch .form-check-input {
  margin: 0 15px !important;
  width: 2.5em !important;
  height: 1.3em;
  -webkit-box-shadow: none;
  box-shadow: none;
  cursor: pointer; }
  .form-switch .form-check-input.color-lightBlue:checked {
    background-color: var(--color-lightBlue);
    border-color: var(--color-lightBlue);
    background-image: url(../img/icons/toggle_dot.svg); }

/* --------------- Start Preloader --------------- */
.pace {
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 99999999999999;
  position: fixed;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 400px;
  border: 0px;
  height: 1px;
  overflow: hidden;
  background: rgba(255, 255, 255, 0.05);
  -webkit-transition: all 1s;
  -o-transition: all 1s;
  transition: all 1s;
  pointer-events: none; }

.pace .pace-progress {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  max-width: 300px;
  position: fixed;
  z-index: 99999999999999;
  display: block;
  position: absolute;
  top: 0;
  right: 100%;
  height: 100%;
  width: 100%;
  background: #fff; }

.pace.pace-inactive {
  width: 100vw;
  opacity: 0; }

.pace.pace-inactive .pace-progress {
  max-width: 100vw; }

#preloader {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: fixed;
  z-index: 9999999;
  pointer-events: none; }

#preloader:after,
#preloader:before {
  content: "";
  position: fixed;
  left: 0;
  height: 50%;
  width: 100%;
  background: -webkit-gradient(linear, left top, right top, from(#0c3df4), to(#02b5ff));
  background: -webkit-linear-gradient(left, #0c3df4 0%, #02b5ff 100%);
  background: -o-linear-gradient(left, #0c3df4 0%, #02b5ff 100%);
  background: linear-gradient(to right, #0c3df4 0%, #02b5ff 100%);
  -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  -o-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }

#preloader:before {
  top: 0; }

#preloader:after {
  bottom: 0; }

#preloader.isdone {
  visibility: hidden;
  -webkit-transition-delay: 1.5s;
  -o-transition-delay: 1.5s;
  transition-delay: 1.5s; }

#preloader.isdone:after,
#preloader.isdone:before {
  height: 0;
  -webkit-transition: all 0.7s cubic-bezier(1, 0, 0.55, 1);
  -o-transition: all 0.7s cubic-bezier(1, 0, 0.55, 1);
  transition: all 0.7s cubic-bezier(1, 0, 0.55, 1);
  -webkit-transition-delay: 1s;
  -o-transition-delay: 1s;
  transition-delay: 1s; }

.loading {
  position: fixed;
  left: 50%;
  top: calc(50% - 40px);
  -webkit-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  font-weight: 300;
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 10px;
  z-index: 9999999999; }

.loading.isdone {
  top: 50%;
  opacity: 0;
  -webkit-transition: all 0.8s;
  -o-transition: all 0.8s;
  transition: all 0.8s;
  -webkit-transition-delay: 0.5s;
  -o-transition-delay: 0.5s;
  transition-delay: 0.5s; }

.loading span {
  -webkit-animation: loading 1.4s infinite alternate;
  animation: loading 1.4s infinite alternate; }

.loading span:nth-child(1) {
  -webkit-animation-delay: 0s;
  animation-delay: 0s; }

.loading span:nth-child(2) {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s; }

.loading span:nth-child(3) {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s; }

.loading span:nth-child(4) {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s; }

.loading span:nth-child(5) {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s; }

.loading span:nth-child(6) {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s; }

.loading span:nth-child(7) {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s; }

@-webkit-keyframes loading {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes loading {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

/* --------------- End Preloader --------------- */
/* --------------- to_top button --------------- */
.to_top {
  position: fixed;
  z-index: 9999;
  bottom: 30px;
  right: -50px;
  width: 35px;
  height: 35px;
  border-radius: 7px;
  background: var(--color-main-grad);
  color: #fff;
  font-size: 11px;
  text-align: center;
  padding: 7px;
  line-height: 1;
  opacity: 0;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  -webkit-box-shadow: 0 5px 10px #0002;
  box-shadow: 0 5px 10px #0002; }
  .to_top small {
    display: block;
    text-transform: uppercase;
    -webkit-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;
    font-size: 8px; }
  .to_top i {
    -webkit-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease; }
  .to_top.show {
    right: 30px;
    opacity: 1; }

/* --------------- pagination style --------------- */
.pagination.style-5 a {
  width: 30px;
  height: 30px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-width: -webkit-max-content;
  min-width: -moz-max-content;
  min-width: max-content;
  border: 1px solid #9993;
  border-radius: 8px;
  margin-right: 5px;
  font-size: 11px;
  color: #666; }
  .pagination.style-5 a .text {
    padding: 3px 10px;
    font-size: 11px; }

.pagination.color-5 a:hover {
  color: var(--color-blue5) !important;
  border-color: var(--color-blue5); }

.pagination.color-5 a.active {
  color: #fff;
  border-color: var(--color-blue5);
  background-color: var(--color-blue5); }

.pagination.color-4 a:hover {
  color: var(--color-blue4) !important;
  border-color: var(--color-blue4); }

.pagination.color-4 a.active {
  color: #fff;
  border-color: var(--color-blue4);
  background-color: var(--color-blue4); }

/**
 * ----------------------------------------
 * animation rotate-center
 * ----------------------------------------
*/
.rotate-center {
  -webkit-animation: rotate-center 100s linear infinite both;
  animation: rotate-center 100s linear infinite both; }

@-webkit-keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

/**
 * ----------------------------------------
 * animation slide_up_down
 * ----------------------------------------
*/
.slide_up_down {
  -webkit-animation: slide_up_down 1.7s ease-in-out infinite alternate both;
  animation: slide_up_down 1.7s ease-in-out infinite alternate both; }

/* ------- */
@-webkit-keyframes slide_up_down {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  100% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px); } }

@keyframes slide_up_down {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  100% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px); } }

/**
 * ----------------------------------------
 * animation wave
 * ----------------------------------------
*/
.wave {
  -webkit-animation: wave 2s ease-in-out infinite alternate both;
  animation: wave 2s ease-in-out infinite alternate both; }

/* ------- */
@-webkit-keyframes wave {
  0% {
    -webkit-transform: rotate(1deg);
    transform: rotate(1deg); }
  100% {
    -webkit-transform: rotate(-1deg);
    transform: rotate(-1deg); } }

@keyframes wave {
  0% {
    -webkit-transform: rotate(1deg);
    transform: rotate(1deg); }
  100% {
    -webkit-transform: rotate(-1deg);
    transform: rotate(-1deg); } }

/**
 * ----------------------------------------
 * animation scale_up_down
 * ----------------------------------------
*/
.scale_up_down {
  -webkit-animation: scale_up_down 1s ease-in-out infinite alternate both;
  animation: scale_up_down 1s ease-in-out infinite alternate both; }

/* ------- */
@-webkit-keyframes scale_up_down {
  0% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9); }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

@keyframes scale_up_down {
  0% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9); }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

.left_to_right_apperance {
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
  -webkit-transition: all .8s ease-in-out;
  -o-transition: all .8s ease-in-out;
  transition: all .8s ease-in-out;
  -webkit-transition-delay: .4s;
  -o-transition-delay: .4s;
  transition-delay: .4s;
  -webkit-clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
  clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%); }
  .left_to_right_apperance.animated {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%); }

/* ===============================================================
			[ * buttons Section ] 
================================================================*/
/* --------------- helper classes --------------- */
/* --------------- radius classes --------------- */
.radius-1 {
  border-radius: 3px !important; }

.radius-2 {
  border-radius: 5px !important; }

.radius-3 {
  border-radius: 8px !important; }

.radius-4 {
  border-radius: 10px !important; }

.radius-5 {
  border-radius: 12px !important; }

.radius-6 {
  border-radius: 15px !important; }

.radius-7 {
  border-radius: 20px !important; }

.radius-8 {
  border-radius: 25px !important; }

.radius-9 {
  border-radius: 30px !important; }

.radius-10 {
  border-radius: 50px !important; }

/* --------------- margin classes --------------- */
.mt-10 {
  margin-top: 10px !important; }

.mt-15 {
  margin-top: 15px !important; }

.mt-20 {
  margin-top: 20px !important; }

.mt-30 {
  margin-top: 30px !important; }

.mt-40 {
  margin-top: 40px !important; }

.mt-50 {
  margin-top: 50px !important; }

.mt-60 {
  margin-top: 60px !important; }

.mt-70 {
  margin-top: 70px !important; }

.mt-80 {
  margin-top: 80px !important; }

.mt-90 {
  margin-top: 90px !important; }

.mt-100 {
  margin-top: 100px !important; }

.mt-150 {
  margin-top: 150px !important; }

.mt-200 {
  margin-top: 200px !important; }

.mb-10 {
  margin-bottom: 10px !important; }

.mb-15 {
  margin-bottom: 15px !important; }

.mb-20 {
  margin-bottom: 20px !important; }

.mb-30 {
  margin-bottom: 30px !important; }

.mb-40 {
  margin-bottom: 40px !important; }

.mb-50 {
  margin-bottom: 50px !important; }

.mb-60 {
  margin-bottom: 60px !important; }

.mb-70 {
  margin-bottom: 70px !important; }

.mb-80 {
  margin-bottom: 80px !important; }

.mb-90 {
  margin-bottom: 90px !important; }

.mb-100 {
  margin-bottom: 100px !important; }

.mb-150 {
  margin-bottom: 150px !important; }

.mb-200 {
  margin-bottom: 200px !important; }

/* --------------- padding classes --------------- */
.pt-10 {
  padding-top: 10px !important; }

.pt-15 {
  padding-top: 15px !important; }

.pt-20 {
  padding-top: 20px !important; }

.pt-30 {
  padding-top: 30px !important; }

.pt-40 {
  padding-top: 40px !important; }

.pt-50 {
  padding-top: 50px !important; }

.pt-60 {
  padding-top: 60px !important; }

.pt-70 {
  padding-top: 70px !important; }

.pt-80 {
  padding-top: 80px !important; }

.pt-90 {
  padding-top: 90px !important; }

.pt-100 {
  padding-top: 100px !important; }

.pt-150 {
  padding-top: 150px !important; }

.pt-200 {
  padding-top: 200px !important; }

.pb-10 {
  padding-bottom: 10px !important; }

.pb-15 {
  padding-bottom: 15px !important; }

.pb-20 {
  padding-bottom: 20px !important; }

.pb-30 {
  padding-bottom: 30px !important; }

.pb-40 {
  padding-bottom: 40px !important; }

.pb-50 {
  padding-bottom: 50px !important; }

.pb-60 {
  padding-bottom: 60px !important; }

.pb-70 {
  padding-bottom: 70px !important; }

.pb-80 {
  padding-bottom: 80px !important; }

.pb-90 {
  padding-bottom: 90px !important; }

.pb-100 {
  padding-bottom: 100px !important; }

.pb-150 {
  padding-bottom: 150px !important; }

.pb-200 {
  padding-bottom: 200px !important; }

/* --------------- opacity classes --------------- */
.op-1 {
  opacity: 0.1; }

.op-2 {
  opacity: 0.2; }

.op-3 {
  opacity: 0.3; }

.op-4 {
  opacity: 0.4; }

.op-5 {
  opacity: 0.5; }

.op-6 {
  opacity: 0.6; }

.op-7 {
  opacity: 0.7; }

.op-8 {
  opacity: 0.8; }

.op-9 {
  opacity: 0.9; }

.op-10 {
  opacity: 1; }

/* --------------- lineHeight classes --------------- */
.lh-1 {
  line-height: 1; }

.lh-2 {
  line-height: 1.2; }

.lh-3 {
  line-height: 1.4; }

.lh-4 {
  line-height: 1.6; }

.lh-5 {
  line-height: 1.8; }

.lh-6 {
  line-height: 2; }

.lh-7 {
  line-height: 2.5; }

.lh-8 {
  line-height: 3; }

/* --------------- border color classes --------------- */
.brd-light {
  border-color: #fff4 !important; }

.brd-gray {
  border-color: #0002 !important; }

.bg-black {
  background-color: #000 !important; }

/* ---------------  color classes --------------- */
.color-000 {
  color: #000 !important; }

.color-111 {
  color: #111 !important; }

.color-222 {
  color: #222 !important; }

.color-333 {
  color: #333 !important; }

.color-444 {
  color: #444 !important; }

.color-555 {
  color: #555 !important; }

.color-666 {
  color: #666 !important; }

.color-777 {
  color: #777 !important; }

.color-888 {
  color: #888 !important; }

.color-999 {
  color: #999 !important; }

/* --------------- icons size --------------- */
.icon-10 {
  width: 10px;
  height: 10px; }

.icon-15 {
  width: 15px;
  height: 15px; }

.icon-20 {
  width: 20px;
  height: 20px; }

.icon-25 {
  width: 25px;
  height: 25px; }

.icon-30 {
  width: 30px;
  height: 30px; }

.icon-35 {
  width: 35px;
  height: 35px; }

.icon-40 {
  width: 40px;
  height: 40px; }

.icon-45 {
  width: 45px;
  height: 45px; }

.icon-50 {
  width: 50px;
  height: 50px; }

.icon-55 {
  width: 55px;
  height: 55px; }

.icon-60 {
  width: 60px;
  height: 60px; }

.icon-65 {
  width: 65px;
  height: 65px; }

.icon-70 {
  width: 70px;
  height: 70px; }

.icon-75 {
  width: 75px;
  height: 75px; }

.icon-80 {
  width: 80px;
  height: 80px; }

/* --------------- img classes --------------- */
.img-cover {
  display: block; }
  .img-cover img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover; }

.img-contain {
  display: block; }
  .img-contain img {
    width: 100%;
    height: 100%;
    -o-object-fit: contain;
    object-fit: contain; }

.imgrotate-scale-hover {
  overflow: hidden;
  display: block; }
  .imgrotate-scale-hover img {
    -webkit-transition: all 2s ease;
    -o-transition: all 2s ease;
    transition: all 2s ease; }
  .imgrotate-scale-hover:hover img {
    -webkit-transform: rotate(-10deg) scale(1.2);
    -ms-transform: rotate(-10deg) scale(1.2);
    transform: rotate(-10deg) scale(1.2); }

/* --------------- letter spacing classes --------------- */
.ltspc-1 {
  letter-spacing: 1px; }

.ltspc-2 {
  letter-spacing: 2px; }

.ltspc-3 {
  letter-spacing: 3px; }

.ltspc-4 {
  letter-spacing: 4px; }

.ltspc-5 {
  letter-spacing: 5px; }

.ltspc-8 {
  letter-spacing: 8px; }

.ltspc-10 {
  letter-spacing: 10px; }

.ltspc-12 {
  letter-spacing: 12px; }

.ltspc-15 {
  letter-spacing: 15px; }

.ltspc-17 {
  letter-spacing: 17px; }

.ltspc-20 {
  letter-spacing: 20px; }

.fs-10px {
  font-size: 10px !important; }

.fs-12px {
  font-size: 12px !important; }

.fs-14px {
  font-size: 14px !important; }

/* ===============================================================
			[ * buttons Section ] 
================================================================*/
/* --------------- buttons styles --------------- */
.butn,
.btn {
  padding: 12px 30px;
  position: relative;
  overflow: hidden;
  text-align: center;
  font-weight: 500;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  text-align: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center; }
  .butn span,
  .btn span {
    font-size: 14px;
    position: relative;
    z-index: 2;
    text-transform: capitalize; }
  .butn small,
  .btn small {
    font-size: 12px;
    position: relative;
    z-index: 2;
    text-transform: capitalize; }
  .butn.butn-gard::before,
  .btn.butn-gard::before {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    width: 150%;
    height: 100%;
    z-index: 1;
    background: -webkit-gradient(linear, left top, right top, color-stop(10%, #0c3df4), color-stop(45%, #02b5ff), color-stop(#02b5ff), to(#0c3df4));
    background: -webkit-linear-gradient(left, #0c3df4 10%, #02b5ff 45%, #02b5ff, #0c3df4);
    background: -o-linear-gradient(left, #0c3df4 10%, #02b5ff 45%, #02b5ff, #0c3df4);
    background: linear-gradient(to right, #0c3df4 10%, #02b5ff 45%, #02b5ff, #0c3df4);
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease; }
  .butn.butn-gard:hover::before,
  .btn.butn-gard:hover::before {
    left: -50%; }
  .butn.dark-butn,
  .btn.dark-butn {
    background: #000; }
    .butn.dark-butn span,
    .butn.dark-butn small,
    .btn.dark-butn span,
    .btn.dark-butn small {
      color: #fff; }
  .butn.butn-blue6,
  .btn.butn-blue6 {
    background: var(--color-blue6); }
    .butn.butn-blue6 span,
    .butn.butn-blue6 small,
    .btn.butn-blue6 span,
    .btn.butn-blue6 small {
      color: #fff; }
  .butn.blue5-3Dbutn,
  .btn.blue5-3Dbutn {
    background: var(--color-blue5);
    -webkit-box-shadow: 0px 4px 0px 0px var(--color-blue4);
    box-shadow: 0px 4px 0px 0px var(--color-blue4);
    -webkit-filter: drop-shadow(0 13px 20px #07397235);
    filter: drop-shadow(0 13px 20px #07397235);
    font-size: 12px; }
    .butn.blue5-3Dbutn span,
    .butn.blue5-3Dbutn small,
    .btn.blue5-3Dbutn span,
    .btn.blue5-3Dbutn small {
      color: #fff; }
  .butn.sm-butn,
  .btn.sm-butn {
    padding: 10px 25px; }
    .butn.sm-butn span,
    .btn.sm-butn span {
      font-size: 12px; }
  .butn:hover,
  .btn:hover {
    background: var(--color-main);
    border-color: transparent !important; }
    .butn:hover span,
    .btn:hover span {
      color: #fff; }
  .butn.hover-darkBlue:hover,
  .btn.hover-darkBlue:hover {
    background: var(--color-darkBlue);
    border-color: transparent !important; }
    .butn.hover-darkBlue:hover span,
    .butn.hover-darkBlue:hover small,
    .btn.hover-darkBlue:hover span,
    .btn.hover-darkBlue:hover small {
      color: #fff; }
  .butn.hover-blue2:hover,
  .btn.hover-blue2:hover {
    background: var(--color-blue2);
    border-color: transparent !important; }
    .butn.hover-blue2:hover span,
    .butn.hover-blue2:hover small,
    .btn.hover-blue2:hover span,
    .btn.hover-blue2:hover small {
      color: #fff; }
  .butn.hover-blue4:hover,
  .btn.hover-blue4:hover {
    background: var(--color-blue4);
    border-color: transparent !important; }
    .butn.hover-blue4:hover span,
    .butn.hover-blue4:hover small,
    .btn.hover-blue4:hover span,
    .btn.hover-blue4:hover small {
      color: #fff; }
  .butn.hover-blue5:hover,
  .btn.hover-blue5:hover {
    background: var(--color-blue5) !important;
    border-color: transparent !important; }
    .butn.hover-blue5:hover span,
    .butn.hover-blue5:hover small,
    .btn.hover-blue5:hover span,
    .btn.hover-blue5:hover small {
      color: #fff; }
  .butn.hover-lightBlue:hover,
  .btn.hover-lightBlue:hover {
    background: var(--color-lightBlue);
    border-color: transparent !important; }
    .butn.hover-lightBlue:hover span,
    .butn.hover-lightBlue:hover small,
    .btn.hover-lightBlue:hover span,
    .btn.hover-lightBlue:hover small {
      color: var(--color-darkBlue); }
  .butn.btn-icon-circle,
  .btn.btn-icon-circle {
    position: relative;
    padding-right: 50px;
    text-transform: capitalize; }
    .butn.btn-icon-circle i,
    .btn.btn-icon-circle i {
      position: absolute;
      right: -43px;
      bottom: -5px;
      width: 30px;
      height: 30px;
      display: -webkit-inline-box;
      display: -ms-inline-flexbox;
      display: inline-flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      color: #000;
      background-color: #fff;
      border-radius: 50%;
      font-size: 16px; }
    .butn.btn-icon-circle:hover,
    .btn.btn-icon-circle:hover {
      -webkit-box-shadow: 0 5px 10px #0003;
      box-shadow: 0 5px 10px #0003; }
      .butn.btn-icon-circle:hover i,
      .btn.btn-icon-circle:hover i {
        -webkit-animation: RL_smooth 1s ease-in-out infinite alternate both;
        animation: RL_smooth 1s ease-in-out infinite alternate both; }
  .butn.hover-shadow,
  .btn.hover-shadow {
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease; }
    .butn.hover-shadow:hover,
    .btn.hover-shadow:hover {
      -webkit-box-shadow: 0 5px 10px #0003;
      box-shadow: 0 5px 10px #0003; }

.scale_up_down {
  -webkit-animation: RL_smooth 1s ease-in-out infinite alternate both;
  animation: RL_smooth 1s ease-in-out infinite alternate both; }

@-webkit-keyframes RL_smooth {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0); }
  50% {
    -webkit-transform: translateX(5px);
    transform: translateX(5px); }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0); } }

@keyframes RL_smooth {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0); }
  50% {
    -webkit-transform: translateX(5px);
    transform: translateX(5px); }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0); } }

/* ===============================================================
			[ * section title ] 
================================================================*/
/* --------------- section head styles --------------- */
.section-head h6 {
  font-size: 13px;
  letter-spacing: 2px;
  margin-bottom: 20px; }

.section-head.style-3 {
  margin-bottom: 50px; }
  .section-head.style-3 h3 {
    font-size: 27px;
    text-transform: capitalize; }
    .section-head.style-3 h3 span {
      font-weight: 400;
      color: var(--color-blue2);
      font-style: italic;
      position: relative; }
      .section-head.style-3 h3 span::after {
        position: absolute;
        content: "";
        background-image: url(../img/section_head/title_shap1.png);
        left: 0;
        bottom: -15px;
        width: calc(100% + 40px);
        height: 30px;
        background-size: contain;
        background-repeat: no-repeat; }
  .section-head.style-3.long-shape h3 span::after {
    background-image: url(../img/section_head/title_shap2.png);
    bottom: -15px; }

/* --------------- section style-4 --------------- */
.section-head.style-4 .title_small {
  font-size: 12px;
  padding: 5px 10px;
  background-color: #f7f4ff;
  border-radius: 5px;
  color: var(--color-blue4);
  margin-bottom: 20px; }

.section-head.style-4 h2 {
  font-size: 40px;
  line-height: 1.3;
  color: #000; }
  .section-head.style-4 h2 span {
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    /* background-image: -webkit-gradient(linear, left top, right top, from(#501e9c), color-stop(30%, #8169f1), color-stop(30%, #8169f1), color-stop(73%, #a44cee), to(#ff847f));
    background-image: -webkit-linear-gradient(left, #501e9c 0%, #8169f1 30%, #8169f1 30%, #a44cee 73%, #ff847f 100%);
    background-image: -o-linear-gradient(left, #501e9c 0%, #8169f1 30%, #8169f1 30%, #a44cee 73%, #ff847f 100%);
    background-image: linear-gradient(to right, #501e9c 0%, #8169f1 30%, #8169f1 30%, #a44cee 73%, #ff847f 100%);  */
    background-image: linear-gradient(to right, #3a6cb4 0%, #30b6c5 100%);
  }

.section-head.style-4 h5 {
  font-size: 16px;
  font-weight: bold;
  color: #a44cee;
  display: inline-block;
  text-transform: capitalize;
  margin: 0 10px; }

/* --------------- section style-5 --------------- */
.section-head.style-5 h2 {
  font-size: 45px; }
  .section-head.style-5 h2 span {
    color: var(--color-blue5);
    position: relative;
    display: inline-block;
    padding: 0 5px;
    margin: 0 5px; }
    .section-head.style-5 h2 span::after {
      position: absolute;
      content: "";
      left: 0;
      bottom: 0;
      width: 100%;
      height: 40%;
      background-color: var(--color-blue5);
      opacity: 8%; }

.section-head.style-5 p {
  color: #666;
  font-size: 15px; }

/* --------------- section style-6 --------------- */
.section-head.style-6 h2 {
  color: #000;
  font-size: 45px; }
  .section-head.style-6 h2 span {
    position: relative;
    padding: 10px 20px; }
    .section-head.style-6 h2 span small {
      position: relative;
      z-index: 2;
      font-size: 45px;
      color: #fff; }
    .section-head.style-6 h2 span::before {
      position: absolute;
      content: "";
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      background-image: url(../img/shap_style_6.png);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      z-index: 1; }

/* --------------- section style-8 --------------- */
.section-head.style-8 h6 {
  font-weight: normal;
  font-size: 14px;
  color: var(--color-main);
  text-decoration: underline;
  text-transform: uppercase;
  margin-bottom: 10px; }

.section-head.style-8 h3 {
  font-size: 40px;
  text-transform: capitalize; }

/* --------------- section style-9 --------------- */
.section-head.style-9 h6 {
  letter-spacing: 0.5s;
  text-transform: uppercase;
  color: var(--color-darkBlue2);
  display: inline-block;
  position: relative;
  font-weight: bold;
  padding: 0 25px; }
  .section-head.style-9 h6::after {
    position: absolute;
    content: "";
    right: 0;
    top: 4px;
    width: 9px;
    height: 9px;
    background-color: #6c41ff;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg); }
  .section-head.style-9 h6::before {
    position: absolute;
    content: "";
    left: 0;
    top: 4px;
    width: 9px;
    height: 9px;
    background-color: #6c41ff;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg); }

.section-head.style-9 h2 {
  font-size: 40px;
  color: var(--color-darkBlue2);
  margin-bottom: 15px; }

.section-head.style-9 p {
  font-size: 18px;
  color: #666666; }

/* ===============================================================
			[ * top navbar Section ] 
================================================================*/
.top-navbar {
  z-index: 9999; }

/* --------------- top navbar styles --------------- */
.top-navbar.style-1 {
  background: var(--color-main-grad);
  position: relative;
  z-index: 9999; }
  .top-navbar.style-1 .top-links .text {
    font-size: 11px; }
    .top-navbar.style-1 .top-links .text strong {
      margin: 0 10px; }
    .top-navbar.style-1 .top-links .text .fa-bullhorn {
      background-color: #002ec1;
      padding: 15px; }
  .top-navbar.style-1 .r-side {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end; }
    .top-navbar.style-1 .r-side .socail-icons {
      border-right: 1px solid #fff4;
      padding-right: 10px;
      margin-right: 14px; }
      .top-navbar.style-1 .r-side .socail-icons a {
        color: #fff;
        margin: 0 5px;
        font-size: 12px; }
    .top-navbar.style-1 .r-side .dropdown {
      color: #fff;
      font-size: 11px; }
      .top-navbar.style-1 .r-side .dropdown img {
        width: 15px;
        height: 15px;
        border-radius: 50%;
        -o-object-fit: cover;
        object-fit: cover; }

/* --------------- top navbar style-4 --------------- */
.top-navbar.style-4 {
  background-image: url(../img/top_nav_s4.png);
  background-size: cover;
  background-position: top;
  padding: 10px 15px 50px;
  text-align: center; }

/* --------------- top navbar style-5 --------------- */
.top-navbar.style-5 {
  padding: 10px 15px;
  text-align: center;
  background-color: var(--color-blue5); }

/* --------------- top navbar style-7 --------------- */
.top-navbar.style-7 {
  padding: 15px 0;
  border-bottom: 1px solid #9993;
  position: relative;
  z-index: 9991; }

/* --------------- top navbar style 9 --------------- */
.top-navbar.style-9 {
  color: #fff;
  background-color: #172b86;
  padding: 10px 0;
  position: relative; }

/* --------------- top navbar style 11 --------------- */
.top-navbar.style-11 {
  position: relative;
  z-index: 9999; }
  .top-navbar.style-11 .top-nav-side {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end; }
    .top-navbar.style-11 .top-nav-side .social-links {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      color: #fff; }
      .top-navbar.style-11 .top-nav-side .social-links a {
        color: #999;
        -webkit-margin-start: 15px;
        margin-inline-start: 15px; }
        .top-navbar.style-11 .top-nav-side .social-links a:hover {
          color: var(--color-green); }

/* ===============================================================
			[ * top side menu Section ] 
================================================================*/
.side_menu.style-7 {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: var(--color-gray2);
  height: 100vh;
  width: 40%;
  min-width: -webkit-max-content;
  min-width: -moz-max-content;
  min-width: max-content;
  opacity: 0;
  -webkit-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  transform: translateX(-100%);
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  pointer-events: none;
  border-top-right-radius: 100%;
  border-bottom-right-radius: 100%;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  z-index: 99999;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }
  .side_menu.style-7 .content {
    width: 100%; }
    .side_menu.style-7 .content .logo {
      padding: 30px 7vw;
      border-bottom: 1px solid #9993; }
      .side_menu.style-7 .content .logo img {
        width: 300px;
        max-width: 50%; }
    .side_menu.style-7 .content .side_foot {
      padding: 30px 7vw;
      border-top: 1px solid #9993; }
      .side_menu.style-7 .content .side_foot h5 {
        text-transform: uppercase;
        margin-bottom: 20px; }
  .side_menu.style-7 .pages_links {
    padding: 15px 7vw;
    height: calc(100vh - 240px);
    overflow: auto; }
    .side_menu.style-7 .pages_links::-webkit-scrollbar {
      height: 5px;
      width: 3px;
      background: #8169f122;
      border-radius: 10px; }
    .side_menu.style-7 .pages_links::-webkit-scrollbar-thumb {
      background: var(--color-blue7);
      -webkit-border-radius: 0;
      border-radius: 10px; }
    .side_menu.style-7 .pages_links::-webkit-scrollbar-corner {
      background: #8169f122;
      border-radius: 10px; }
    .side_menu.style-7 .pages_links li {
      opacity: 0;
      -webkit-clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
      clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%); }
      .side_menu.style-7 .pages_links li a {
        color: #222;
        font-size: calc(14px + 1.5vw);
        font-weight: bold;
        margin: 10px 0;
        position: relative;
        -webkit-transition: all 0.3s linear;
        -o-transition: all 0.3s linear;
        transition: all 0.3s linear;
        text-transform: capitalize; }
        .side_menu.style-7 .pages_links li a::after {
          position: absolute;
          content: "";
          left: 110%;
          top: 60%;
          width: 0;
          height: 2px;
          background-color: var(--color-blue7);
          -webkit-transition: all 0.3s ease-in-out;
          -o-transition: all 0.3s ease-in-out;
          transition: all 0.3s ease-in-out; }
        .side_menu.style-7 .pages_links li a:hover, .side_menu.style-7 .pages_links li a.active {
          color: var(--color-blue7); }
          .side_menu.style-7 .pages_links li a:hover::after, .side_menu.style-7 .pages_links li a.active::after {
            width: 50px; }
    .side_menu.style-7 .pages_links ul li {
      -webkit-transition: all 1s ease;
      -o-transition: all 1s ease;
      transition: all 1s ease; }
      .side_menu.style-7 .pages_links ul li:nth-of-type(1) {
        -webkit-transition-delay: 0.3s;
        -o-transition-delay: 0.3s;
        transition-delay: 0.3s; }
      .side_menu.style-7 .pages_links ul li:nth-of-type(2) {
        -webkit-transition-delay: 0.4s;
        -o-transition-delay: 0.4s;
        transition-delay: 0.4s; }
      .side_menu.style-7 .pages_links ul li:nth-of-type(3) {
        -webkit-transition-delay: 0.5s;
        -o-transition-delay: 0.5s;
        transition-delay: 0.5s; }
      .side_menu.style-7 .pages_links ul li:nth-of-type(4) {
        -webkit-transition-delay: 0.6s;
        -o-transition-delay: 0.6s;
        transition-delay: 0.6s; }
      .side_menu.style-7 .pages_links ul li:nth-of-type(5) {
        -webkit-transition-delay: 0.7s;
        -o-transition-delay: 0.7s;
        transition-delay: 0.7s; }
      .side_menu.style-7 .pages_links ul li:nth-of-type(6) {
        -webkit-transition-delay: 0.8s;
        -o-transition-delay: 0.8s;
        transition-delay: 0.8s; }
      .side_menu.style-7 .pages_links ul li:nth-of-type(7) {
        -webkit-transition-delay: 0.9s;
        -o-transition-delay: 0.9s;
        transition-delay: 0.9s; }
      .side_menu.style-7 .pages_links ul li:nth-of-type(8) {
        -webkit-transition-delay: 1s;
        -o-transition-delay: 1s;
        transition-delay: 1s; }
  .side_menu.style-7 .side_menu_cls {
    position: absolute;
    top: 40px;
    right: 30px; }
    .side_menu.style-7 .side_menu_cls:hover {
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg); }
  .side_menu.style-7.show {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
    pointer-events: all;
    border-radius: 0; }
    .side_menu.style-7.show li {
      opacity: 1;
      -webkit-clip-path: polygon(100% 0, 0 0, 0 100%, 100% 100%);
      clip-path: polygon(100% 0, 0 0, 0 100%, 100% 100%); }
  .side_menu.style-7.dark_theme {
    background-color: #000;
    color: #fff; }
    .side_menu.style-7.dark_theme .pages_links li a {
      color: #999; }
      .side_menu.style-7.dark_theme .pages_links li a::after {
        background: #999; }
      .side_menu.style-7.dark_theme .pages_links li a:hover {
        color: #fff; }
        .side_menu.style-7.dark_theme .pages_links li a:hover::after {
          background: #999; }
    .side_menu.style-7.dark_theme::-webkit-scrollbar {
      background: #fff3; }
    .side_menu.style-7.dark_theme::-webkit-scrollbar-thumb {
      background: #fff3; }
    .side_menu.style-7.dark_theme::-webkit-scrollbar-corner {
      background: #fff3; }

.side_overlay {
  position: fixed;
  z-index: 99998;
  width: 100vw;
  height: 100vh;
  background-color: #0009;
  opacity: 0;
  visibility: hidden;
  top: 0;
  left: 0;
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out; }
  .side_overlay.show {
    opacity: 1;
    visibility: visible; }

@media screen and (max-width: 480px) {
  .side_menu.style-7 {
    width: 100%; }
    .side_menu.style-7 .pages_links {
      height: calc(100vh - 273px); } }

/* ===============================================================
			[ * navbar Section ] 
================================================================*/
/* --------------- navbar styles --------------- */
.nav-container {
  z-index: 999; }

.navbar {
  z-index: 999; }
  .navbar .nav-link {
    cursor: pointer; }

.navbar.nav-scroll {
  background: #fff !important;
  -webkit-box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
  padding: 0;
  position: fixed !important;
  top: -100px !important;
  left: 0;
  width: 100%;
  -webkit-transition: -webkit-transform .5s;
  transition: -webkit-transform .5s;
  -o-transition: transform .5s;
  transition: transform .5s;
  transition: transform .5s, -webkit-transform .5s;
  -webkit-transform: translateY(100px);
  -ms-transform: translateY(100px);
  transform: translateY(100px);
  border-radius: 0 !important;
  margin: 0 !important; }

.navbar.style-1 {
  padding: 30px 0; }
  .navbar.style-1 .navbar-brand {
    margin-right: 40px; }
  .navbar.style-1 .navbar-nav .nav-item .nav-link {
    font-size: 12px;
    color: #000;
    font-weight: 700;
    line-height: 2;
    margin: 0 7px;
    text-transform: capitalize; }
    .navbar.style-1 .navbar-nav .nav-item .nav-link.active {
      color: var(--color-main); }
  .navbar.style-1 .nav-side {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
    .navbar.style-1 .nav-side .hotline {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      border-right: 1px solid #9993; }
      .navbar.style-1 .nav-side .hotline .icon {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        background: var(--color-main-grad);
        color: #fff;
        font-size: 13px;
        -ms-flex-negative: 0;
        flex-shrink: 0; }
      .navbar.style-1 .nav-side .hotline .cont h6 {
        color: #000;
        font-size: 15px;
        font-weight: bold; }
  .navbar.style-1 .qoute-nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
    .navbar.style-1 .qoute-nav a .cart-num {
      font-size: 9px;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      color: #fff;
      background: var(--color-main-grad);
      display: -webkit-inline-box;
      display: -ms-inline-flexbox;
      display: inline-flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center; }

.navbar.style-2.nav-scroll {
  background-color: #010049 !important; }

.navbar.style-2 .navbar-brand img {
  width: 155px;
  -o-object-fit: contain;
  object-fit: contain; }

.navbar.style-2 .navbar-nav .nav-item .nav-link {
  font-size: 12px;
  color: #fff;
  font-weight: 500;
  line-height: 2;
  margin: 0;
  padding: 30px 30px;
  border-bottom: 2px solid transparent;
  position: relative; }
  .navbar.style-2 .navbar-nav .nav-item .nav-link::before {
    position: absolute;
    content: "";
    left: 0;
    bottom: 0;
    width: 100%;
    height: 0%;
    background-color: #fff2;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease; }
  .navbar.style-2 .navbar-nav .nav-item .nav-link.active, .navbar.style-2 .navbar-nav .nav-item .nav-link:hover {
    border-bottom: 2px solid var(--color-lightBlue); }
    .navbar.style-2 .navbar-nav .nav-item .nav-link.active::before, .navbar.style-2 .navbar-nav .nav-item .nav-link:hover::before {
      height: 100%; }

.navbar.style-2 .qoute-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }
  .navbar.style-2 .qoute-nav a {
    color: #fff; }
    .navbar.style-2 .qoute-nav a .cart-num {
      font-size: 9px;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      color: #000;
      background: var(--color-lightBlue);
      display: -webkit-inline-box;
      display: -ms-inline-flexbox;
      display: inline-flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center; }

.navbar.style-2 .dropdown-menu {
  background: #348CB2;
  border-radius: 0; }
  .navbar.style-2 .dropdown-menu .dropdown-item {
    color: #fff;
    padding: 8px 15px; }
    .navbar.style-2 .dropdown-menu .dropdown-item.active, .navbar.style-2 .dropdown-menu .dropdown-item:active, .navbar.style-2 .dropdown-menu .dropdown-item:hover {
      background-color: #010049; }

.navbar.style-3 {
  padding: 20px 0; }
  .navbar.style-3.nav-scroll {
    background-color: #4A00E1 !important; }
  .navbar.style-3 .navbar-brand img {
    width: 155px;
    -o-object-fit: contain;
    object-fit: contain; }
  .navbar.style-3 .navbar-nav .nav-item .nav-link {
    font-size: 11px;
    color: #fff;
    margin: 0 5px;
    padding: 10px 20px;
    border-radius: 30px; }
    .navbar.style-3 .navbar-nav .nav-item .nav-link.active, .navbar.style-3 .navbar-nav .nav-item .nav-link:hover {
      background-color: #0002; }
  .navbar.style-3 .nav-side .search-icon {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    border: 1px solid #fff6;
    color: #fff;
    font-size: 12px;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center; }

.navbar.style-4 {
  position: relative;
  z-index: 99;
  padding: 12px 15px;
  background-color: #fff;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  margin-top: -38px; }
  .navbar.style-4 .navbar-brand {
    width: 150px;
    -o-object-fit: contain;
    object-fit: contain; }
  .navbar.style-4 .navbar-nav .nav-item .nav-link {
    position: relative;
    color: #000;
    font-size: 12px;
    font-weight: bold;
    margin: 0 15px; }
    .navbar.style-4 .navbar-nav .nav-item .nav-link::before {
      position: absolute;
      content: "";
      top: -30px;
      left: 50%;
      -webkit-transform: translateX(-50%) translateY(-20px);
      -ms-transform: translateX(-50%) translateY(-20px);
      transform: translateX(-50%) translateY(-20px);
      width: 55px;
      height: 30px;
      background-image: url(../img/icons/nav_icon/active_s4.png);
      background-size: contain;
      background-repeat: no-repeat;
      opacity: 0;
      -webkit-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      transition: all 0.3s ease; }
    .navbar.style-4 .navbar-nav .nav-item .nav-link:hover, .navbar.style-4 .navbar-nav .nav-item .nav-link.active {
      color: var(--color-blue4); }
      .navbar.style-4 .navbar-nav .nav-item .nav-link:hover::before, .navbar.style-4 .navbar-nav .nav-item .nav-link.active::before {
        opacity: 1;
        -webkit-transform: translateX(-50%) translateY(0);
        -ms-transform: translateX(-50%) translateY(0);
        transform: translateX(-50%) translateY(0); }
    .navbar.style-4 .navbar-nav .nav-item .nav-link .hot {
      position: absolute;
      font-size: 8px;
      padding: 2px 3px;
      border-radius: 3px;
      top: -10px;
      right: 0;
      text-transform: uppercase; }
  .navbar.style-4 .nav-side .search-icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid #0002;
    color: #000;
    font-size: 16px;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center; }

.navbar.style-5 {
  position: relative;
  z-index: 99;
  padding: 20px 0;
  background-color: #e7f1ff; }
  .navbar.style-5 .container-fluid {
    padding: 0 3vw; }
  .navbar.style-5 .navbar-brand img {
    max-width: 150px;
    -o-object-fit: contain;
    object-fit: contain; }
  .navbar.style-5 .nav-item .nav-link {
    position: relative;
    color: #000;
    font-size: 12px;
    font-weight: bold;
    margin: 0 15px;
    text-transform: capitalize; }
    .navbar.style-5 .nav-item .nav-link:hover, .navbar.style-5 .nav-item .nav-link.active {
      color: var(--color-blue5); }
    .navbar.style-5 .nav-item .nav-link .bi {
      position: relative;
      bottom: -5px; }
  .navbar.style-5 .nav-side .search-icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid #0002;
    color: #000;
    font-size: 16px;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center; }

.navbar.style-6 {
  position: absolute;
  z-index: 99;
  padding: 20px 0;
  background-color: #dee0f2;
  width: 100%;
  border-radius: 30px;
  top: 0;
  left: 0; }
  .navbar.style-6 .container-fluid {
    padding: 0 3vw; }
  .navbar.style-6 .navbar-brand img {
    max-width: 150px;
    -o-object-fit: contain;
    object-fit: contain; }
  .navbar.style-6 .nav-item .nav-link {
    position: relative;
    color: #000;
    font-size: 12px;
    font-weight: bold;
    margin: 0 15px;
    text-transform: capitalize; }
    .navbar.style-6 .nav-item .nav-link::after {
      position: absolute;
      content: "";
      right: -16px;
      top: 16px;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: #bebccd; }
    .navbar.style-6 .nav-item .nav-link:hover, .navbar.style-6 .nav-item .nav-link.active {
      color: var(--color-blue6); }
  .navbar.style-6 .nav-item:last-of-type .nav-link::after {
    display: none; }

.navbar.style-7 {
  padding: 30px 0; }
  .navbar.style-7 .navbar-brand {
    width: 250px;
    padding-right: 60px;
    border-right: 1px solid #9992; }
  .navbar.style-7 .navbar-nav .nav-item .nav-link {
    font-weight: bold;
    font-size: 14px;
    border-bottom: 1px solid transparent;
    padding: 0;
    margin: 15px 20px;
    color: #000;
    text-transform: capitalize; }
    .navbar.style-7 .navbar-nav .nav-item .nav-link.active, .navbar.style-7 .navbar-nav .nav-item .nav-link:hover {
      border-color: #000; }
  .navbar.style-7 .side_menu_btn {
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out; }
    .navbar.style-7 .side_menu_btn:hover {
      -webkit-transform: rotate(140deg);
      -ms-transform: rotate(140deg);
      transform: rotate(140deg); }

.navbar.style-8 {
  padding: 30px 0; }
  .navbar.style-8 .navbar-brand {
    width: 150px; }
  .navbar.style-8 .navbar-nav .nav-item .nav-link {
    font-weight: bold;
    font-size: 14px;
    border-bottom: 1px solid transparent;
    padding: 0;
    margin: 15px 20px;
    color: #000;
    text-transform: capitalize; }
    .navbar.style-8 .navbar-nav .nav-item .nav-link.active, .navbar.style-8 .navbar-nav .nav-item .nav-link:hover {
      border-color: #000; }

.navbar.style-9.nav-scroll {
  background: #1f227d !important; }
  .navbar.style-9.nav-scroll .content {
    border: 0; }

.navbar.style-9 .content {
  padding: 20px 15px;
  border-bottom: 1px solid #fff2; }

.navbar.style-9 .navbar-brand {
  width: 160px; }

.navbar.style-9 .navbar-nav .nav-item .nav-link {
  font-weight: bold;
  font-size: 14px;
  border-bottom: 1px solid transparent;
  padding: 0;
  margin: 15px 20px;
  color: #fff;
  text-transform: capitalize; }
  .navbar.style-9 .navbar-nav .nav-item .nav-link.active, .navbar.style-9 .navbar-nav .nav-item .nav-link:hover {
    border-color: #fff; }

.navbar.style-9 .side_menu_btn {
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }
  .navbar.style-9 .side_menu_btn:hover {
    -webkit-transform: rotate(140deg);
    -ms-transform: rotate(140deg);
    transform: rotate(140deg); }

.navbar.style-11.nav-scroll {
  background: #111 !important; }
  .navbar.style-11.nav-scroll .content {
    border: 0; }

.navbar.style-11 .content {
  padding: 20px 15px; }

.navbar.style-11 .navbar-brand {
  width: 160px;
  border-right: 1px solid #fff3; }

.navbar.style-11 .nav-link {
  font-weight: 500;
  font-size: 14px;
  border-bottom: 1px solid transparent;
  padding: 0;
  margin: 15px 20px;
  color: #fff;
  text-transform: uppercase; }
  .navbar.style-11 .nav-link.active, .navbar.style-11 .nav-link:hover {
    border-color: #fff; }

.navbar.style-11 .side_menu_btn {
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }
  .navbar.style-11 .side_menu_btn:hover {
    -webkit-transform: rotate(140deg);
    -ms-transform: rotate(140deg);
    transform: rotate(140deg); }

/* ===============================================================
			[ * header Section ] 
================================================================*/
/* --------------- header styles --------------- */
header.style-1 {
  position: relative; }
  header.style-1 .head-shape-r {
    position: absolute;
    right: 0;
    top: 8%;
    width: 55%;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: right;
    object-position: right; }
  header.style-1 .head-shape-l {
    position: absolute;
    left: 0;
    top: 25%;
    height: 40%;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: left;
    object-position: left; }
  header.style-1 .content {
    position: relative;
    z-index: 10; }
  header.style-1 .info .section-head h2 {
    font-size: 55px; }
  header.style-1 .info .text {
    font-size: 14px;
    color: #666666;
    width: 80%; }
  header.style-1 .info .bttns {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
    header.style-1 .info .bttns .vid-btn {
      display: -webkit-inline-box;
      display: -ms-inline-flexbox;
      display: inline-flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      margin-left: 30px; }
      header.style-1 .info .bttns .vid-btn i {
        width: 35px;
        height: 35px;
        border-radius: 30px;
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        background: var(--color-main-grad);
        color: #fff;
        margin-right: 15px;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        font-size: 22px;
        padding-left: 2px;
        outline: 6px solid #157aa620; }
      header.style-1 .info .bttns .vid-btn span {
        color: var(--color-main);
        font-size: 12px;
        font-weight: bold; }

header.style-2 {
  position: relative;
  min-height: 100vh;
  padding: 185px 0 100px;
  background: -webkit-gradient(linear, left bottom, left top, color-stop(-40%, #062063), color-stop(40%, #157aa6));
  background: -webkit-linear-gradient(bottom, #062063 -40%, #157aa6 40%);
  background: -o-linear-gradient(bottom, #062063 -40%, #157aa6 40%);
  background: linear-gradient(to top, #062063 -40%, #157aa6 40%); }
  header.style-2 .head_shape2 {
    position: absolute;
    bottom: -5%;
    left: -5%;
    width: 110%;
    max-width: unset;
    height: 115%;
    max-height: none; }
  header.style-2 .content {
    position: relative;
    z-index: 5; }
    header.style-2 .content h1 {
      font-size: 123px;
      letter-spacing: 75px;
      display: inline-block;
      padding-left: 75px; }
    header.style-2 .content h4 {
      font-weight: 600; }
    header.style-2 .content p {
      font-size: 20px; }
      header.style-2 .content p.d-block {
        font-size: 16px; }
    header.style-2 .content .vid-btn {
      display: -webkit-inline-box;
      display: -ms-inline-flexbox;
      display: inline-flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      margin-top: 100px; }
      header.style-2 .content .vid-btn i {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        background: var(--color-lightBlue);
        color: #fff;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        font-size: 22px;
        padding-left: 2px; }
    header.style-2 .content .brands {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      position: relative;
      z-index: 5; }

header.style-3 {
  background-color: var(--color-blue2);
  position: relative;
  padding: 100px 0 30px; }
  header.style-3::before {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    width: 100%;
    height: 60%;
    background-image: url(../img/0011.png);
    background-size: 80%;
    background-repeat: repeat;
    background-position: bottom;
    opacity: 0.08;
    -webkit-filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(1003%) contrast(103%);
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(1003%) contrast(103%); }
  header.style-3 .main-img {
    position: absolute;
    right: 0;
    top: 200px;
    width: 55%;
    height: calc(100% - 300px);
    -o-object-fit: contain;
    object-fit: contain;
    -o-object-position: right;
    object-position: right; }
    header.style-3 .main-img .pattern {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0; }
    header.style-3 .main-img .circle {
      position: absolute;
      width: 55%;
      top: 10.5%;
      right: 20%;
      -webkit-animation: rotate-center 100s linear infinite both;
      animation: rotate-center 100s linear infinite both; }
    header.style-3 .main-img .logo_shap {
      position: absolute;
      top: 37%;
      right: 39%;
      width: 17%; }
  header.style-3 .content .info .h1 {
    font-size: 55px;
    font-weight: 700;
    color: #fff;
    line-height: 1.2; }
    header.style-3 .content .info .h1 span {
      font-weight: 400;
      position: relative;
      font-style: italic; }
      header.style-3 .content .info .h1 span::before {
        position: absolute;
        content: "";
        left: 0;
        top: 100%;
        width: 160px;
        height: 30px;
        background-image: url(../img/header/info_h1_line.png);
        background-size: 160px;
        background-repeat: no-repeat;
        background-position: left; }
  header.style-3 .content .info .p {
    color: #ccccff;
    margin-top: 40px; }
  header.style-3 .content .info .h5 {
    color: #fffefe;
    font-size: 16px;
    font-weight: bold;
    margin-top: 80px;
    position: relative; }
    header.style-3 .content .info .h5::before {
      position: absolute;
      content: "";
      left: -65px;
      bottom: -10px;
      width: 50px;
      height: 100px;
      background-image: url(../img/header/head3_arrow.png);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center; }
  header.style-3 .content .info .form-group .form-control,
  header.style-3 .content .info .form-group .form-select {
    padding: 12px 20px;
    font-size: 12px;
    border: 0;
    border-radius: 30px; }
  header.style-3 .content .info .form-group.input-with-icon {
    position: relative; }
    header.style-3 .content .info .form-group.input-with-icon .form-control {
      padding: 12px 20px 12px 40px; }
    header.style-3 .content .info .form-group.input-with-icon .input-icon {
      position: absolute;
      left: 20px;
      bottom: 10px;
      color: #999; }

/* ------------ animation -------- */
header.style-4 {
  position: relative;
  padding: 60px 0; }
  header.style-4 .content {
    position: relative;
    overflow: hidden; }
    header.style-4 .content .info .title_small {
      font-size: 12px;
      padding: 5px 10px;
      background-color: #f7f4ff;
      border-radius: 5px;
      color: var(--color-blue4); }
    header.style-4 .content .info h1 {
      font-size: 52px;
      line-height: 60px;
      color: #000; }
      header.style-4 .content .info h1 span {
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        /* background-image: -webkit-gradient(linear, left top, right top, from(#501e9c), color-stop(30%, #8169f1), color-stop(30%, #8169f1), color-stop(73%, #a44cee), to(#ff847f));
        background-image: -webkit-linear-gradient(left, #501e9c 0%, #8169f1 30%, #8169f1 30%, #a44cee 73%, #ff847f 100%);
        background-image: -o-linear-gradient(left, #501e9c 0%, #8169f1 30%, #8169f1 30%, #a44cee 73%, #ff847f 100%); */
        /* background-image: linear-gradient(to right, #501e9c 0%, #8169f1 30%, #8169f1 30%, #a44cee 73%, #ff847f 100%);  */
        background-image: linear-gradient(to right, #3a6cb4 0%, #30b6c5 100%);
      }
    header.style-4 .content .info .text {
      color: #666;
      font-size: 15px; }
    header.style-4 .content .info .play-btn {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center; }
      header.style-4 .content .info .play-btn .icon {
        width: 42px;
        height: 42px;
        border: 1px solid #5842bc99;
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        border-radius: 50%; }
        header.style-4 .content .info .play-btn .icon i {
          color: var(--color-blue4);
          font-size: 16px; }
    header.style-4 .content .bubble {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
      -webkit-animation: rotate-center 100s linear infinite both;
      animation: rotate-center 100s linear infinite both; }
  header.style-4 .wave {
    position: absolute;
    left: -3%;
    bottom: -10px;
    width: 106%;
    max-width: unset;
    height: 240px;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: top;
    object-position: top;
    z-index: 20; }

header.style-5 {
  position: relative;
  padding: 60px 0 80px;
  overflow: hidden;
  background: -moz-linear-gradient(bottom, #ffffff 0%, #edf4fe 74%, #e7f1ff 100%);
  background: -webkit-linear-gradient(bottom, #ffffff 0%, #edf4fe 74%, #e7f1ff 100%);
  background: -webkit-gradient(linear, left bottom, left top, from(#ffffff), color-stop(74%, #edf4fe), to(#e7f1ff));
  background: -o-linear-gradient(bottom, #ffffff 0%, #edf4fe 74%, #e7f1ff 100%);
  background: linear-gradient(to top, #ffffff 0%, #edf4fe 74%, #e7f1ff 100%); }
  header.style-5 .info {
    text-align: center; }
    header.style-5 .info h1 {
      font-size: 60px;
      color: #000;
      line-height: 1.2;
      letter-spacing: -2px; }
      header.style-5 .info h1 span {
        position: relative; }
        header.style-5 .info h1 span .head-line {
          position: absolute;
          left: 0;
          bottom: -5px;
          width: 100%; }
        header.style-5 .info h1 span .head-pen {
          position: absolute;
          left: 102%;
          bottom: -5px; }
    header.style-5 .info p {
      font-size: 15px;
      color: #666;
      margin-top: 30px; }
    header.style-5 .info .form {
      text-align: center; }
      header.style-5 .info .form .form-group {
        position: relative;
        max-width: 500px;
        margin: 40px auto; }
        header.style-5 .info .form .form-group .icon {
          position: absolute;
          left: 25px;
          bottom: 13px;
          font-size: 18px; }
        header.style-5 .info .form .form-group input {
          width: 100%;
          padding: 16px 180px 16px 60px;
          background-color: #fff;
          border: 1px solid #ccc;
          border-radius: 30px; }
        header.style-5 .info .form .form-group .btn {
          position: absolute;
          right: 8px;
          top: 7px; }
  header.style-5 .main-img {
    margin-top: 85px;
    position: relative;
    z-index: 10; }
    header.style-5 .main-img .page-img {
      -webkit-box-shadow: 0px -13px 124px 0px rgba(75, 83, 97, 0.15);
      box-shadow: 0px -13px 124px 0px rgba(75, 83, 97, 0.15);
      border-radius: 15px; }
    header.style-5 .main-img .linechart-img {
      position: absolute;
      top: 8%;
      right: 7%;
      width: 50%;
      -webkit-animation: scale_up_down 1.5s ease-in-out infinite alternate both;
      animation: scale_up_down 1.5s ease-in-out infinite alternate both; }
    header.style-5 .main-img .piechart-img {
      position: absolute;
      bottom: 10%;
      right: 10%;
      width: 15%;
      -webkit-filter: drop-shadow(0 20px 60px #0005);
      filter: drop-shadow(0 20px 60px #0005);
      -webkit-animation: rotate-center 50s linear infinite both;
      animation: rotate-center 50s linear infinite both; }
  header.style-5 .handl-img {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 30%;
    z-index: 15;
    -webkit-animation: slide_up_down 2s ease-in-out infinite alternate both;
    animation: slide_up_down 2s ease-in-out infinite alternate both; }
  header.style-5 .handr-img {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 40%;
    z-index: 0;
    -webkit-animation: slide_up_down 2s ease-in-out infinite alternate both;
    animation: slide_up_down 2s ease-in-out infinite alternate both;
    -webkit-animation-delay: -1s;
    animation-delay: -1s; }

header.style-6 {
  position: relative;
  padding: 30px 30px 0;
  overflow: hidden; }
  header.style-6 .content {
    background-color: #dee0f2;
    position: relative;
    padding: 150px 0 100px;
    border-radius: 30px; }
  header.style-6 .info {
    text-align: center;
    position: relative;
    z-index: 5; }
    header.style-6 .info h6 {
      color: #666;
      font-size: 18px;
      letter-spacing: 5px;
      text-transform: uppercase;
      margin-bottom: 20px; }
    header.style-6 .info h1 {
      font-size: 85px;
      color: #000;
      line-height: 1.1; }
      header.style-6 .info h1 span {
        color: #fff;
        position: relative;
        padding: 10px 40px; }
        header.style-6 .info h1 span small {
          color: #fff;
          position: relative;
          z-index: 2;
          font-size: 85px; }
        header.style-6 .info h1 span::before {
          position: absolute;
          content: "";
          left: 0;
          top: 0;
          height: 100%;
          width: 100%;
          padding: 10px 30px;
          background-image: url(../img/shap_style_6.png);
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          z-index: 1; }
    header.style-6 .info .text {
      font-size: 14px;
      color: #666;
      margin-top: 50px; }
    header.style-6 .info .form {
      background-color: #fff;
      border-radius: 50px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      padding: 10px;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      margin-top: 70px; }
      header.style-6 .info .form .form-group {
        width: 38%;
        border-right: 1px solid #9994;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        padding: 0 15px; }
        header.style-6 .info .form .form-group:last-of-type {
          border: 0; }
        header.style-6 .info .form .form-group input {
          background-color: #fff;
          border: 0;
          color: #000;
          font-size: 12px;
          width: 100%; }
          header.style-6 .info .form .form-group input::-webkit-input-placeholder {
            opacity: 0.7; }
          header.style-6 .info .form .form-group input:-ms-input-placeholder {
            opacity: 0.7; }
          header.style-6 .info .form .form-group input::-ms-input-placeholder {
            opacity: 0.7; }
          header.style-6 .info .form .form-group input::placeholder {
            opacity: 0.7; }
  header.style-6 .hand-mega {
    position: absolute;
    width: 20%;
    top: 20%;
    left: 0;
    -o-object-fit: contain;
    object-fit: contain;
    -o-object-position: left;
    object-position: left;
    z-index: 2; }
  header.style-6 .head6-rating {
    position: absolute;
    width: 18%;
    bottom: 27%;
    left: 15%;
    -o-object-fit: contain;
    object-fit: contain;
    z-index: 2; }
  header.style-6 .target-3d {
    position: absolute;
    width: 20%;
    bottom: -120px;
    left: 35%;
    -o-object-fit: contain;
    object-fit: contain;
    -o-object-position: left;
    object-position: left;
    z-index: 2; }
  header.style-6 .head6-charts {
    position: absolute;
    width: 14%;
    top: 20%;
    right: 15%;
    -o-object-fit: contain;
    object-fit: contain;
    z-index: 2; }
  header.style-6 .head6-rocket {
    position: absolute;
    width: 30%;
    bottom: 5%;
    right: -30px;
    z-index: 2; }

header.style-7 {
  position: relative;
  color: #000;
  padding-bottom: 60px; }
  header.style-7 .content {
    position: relative;
    z-index: 10; }
  header.style-7 .info h1 {
    margin-top: 30px;
    margin-bottom: 40px;
    font-size: 60px; }
    header.style-7 .info h1 .num {
      color: #e2e1e1; }
    header.style-7 .info h1 .text-grad {
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-image: -webkit-gradient(linear, left top, right top, from(#501e9c), color-stop(30%, #8169f1), color-stop(30%, #8169f1), color-stop(73%, #a44cee), to(#ff847f));
      background-image: -webkit-linear-gradient(left, #501e9c 0%, #8169f1 30%, #8169f1 30%, #a44cee 73%, #ff847f 100%);
      background-image: -o-linear-gradient(left, #501e9c 0%, #8169f1 30%, #8169f1 30%, #a44cee 73%, #ff847f 100%);
      background-image: linear-gradient(to right, #501e9c 0%, #8169f1 30%, #8169f1 30%, #a44cee 73%, #ff847f 100%); }
  header.style-7 .img {
    position: relative; }
    header.style-7 .img .rock7 {
      position: absolute;
      top: 50px;
      right: -50px; }
  header.style-7 .sponsers {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
    header.style-7 .sponsers .sponsers-content a {
      opacity: 20%;
      -webkit-margin-end: 50px;
      margin-inline-end: 50px; }
      header.style-7 .sponsers .sponsers-content a:hover {
        opacity: 100%; }
  header.style-7 .head7_bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -o-object-fit: contain;
    object-fit: contain;
    -webkit-animation-delay: -1s;
    animation-delay: -1s; }

header.style-8 {
  background-image: url(../img/header/header8_pattern.svg);
  background-size: cover;
  background-attachment: fixed; }
  header.style-8 .info h1 {
    font-size: 60px;
    margin-bottom: 50px;
    margin-top: 10px; }
  header.style-8 .info .form h5 {
    font-size: 20px;
    font-weight: 300; }
  header.style-8 .info .form .form-group {
    position: relative; }
  header.style-8 .info .form-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 8px;
    background-color: #fff;
    border-radius: 50px; }
    header.style-8 .info .form-group input {
      border: 0; }
      header.style-8 .info .form-group input::-webkit-input-placeholder {
        color: #9999; }
      header.style-8 .info .form-group input:-ms-input-placeholder {
        color: #9999; }
      header.style-8 .info .form-group input::-ms-input-placeholder {
        color: #9999; }
      header.style-8 .info .form-group input::placeholder {
        color: #9999; }

header.style-9 {
  background-image: url(../img/header/head9_back.png);
  background-position: center;
  background-size: cover; }
  header.style-9 .info {
    color: #fff; }
    header.style-9 .info h6 {
      font-size: 16px;
      font-weight: bold;
      position: relative;
      padding-left: 25px;
      text-transform: uppercase;
      margin-bottom: 15px; }
      header.style-9 .info h6::before {
        position: absolute;
        content: "";
        left: 0;
        top: 5px;
        width: 10px;
        height: 10px;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
        background-color: #5f70b7; }
    header.style-9 .info h1 {
      font-size: 50px;
      margin-bottom: 30px; }
      header.style-9 .info h1 span {
        color: #fff000;
        text-decoration: underline !important; }
    header.style-9 .info ul li {
      font-size: 18px;
      margin: 15px 0;
      font-weight: 300;
      padding-left: 50px;
      text-transform: capitalize;
      position: relative; }
      header.style-9 .info ul li::before {
        position: absolute;
        content: "";
        left: 0;
        top: 50%;
        width: 35px;
        height: 1px;
        background-color: #fff3; }
  header.style-9 .img {
    width: 800px;
    max-width: unset; }

header.style-10 {
  background-image: url(../img/header/head9_back.png);
  background-position: center;
  background-size: cover; }
  header.style-10 .info {
    position: relative;
    color: #fff;
    z-index: 10; }
    header.style-10 .info h6 {
      font-size: 14px;
      font-weight: 400;
      position: relative;
      text-transform: capitalize;
      margin-bottom: 15px; }
    header.style-10 .info h1 {
      font-size: 73px;
      margin-bottom: 30px; }
  header.style-10 .img {
    width: 750px;
    max-width: unset;
    -webkit-margin-start: -100px;
    margin-inline-start: -100px; }
  header.style-10 .logos {
    padding-bottom: 60px; }
    header.style-10 .logos .logos-content {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between; }
      header.style-10 .logos .logos-content .logo_img {
        width: 50px;
        margin-top: 30px; }

header.style-11 {
  background-image: url(../img/header/header_11.png);
  background-position: center;
  background-size: cover; }
  header.style-11 .info {
    position: relative;
    color: #fff;
    z-index: 10; }
    header.style-11 .info h6 {
      font-size: 14px;
      font-weight: 400;
      position: relative;
      text-transform: capitalize;
      margin-bottom: 15px;
      color: var(--color-green); }
    header.style-11 .info h1 {
      font-size: 60px;
      margin-bottom: 30px; }
  header.style-11 .img {
    width: 700px;
    max-width: unset; }

/* ===============================================================
			[ * about Section ] 
================================================================*/
/* --------------- about styles --------------- */
.about.style-1 {
  position: relative; }
  .about.style-1 .content {
    padding: 65px 120px;
    background: var(--color-main-grad);
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    z-index: 5; }
    .about.style-1 .content .about_shap {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 0;
      opacity: 30%;
      pointer-events: none; }
    .about.style-1 .content .about_logos {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      padding-bottom: 40px;
      border-bottom: 1px solid #fff4;
      position: relative;
      z-index: 5; }
    .about.style-1 .content .about-info {
      padding: 60px 0;
      position: relative;
      z-index: 5; }
      .about.style-1 .content .about-info .title h3 {
        color: #fff;
        font-weight: 600;
        font-size: 30px; }
      .about.style-1 .content .about-info .title small {
        color: #fff;
        text-transform: uppercase;
        font-size: 11px;
        margin-top: 35px;
        display: block; }
      .about.style-1 .content .about-info .info h6 {
        color: #fff;
        font-size: 14px;
        line-height: 1.6;
        margin-bottom: 25px; }
      .about.style-1 .content .about-info .info p {
        font-size: 14px;
        color: #c7e9ff; }
      .about.style-1 .content .about-info .info .butn {
        margin-top: 50px; }
    .about.style-1 .content .about-numbers {
      position: relative;
      z-index: 5; }
      .about.style-1 .content .about-numbers .num-item {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center; }
        .about.style-1 .content .about-numbers .num-item .num {
          color: #fff;
          font-size: 32px;
          font-weight: bold;
          -ms-flex-negative: 0;
          flex-shrink: 0;
          margin-right: 15px;
          min-width: 70px;
          text-align: center; }
          .about.style-1 .content .about-numbers .num-item .num i {
            font-size: 21px; }
        .about.style-1 .content .about-numbers .num-item .inf {
          color: #fff; }

.about.style-3 {
  position: relative; }
  .about.style-3 .top-content {
    min-height: 510px;
    margin-bottom: 100px;
    padding-top: 20px; }
  .about.style-3 .info h5 {
    font-size: 19px;
    line-height: 28px;
    font-style: italic;
    margin-bottom: 25px; }
  .about.style-3 .info .text {
    color: #666; }
  .about.style-3 .info ul li {
    font-size: 14px;
    font-weight: 600;
    margin: 15px 0; }
    .about.style-3 .info ul li i {
      color: var(--color-blue2); }
  .about.style-3 .img-left {
    position: absolute;
    left: 0;
    top: 190px;
    max-width: 50%; }
    .about.style-3 .img-left .info-circle {
      position: absolute;
      top: 0;
      left: 50%;
      -webkit-transform: translate(-50%, -40%);
      -ms-transform: translate(-50%, -40%);
      transform: translate(-50%, -40%);
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      text-align: center;
      width: 230px;
      height: 230px;
      background-color: #4a00e1;
      color: #fff;
      border-radius: 50%;
      overflow: hidden;
      outline: 10px solid #fff; }
      .about.style-3 .img-left .info-circle h2 {
        font-size: 56px; }
      .about.style-3 .img-left .info-circle small {
        font-size: 11px;
        text-transform: uppercase; }
      .about.style-3 .img-left .info-circle:nth-of-type(2) {
        width: 200px;
        height: 200px;
        left: auto;
        right: 0;
        top: 50%;
        -webkit-transform: translate(-14%, -57%);
        -ms-transform: translate(-14%, -57%);
        transform: translate(-14%, -57%);
        z-index: 5; }
        .about.style-3 .img-left .info-circle:nth-of-type(2) h2 {
          font-size: 50px; }
      .about.style-3 .img-left .info-circle:nth-of-type(3) {
        width: 150px;
        height: 150px;
        left: auto;
        right: 0;
        top: 50%;
        -webkit-transform: translate(-88%, 32%);
        -ms-transform: translate(-88%, 32%);
        transform: translate(-88%, 32%);
        background-color: #8d3dfa;
        z-index: 0; }
        .about.style-3 .img-left .info-circle:nth-of-type(3) h2 {
          font-size: 37px; }
      .about.style-3 .img-left .info-circle:nth-of-type(4) {
        width: 180px;
        height: 180px;
        left: 0;
        top: 50%;
        -webkit-transform: translate(20%, -20%);
        -ms-transform: translate(20%, -20%);
        transform: translate(20%, -20%);
        background-color: #00bcf2;
        z-index: 0; }
        .about.style-3 .img-left .info-circle:nth-of-type(4) h2 {
          font-size: 46px; }
  .about.style-3 .btm-content {
    position: relative;
    min-height: 510px;
    padding-top: 50px; }
    .about.style-3 .btm-content .img-right {
      position: absolute;
      right: 0;
      top: 0;
      max-width: 60%; }

.about.style-4 {
  overflow: hidden;
  position: relative; }
  .about.style-4 .top-wave {
    position: absolute;
    top: -2px;
    width: 100%; }
  .about.style-4 .bottom-wave {
    position: absolute;
    bottom: -2px;
    width: 100%;
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg); }
  .about.style-4 .content {
    position: relative;
    padding: 60px 0; }
    .about.style-4 .content ul li h6 {
      font-size: 15px; }
    .about.style-4 .content.frs-content .lines, .about.style-4 .content.trd-content .lines {
      position: absolute;
      left: 0;
      top: -80px;
      width: 40%;
      max-width: unset;
      max-height: unset; }
    .about.style-4 .content.frs-content .bubble, .about.style-4 .content.trd-content .bubble {
      position: absolute;
      left: 0;
      top: 0;
      width: 50%;
      max-width: unset;
      max-height: unset;
      -webkit-animation: rotate-center 100s linear infinite both;
      animation: rotate-center 100s linear infinite both; }
    .about.style-4 .content.sec-content .bubble2 {
      position: absolute;
      right: 0;
      top: 0;
      width: 50%;
      max-width: unset;
      max-height: unset;
      -webkit-animation: rotate-center 100s linear infinite both;
      animation: rotate-center 100s linear infinite both; }
  .about.style-4 .btn-img {
    text-align: initial;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    background-color: #f4f2fb;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
    .about.style-4 .btn-img .icon {
      -ms-flex-negative: 0;
      flex-shrink: 0;
      padding-right: 15px;
      margin-right: 15px;
      border-right: 1px solid #0001;
      width: 45px; }
    .about.style-4 .btn-img .inf {
      height: -webkit-max-content;
      height: -moz-max-content;
      height: max-content;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column; }
      .about.style-4 .btn-img .inf small {
        font-size: 10px;
        color: #666; }
      .about.style-4 .btn-img .inf h6 {
        font-size: 12px;
        color: #000;
        font-weight: bold;
        line-height: 1.5; }
  .about.style-4 .integration {
    position: relative; }
    .about.style-4 .integration .intg-back {
      position: absolute;
      left: 0;
      bottom: 19%;
      width: 100%;
      max-height: unset;
      -o-object-fit: cover;
      object-fit: cover; }
    .about.style-4 .integration .content {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      position: relative; }
      .about.style-4 .integration .content .img {
        -webkit-animation: slide_up_down 1.7s ease-in-out infinite alternate both;
        animation: slide_up_down 1.7s ease-in-out infinite alternate both; }
        .about.style-4 .integration .content .img:nth-of-type(1) {
          -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s; }
        .about.style-4 .integration .content .img:nth-of-type(2) {
          -webkit-animation-delay: -1s;
          animation-delay: -1s; }
        .about.style-4 .integration .content .img:nth-of-type(3) {
          -webkit-animation-delay: -1.5s;
          animation-delay: -1.5s; }
        .about.style-4 .integration .content .img:nth-of-type(4) {
          -webkit-animation-delay: -2s;
          animation-delay: -2s; }
        .about.style-4 .integration .content .img:nth-of-type(5) {
          -webkit-animation-delay: -2.5s;
          animation-delay: -2.5s; }

.about.style-5 .content {
  padding: 50px 0; }
  .about.style-5 .content p {
    font-size: 12.5px;
    color: #777;
    line-height: 2; }
  .about.style-5 .content .line-links {
    margin-top: 35px; }
    .about.style-5 .content .line-links a {
      position: relative;
      color: #000;
      font-size: 15px;
      font-weight: bold;
      border-bottom: 1px solid #9993;
      padding: 13px 0;
      display: block;
      width: 100%; }
      .about.style-5 .content .line-links a:last-of-type {
        border-bottom: 0; }
      .about.style-5 .content .line-links a::after {
        position: absolute;
        content: "";
        left: 0;
        bottom: 0;
        height: 1px;
        width: 0%;
        background-color: var(--color-blue5);
        -webkit-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease; }
      .about.style-5 .content .line-links a:hover::after {
        width: 100%; }
  .about.style-5 .content .list-icon {
    margin-top: 40px; }
    .about.style-5 .content .list-icon li {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      margin-bottom: 15px; }
      .about.style-5 .content .list-icon li .icon {
        -ms-flex-negative: 0;
        flex-shrink: 0;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: var(--color-blue5);
        color: #fff;
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        margin-right: 15px; }
      .about.style-5 .content .list-icon li h6 {
        font-size: 15px;
        font-weight: 700; }
  .about.style-5 .content .img {
    position: relative;
    text-align: right; }
    .about.style-5 .content .img.main-img1 .img-body {
      position: relative;
      z-index: 2;
      top: 50px;
      -webkit-animation: slide_up_down 3s ease-in-out infinite alternate both;
      animation: slide_up_down 3s ease-in-out infinite alternate both; }
    .about.style-5 .content .img.main-img1 img {
      position: absolute;
      -o-object-fit: contain;
      object-fit: contain; }
      .about.style-5 .content .img.main-img1 img.sm-circle {
        right: -50px;
        top: 20%;
        z-index: 1;
        -webkit-animation: rotate-center 20s linear infinite both reverse;
        animation: rotate-center 20s linear infinite both reverse; }
      .about.style-5 .content .img.main-img1 img.lg-circle {
        left: 0;
        top: 0;
        z-index: 1;
        -webkit-animation: rotate-center 50s linear infinite both;
        animation: rotate-center 50s linear infinite both; }
      .about.style-5 .content .img.main-img1 img.card1 {
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        z-index: 3;
        -webkit-animation: scale_up_down 10s ease-in-out infinite alternate both;
        animation: scale_up_down 10s ease-in-out infinite alternate both; }
      .about.style-5 .content .img.main-img1 img.card2 {
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        -webkit-animation: scale_up_down 7s ease-in-out infinite alternate both;
        animation: scale_up_down 7s ease-in-out infinite alternate both;
        -webkit-transition-delay: -5s;
        -o-transition-delay: -5s;
        transition-delay: -5s; }
    .about.style-5 .content .img.main-img2 .img-body {
      position: relative;
      z-index: 2;
      -webkit-animation: slide_up_down 3s ease-in-out infinite alternate both;
      animation: slide_up_down 3s ease-in-out infinite alternate both; }
    .about.style-5 .content .img.main-img2 img {
      position: absolute; }
      .about.style-5 .content .img.main-img2 img:nth-of-type(1) {
        right: 0;
        top: 0;
        width: 100%;
        z-index: 1;
        -webkit-animation: rotate-center 70s linear infinite both;
        animation: rotate-center 70s linear infinite both; }
    .about.style-5 .content .img.main-img3 {
      position: relative; }
      .about.style-5 .content .img.main-img3 .img-body {
        position: relative;
        z-index: 1;
        max-height: 500px;
        -o-object-fit: contain;
        object-fit: contain;
        -webkit-animation: rotate-center 100s linear infinite both;
        animation: rotate-center 100s linear infinite both; }
      .about.style-5 .content .img.main-img3 img {
        position: absolute;
        right: 0;
        top: 20%;
        width: 100%; }
        .about.style-5 .content .img.main-img3 img:nth-of-type(2) {
          z-index: 3;
          -webkit-animation: slide_up_down 2s ease-in-out infinite alternate both;
          animation: slide_up_down 2s ease-in-out infinite alternate both; }
        .about.style-5 .content .img.main-img3 img:nth-of-type(3) {
          z-index: 1;
          -webkit-animation: slide_up_down 2s ease-in-out infinite alternate both;
          animation: slide_up_down 2s ease-in-out infinite alternate both;
          -webkit-animation-delay: 1s;
          animation-delay: 1s; }
        .about.style-5 .content .img.main-img3 img:nth-of-type(4) {
          z-index: 2;
          -webkit-animation: slide_up_down 2s ease-in-out infinite alternate both;
          animation: slide_up_down 2s ease-in-out infinite alternate both;
          -webkit-animation-delay: 2s;
          animation-delay: 2s; }
        .about.style-5 .content .img.main-img3 img:nth-of-type(5) {
          z-index: 4;
          -webkit-animation: slide_up_down 2s ease-in-out infinite alternate both;
          animation: slide_up_down 2s ease-in-out infinite alternate both;
          -webkit-animation-delay: 3s;
          animation-delay: 3s; }

.about.style-6 {
  position: relative; }
  .about.style-6 .content {
    border-bottom: 1px solid #9994; }
    .about.style-6 .content .info .text {
      color: #666;
      font-size: 15px; }
    .about.style-6 .content .info .vid-btn {
      font-size: 11px;
      font-weight: bold;
      display: -webkit-inline-box;
      display: -ms-inline-flexbox;
      display: inline-flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center; }
      .about.style-6 .content .info .vid-btn i {
        width: 40px;
        height: 40px;
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        border-radius: 50%;
        border: 1px solid var(--color-blue6);
        color: var(--color-blue6);
        font-size: 21px;
        margin-right: 5px; }
  .about.style-6 .bubbles {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 50%;
    -o-object-fit: contain;
    object-fit: contain; }

.about.style-7 {
  position: relative;
  padding: 120px 0; }
  .about.style-7 .bg-lines {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    -o-object-fit: cover;
    object-fit: cover; }
  .about.style-7 .top-wave {
    position: absolute;
    top: -1px;
    left: 0;
    width: 100%; }
  .about.style-7 .bottom-wave {
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg); }
  .about.style-7 .info .text {
    font-size: 16px;
    color: #666;
    line-height: 1.7; }
  .about.style-7 .img {
    position: relative;
    padding-left: 100px; }
    .about.style-7 .img .main-img {
      border-radius: 10px;
      -o-object-fit: cover;
      object-fit: cover; }
    .about.style-7 .img .img-chart {
      position: absolute;
      top: 120px;
      left: -30px;
      z-index: 2; }
    .about.style-7 .img .circle {
      position: absolute;
      content: "";
      width: 85px;
      height: 85px;
      bottom: 50px;
      right: -40px;
      background-color: var(--color-blue7);
      border-radius: 50%;
      z-index: -1; }

.about.style-8 .info ul li {
  margin: 10px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }
  .about.style-8 .info ul li .icon {
    width: 20px;
    -o-object-fit: contain;
    object-fit: contain;
    -webkit-margin-end: 15px;
    margin-inline-end: 15px;
    -ms-flex-negative: 0;
    flex-shrink: 0; }
  .about.style-8 .info ul li p {
    font-size: 16px;
    font-weight: 500;
    color: #000; }

.about.style-8 .info .author {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }
  .about.style-8 .info .author .inf p {
    color: var(--color-main); }
  .about.style-8 .info .author .inf h6 {
    font-size: 18px;
    font-weight: bold; }

.about.style-8 .info .signature {
  width: 100px; }

.about.style-9 .info {
  padding: 0 50px; }
  .about.style-9 .info ul li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 25px; }
    .about.style-9 .info ul li p {
      font-size: 16px;
      color: #666; }
    .about.style-9 .info ul li .icon {
      -webkit-margin-end: 20px;
      margin-inline-end: 20px;
      -ms-flex-negative: 0;
      flex-shrink: 0; }
      .about.style-9 .info ul li .icon img {
        width: 60px; }
  .about.style-9 .info .call-info {
    border-top: 1px solid #9993; }
    .about.style-9 .info .call-info h5 {
      font-weight: bold;
      font-size: 20px;
      color: var(--color-darkBlue2);
      margin-bottom: 10px; }
    .about.style-9 .info .call-info p {
      font-size: 16px;
      color: #666; }

.about.style-10 {
  position: relative; }
  .about.style-10 .imgs .col-lg-6:first-of-type .img:first-of-type {
    height: 270px; }
  .about.style-10 .imgs .col-lg-6:first-of-type .img:last-of-type {
    height: 190px; }
  .about.style-10 .imgs .col-lg-6:last-of-type .img:first-of-type {
    height: 160px; }
  .about.style-10 .imgs .col-lg-6:last-of-type .img:last-of-type {
    height: 320px; }
  .about.style-10 .info {
    -webkit-padding-start: 50px;
    padding-inline-start: 50px; }
    .about.style-10 .info h6 {
      font-size: 14px;
      font-weight: 400;
      color: #8169f1;
      margin-bottom: 10px; }
    .about.style-10 .info h2 {
      font-size: 40px; }
    .about.style-10 .info p {
      font-size: 16px;
      color: #666;
      margin-top: 25px; }
  .about.style-10 .pattern {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    height: 100%; }

/* ===============================================================
			[ * services Section ] 
================================================================*/
/* --------------- services styles --------------- */
.services.style-1 {
  position: relative; }
  .services.style-1 .ser_shap_l {
    position: absolute;
    left: 0;
    bottom: 30%;
    z-index: -1;
    -webkit-transition: all 5s ease;
    -o-transition: all 5s ease;
    transition: all 5s ease; }
  .services.style-1 .ser_shap_r {
    position: absolute;
    right: 0;
    top: 25%; }
  .services.style-1 .service-box {
    position: relative;
    z-index: 5;
    padding: 30px;
    border: 1px solid #9993;
    border-radius: 8px;
    display: block;
    background-color: #fff;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease; }
    .services.style-1 .service-box h5 {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      margin-bottom: 35px; }
      .services.style-1 .service-box h5 a {
        color: #000;
        font-weight: bold; }
      .services.style-1 .service-box h5 .num {
        color: var(--color-main);
        font-size: 12px; }
    .services.style-1 .service-box .icon {
      margin-bottom: 35px; }
      .services.style-1 .service-box .icon img {
        width: 60px;
        height: 60px;
        -o-object-fit: cover;
        object-fit: cover; }
    .services.style-1 .service-box .info .text {
      color: #666;
      margin-bottom: 20px;
      font-size: 13px; }
    .services.style-1 .service-box .info .tags a {
      font-size: 11px;
      padding: 3px 7px;
      border-radius: 4px;
      background-color: #eef4f8;
      margin-top: 10px; }
    .services.style-1 .service-box:hover {
      border-color: transparent;
      -webkit-box-shadow: 0px 54px 115px 0px rgba(52, 60, 66, 0.25);
      box-shadow: 0px 54px 115px 0px rgba(52, 60, 66, 0.25); }
      .services.style-1 .service-box:hover h5 a {
        color: var(--color-main); }

.tags.style-2 a {
  font-size: 11px;
  padding: 3px 7px;
  border-radius: 4px;
  background-color: #131172;
  margin: 3px; }

.services_projects {
  background-image: url(../../assets/img/services_work.png);
  background-size: cover;
  background-attachment: fixed;
  background-position: center; }
  .services_projects .swiper-button-next, .services_projects .swiper-button-prev {
    width: 50px;
    height: 50px;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease; }
    .services_projects .swiper-button-next:hover, .services_projects .swiper-button-prev:hover {
      background: var(--color-lightBlue) !important; }

.swiper-slide-duplicate-prev .service-box {
  border: 0 !important; }

.services.style-3 {
  background-color: #f0eff5; }
  .services.style-3 .service-card.style-3 {
    padding: 30px;
    background-color: #fff;
    border-radius: 12px;
    height: 100%; }
    .services.style-3 .service-card.style-3 .icon {
      margin-bottom: 60px; }
      .services.style-3 .service-card.style-3 .icon img {
        width: 125px;
        height: 120px;
        -o-object-fit: contain;
        object-fit: contain; }
    .services.style-3 .service-card.style-3 .info .title {
      font-size: 18px;
      color: #000;
      text-transform: capitalize;
      margin-bottom: 20px;
      font-weight: bold; }
    .services.style-3 .service-card.style-3 .info .text {
      font-size: 13px;
      color: #666; }
    .services.style-3 .service-card.style-3 .info a {
      margin-top: 30px;
      font-weight: bold;
      font-size: 10px;
      text-transform: uppercase; }
      .services.style-3 .service-card.style-3 .info a:hover {
        color: var(--color-blue2); }

.services-slider.style-6 .swiper-container {
  padding-bottom: 100px; }

.services-slider.style-6 .swiper-slide {
  -webkit-transform: scale(0.9);
  -ms-transform: scale(0.9);
  transform: scale(0.9);
  position: relative;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  top: 0; }
  .services-slider.style-6 .swiper-slide.swiper-slide-nth-next-2 {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    top: 100px;
    padding: 0 9px; }
  .services-slider.style-6 .swiper-slide.swiper-slide-next {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    top: 50px;
    padding: 0 12px; }
  .services-slider.style-6 .swiper-slide.swiper-slide-active {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
    padding: 0 20px;
    -webkit-filter: drop-shadow(5px 50px 30px #07397211);
    filter: drop-shadow(5px 50px 30px #07397211); }
  .services-slider.style-6 .swiper-slide.swiper-slide-prev {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    top: 50px;
    padding: 0 12px; }
  .services-slider.style-6 .swiper-slide.swiper-slide-nth-prev-2 {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    top: 100px;
    padding: 0 9px; }

.service-card.style-6 {
  text-align: center;
  padding: 30px 8%;
  background-color: #f1f2fa;
  border-radius: 30px;
  overflow: hidden;
  margin: 20px 0;
  display: block;
  color: #000; }
  .service-card.style-6 .icon {
    -webkit-filter: drop-shadow(5px 15px 20px #07397255);
    filter: drop-shadow(5px 15px 20px #07397255);
    margin-bottom: 25px; }
    .service-card.style-6 .icon img {
      max-height: 100px;
      max-width: 100px;
      -o-object-fit: contain;
      object-fit: contain; }
  .service-card.style-6 .info h5 {
    font-weight: bold;
    font-size: 15px;
    margin-bottom: 15px; }
  .service-card.style-6 .info .text {
    font-size: 11px;
    color: #666; }

.services.style-7 {
  position: relative;
  background-color: #f0eff5; }
  .services.style-7 .services-content {
    position: relative; }
    .services.style-7 .services-content::after {
      position: absolute;
      content: "";
      left: 0;
      bottom: 0;
      width: 100%;
      height: 10px;
      background-image: url(../img/ser_wh_line.png);
      background-repeat: repeat-x; }
  .services.style-7 .service-item-style7 {
    text-align: center;
    margin-top: 30px;
    padding: 0 15px; }
    .services.style-7 .service-item-style7 .img {
      position: relative;
      width: 140px;
      height: 140px;
      margin: 0 auto 40px; }
      .services.style-7 .service-item-style7 .img img {
        width: 100%;
        height: 100%;
        -o-object-fit: cover;
        object-fit: cover;
        border-radius: 50%; }
      .services.style-7 .service-item-style7 .img .icon {
        width: 40px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        background-color: #fff;
        border-radius: 50%;
        color: var(--color-blue7);
        position: absolute;
        bottom: 0;
        right: 0;
        font-size: 20px; }
    .services.style-7 .service-item-style7 .info h6 {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 15px;
      text-transform: capitalize; }
    .services.style-7 .service-item-style7 .info .text {
      font-size: 16px;
      color: #666;
      margin-bottom: 30px; }
    .services.style-7 .service-item-style7 .info a {
      color: #000; }
      .services.style-7 .service-item-style7 .info a i {
        position: relative;
        top: 2px;
        margin: 0 5px;
        -webkit-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease; }
        .services.style-7 .service-item-style7 .info a i:first-of-type {
          opacity: 0;
          -webkit-transform: translateX(-10px);
          -ms-transform: translateX(-10px);
          transform: translateX(-10px); }
        .services.style-7 .service-item-style7 .info a i:last-of-type {
          opacity: 1;
          -webkit-transform: translateX(0);
          -ms-transform: translateX(0);
          transform: translateX(0); }
      .services.style-7 .service-item-style7 .info a:hover {
        color: var(--color-blue7);
        padding-left: 10px; }
        .services.style-7 .service-item-style7 .info a:hover i:first-of-type {
          opacity: 1;
          -webkit-transform: translateX(0);
          -ms-transform: translateX(0);
          transform: translateX(0); }
        .services.style-7 .service-item-style7 .info a:hover i:last-of-type {
          opacity: 0;
          -webkit-transform: translateX(10px);
          -ms-transform: translateX(10px);
          transform: translateX(10px); }

.services.style-8 {
  background-image: url(../img/services/serv8_back.png);
  background-size: cover; }
  .services.style-8 .services-slider8 .swiper-wrapper {
    -webkit-transition-timing-function: linear !important;
    -o-transition-timing-function: linear !important;
    transition-timing-function: linear !important;
    position: relative; }
  .services.style-8 .service-card.style-8 {
    text-align: center;
    padding: 50px;
    -webkit-box-shadow: 0px 16px 32px 0px #0000000a;
    box-shadow: 0px 16px 32px 0px #0000000a;
    border: 1px solid #f4f4f4;
    border-radius: 10px;
    -webkit-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease; }
    .services.style-8 .service-card.style-8:hover {
      -webkit-box-shadow: none;
      box-shadow: none;
      border-color: var(--color-main); }
      .services.style-8 .service-card.style-8:hover .info a {
        color: var(--color-main); }
    .services.style-8 .service-card.style-8 .icon {
      margin-bottom: 10px; }
    .services.style-8 .service-card.style-8 .info h5 {
      font-size: 24px;
      font-weight: bold;
      text-transform: capitalize; }
    .services.style-8 .service-card.style-8 .info p {
      font-size: 16px;
      color: #666;
      margin-top: 20px; }
    .services.style-8 .service-card.style-8 .info a {
      text-transform: uppercase;
      color: #c3c3c3;
      margin-top: 35px; }

@media screen and (min-width: 991px) {
  .services-slider8 {
    position: relative; }
    .services-slider8 .swiper-slide {
      width: 370px !important; } }

.services.style-9 .services-content {
  position: relative; }
  .services.style-9 .services-content::before {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    width: 100%;
    height: 50%;
    background-color: #fff; }
  .services.style-9 .services-content .service-card {
    padding: 35px;
    -webkit-box-shadow: 0px -16px 32px 0px #00000008;
    box-shadow: 0px -16px 32px 0px #00000008;
    border-radius: 6px;
    background-color: #fff;
    position: relative;
    z-index: 5; }
    .services.style-9 .services-content .service-card .img-icon {
      margin-bottom: 25px; }
      .services.style-9 .services-content .service-card .img-icon img {
        width: 60px;
        height: 60px;
        -o-object-fit: cover;
        object-fit: cover; }
    .services.style-9 .services-content .service-card h3 {
      font-size: 22px;
      font-weight: bold;
      color: var(--color-darkBlue2);
      margin-bottom: 15px; }
    .services.style-9 .services-content .service-card p {
      color: #666;
      margin-bottom: 30px; }
    .services.style-9 .services-content .service-card .icon {
      font-size: 18px;
      color: #999; }

.services.style-9 .con-info {
  padding: 30px;
  color: #fff;
  border-radius: 6px;
  background-image: -webkit-gradient(linear, left top, right top, from(#25146f), color-stop(#3680fc), to(#25146f));
  background-image: -webkit-linear-gradient(left, #25146f, #3680fc, #25146f);
  background-image: -o-linear-gradient(left, #25146f, #3680fc, #25146f);
  background-image: linear-gradient(to right, #25146f, #3680fc, #25146f);
  text-align: center;
  margin-top: 60px; }
  .services.style-9 .con-info h5 {
    font-size: 18px;
    font-weight: 300px; }

.services.style-10 {
  position: relative; }
  .services.style-10::before {
    position: absolute;
    content: "";
    left: 0;
    bottom: -1px;
    width: 100%;
    height: 50%;
    background-color: #f8f9fa; }
  .services.style-10 .content {
    position: relative;
    border-radius: 6px;
    background-color: #ffffff;
    -webkit-box-shadow: 0px -16px 32px 0px #0000000a;
    box-shadow: 0px -16px 32px 0px #0000000a;
    padding: 50px; }
    .services.style-10 .content .section-title {
      font-size: calc(8vw + 40px);
      text-transform: uppercase;
      position: absolute;
      top: 0;
      left: 50%;
      -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      transform: translateX(-50%);
      font-weight: bold;
      line-height: 0.75;
      color: #f6f6f6;
      background-image: -webkit-gradient(linear, left top, left bottom, from(#f6f6f6), to(#fafafa2f));
      background-image: -webkit-linear-gradient(top, #f6f6f6, #fafafa2f);
      background-image: -o-linear-gradient(top, #f6f6f6, #fafafa2f);
      background-image: linear-gradient(to bottom, #f6f6f6, #fafafa2f);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent; }
  .services.style-10 .services-card {
    position: relative;
    z-index: 10; }
    .services.style-10 .services-card h6 {
      font-size: 14px;
      color: #8169f1;
      margin-bottom: 15px; }
    .services.style-10 .services-card h3 {
      font-size: 22px;
      margin-bottom: 10px; }
    .services.style-10 .services-card p {
      color: #666; }
    .services.style-10 .services-card .icon {
      margin-top: 40px; }
      .services.style-10 .services-card .icon img {
        width: 75px;
        height: 60px;
        -o-object-fit: contain;
        object-fit: contain; }

/* ===============================================================
			[ * choose-us Section ] 
================================================================*/
/* --------------- choose-usstyles --------------- */
.choose-us.style-1 {
  position: relative; }
  .choose-us.style-1 .choose-us-img {
    position: absolute;
    left: -65px;
    bottom: -150px;
    width: 60%;
    height: 120%;
    max-height: none;
    -webkit-animation: rotate-center 100s linear infinite both;
    animation: rotate-center 100s linear infinite both; }
  .choose-us.style-1 .info .text {
    color: #666;
    margin-bottom: 40px; }
  .choose-us.style-1 .info ul li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 20px; }
    .choose-us.style-1 .info ul li .icon {
      -ms-flex-negative: 0;
      flex-shrink: 0;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background: var(--color-main-grad);
      color: #fff;
      display: -webkit-inline-box;
      display: -ms-inline-flexbox;
      display: inline-flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      margin-right: 15px; }
    .choose-us.style-1 .info ul li h6 {
      font-size: 15px;
      font-weight: 600; }
  .choose-us.style-1 .info .btn {
    margin-top: 60px; }
  .choose-us.style-1 .choose-us-brands {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 50%;
    -o-object-fit: contain;
    object-fit: contain;
    -webkit-animation: slide_up_down 2s ease-in-out infinite alternate both;
    animation: slide_up_down 2s ease-in-out infinite alternate both;
    z-index: 5; }
  .choose-us.style-1 .choose-us-bubbles {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 45%;
    -o-object-fit: contain;
    object-fit: contain;
    -webkit-animation: slide_up_down 2s ease-in-out infinite alternate both;
    animation: slide_up_down 2s ease-in-out infinite alternate both;
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
    z-index: 3; }

.choose-us.style-2 .img img {
  -webkit-animation: rotate-center 100s linear infinite both;
  animation: rotate-center 100s linear infinite both; }

.choose-us.style-2 .info h5 {
  font-weight: 600; }

.choose-us.style-2 .choose-numbers {
  position: relative;
  z-index: 5; }
  .choose-us.style-2 .choose-numbers .num-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
    .choose-us.style-2 .choose-numbers .num-item .num {
      color: #fff;
      font-size: 40px;
      font-weight: 400;
      -ms-flex-negative: 0;
      flex-shrink: 0;
      margin-right: 20px;
      min-width: 70px;
      text-align: center; }
      .choose-us.style-2 .choose-numbers .num-item .num i {
        font-size: 21px; }
    .choose-us.style-2 .choose-numbers .num-item .inf {
      color: #ccc;
      text-transform: uppercase;
      font-size: 13px;
      line-height: 1.7; }

/* --------------- choose-us style-6 --------------- */
.choose-us.style-6 {
  position: relative; }
  .choose-us.style-6 .section-head.style-6 h2 {
    font-size: 40px; }
  .choose-us.style-6 .info ul li .inf h5 {
    font-size: 18px;
    font-weight: bold;
    color: #000;
    text-transform: capitalize; }
  .choose-us.style-6 .img {
    padding-top: 50px;
    margin-left: -200px; }
  .choose-us.style-6 .bubbles {
    position: absolute;
    top: 0;
    left: 0;
    height: calc(100% - 200px); }

/* --------------- choose-us style-7 --------------- */
.choose-us.style-7 {
  position: relative;
  background-color: #000;
  color: #fff;
  overflow: hidden; }
  .choose-us.style-7 .top-wave {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%; }
  .choose-us.style-7 .bottom-wave {
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg); }
  .choose-us.style-7 .choose-circle {
    position: absolute;
    bottom: calc(50% - 165px);
    right: -165px;
    width: 330px;
    height: 300px;
    z-index: 1;
    pointer-events: none; }
  .choose-us.style-7 .img {
    position: relative; }
    .choose-us.style-7 .img img {
      height: 470px;
      border-radius: 10px;
      width: 100%;
      -o-object-fit: cover;
      object-fit: cover; }
    .choose-us.style-7 .img .play_btn {
      position: absolute;
      left: 50%;
      top: 50%;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      width: 60px;
      height: 60px;
      line-height: 60px;
      text-align: center;
      background-color: #fff;
      border-radius: 50%;
      background-color: #fff;
      color: #000;
      font-size: 14px;
      z-index: 2; }
  .choose-us.style-7 .info {
    -webkit-padding-start: 60px;
    padding-inline-start: 60px; }
    .choose-us.style-7 .info li {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      margin: 30px 0; }
      .choose-us.style-7 .info li .icon {
        -ms-flex-negative: 0;
        flex-shrink: 0;
        margin-right: 25px; }
        .choose-us.style-7 .info li .icon img {
          width: 60px;
          height: 60px;
          -o-object-fit: contain;
          object-fit: contain; }
      .choose-us.style-7 .info li .inf h6 {
        font-size: 18px;
        margin-bottom: 10px; }
      .choose-us.style-7 .info li .inf p {
        font-size: 16px;
        color: #ababab; }

/* --------------- choose-us style-8 --------------- */
.choose-us.style-8 .info .choose-card {
  padding: 30px;
  border: 1px solid #9992;
  border-radius: 10px;
  position: relative;
  margin-top: 40px; }
  .choose-us.style-8 .info .choose-card .icon {
    width: 50px;
    margin-bottom: 20px; }
  .choose-us.style-8 .info .choose-card h6 {
    font-size: 18px;
    font-weight: bold; }
  .choose-us.style-8 .info .choose-card .arrow {
    color: #e7e7e7;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
    position: absolute;
    right: 20px;
    top: 20px; }

/* --------------- choose-us style-9 --------------- */
.choose-us.style-9 .nav {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-bottom: 1px solid #9993; }
  .choose-us.style-9 .nav .nav-link {
    color: var(--color-darkBlue2);
    font-weight: bold;
    text-transform: capitalize;
    padding: 20px 15px;
    border-radius: 0; }
    .choose-us.style-9 .nav .nav-link img {
      width: 30px;
      height: 30px;
      -o-object-fit: contain;
      object-fit: contain;
      -webkit-margin-end: 20px;
      margin-inline-end: 20px;
      -webkit-filter: invert(26%) sepia(39%) saturate(6305%) hue-rotate(246deg) brightness(99%) contrast(105%);
      filter: invert(26%) sepia(39%) saturate(6305%) hue-rotate(246deg) brightness(99%) contrast(105%); }
    .choose-us.style-9 .nav .nav-link.active {
      background: transparent;
      border-bottom: 1px solid var(--color-darkBlue2); }

.choose-us.style-9 .feat-content {
  padding-top: 40px;
  position: relative; }
  .choose-us.style-9 .feat-content .img {
    height: 400px;
    border-radius: 10px;
    overflow: hidden; }
  .choose-us.style-9 .feat-content .info .icon {
    width: 80px;
    margin-bottom: 20px; }
  .choose-us.style-9 .feat-content .info h2 {
    font-size: 30px;
    color: var(--color-darkBlue2);
    margin-bottom: 15px; }
  .choose-us.style-9 .feat-content .info p {
    font-size: 16px;
    color: #666; }
  .choose-us.style-9 .feat-content .play_icon {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: #fff;
    color: var(--color-darkBlue2);
    line-height: 80px;
    text-align: center;
    font-size: 20px;
    position: absolute;
    top: 200px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    z-index: 10; }

/* ===============================================================
			[ * portfolio Section ] 
================================================================*/
/* --------------- portfolio styles --------------- */
.portfolio.style-1 {
  position: relative; }
  .portfolio.style-1 .shap_r {
    position: absolute;
    right: 0;
    top: 30px;
    width: 30%;
    opacity: 0.3;
    pointer-events: none; }
  .portfolio.style-1 .shap_l {
    position: absolute;
    left: 0;
    bottom: 20px;
    width: 40%;
    opacity: 1;
    pointer-events: none; }
  .portfolio.style-1 .portfolio-slider {
    position: relative;
    padding-bottom: 60px; }
    .portfolio.style-1 .portfolio-slider .swiper-button-next,
    .portfolio.style-1 .portfolio-slider .swiper-button-prev {
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%); }
    .portfolio.style-1 .portfolio-slider .swiper-button-next,
    .portfolio.style-1 .portfolio-slider .swiper-container-rtl .swiper-button-prev {
      right: -70px; }
    .portfolio.style-1 .portfolio-slider .swiper-button-prev,
    .portfolio.style-1 .portfolio-slider .swiper-container-rtl .swiper-button-next {
      left: -70px; }
  .portfolio.style-1 .portfolio-card {
    background-color: #fff;
    border-radius: 7px;
    overflow: hidden; }
    .portfolio.style-1 .portfolio-card .img {
      height: 240px;
      overflow: hidden; }
      .portfolio.style-1 .portfolio-card .img img {
        width: 100%;
        height: 100%;
        -o-object-fit: cover;
        object-fit: cover; }
    .portfolio.style-1 .portfolio-card .info {
      padding: 5px; }
      .portfolio.style-1 .portfolio-card .info h5 a {
        color: #000;
        font-weight: bold; }
      .portfolio.style-1 .portfolio-card .info small {
        font-size: 10px;
        letter-spacing: 1px;
        margin-top: 5px; }
      .portfolio.style-1 .portfolio-card .info .text {
        color: #666;
        margin: 15px 0 20px;
        font-size: 13px; }
      .portfolio.style-1 .portfolio-card .info .tags a {
        font-size: 11px;
        padding: 3px 7px;
        margin-top: 10px;
        border-radius: 4px;
        background-color: #eef4f8; }
    .portfolio.style-1 .portfolio-card:hover h5 a {
      color: var(--color-main); }

.projects.style-3 {
  background-color: #f0eff5; }

.project-card.style-3 {
  position: relative;
  overflow: hidden; }
  .project-card.style-3 .img {
    height: 360px;
    border-radius: 15px;
    overflow: hidden; }
    .project-card.style-3 .img img {
      -webkit-transition: all 1s ease;
      -o-transition: all 1s ease;
      transition: all 1s ease; }
  .project-card.style-3 .info {
    position: absolute;
    z-index: 5;
    bottom: 20px;
    left: 20px;
    background-color: #fff;
    border-radius: 15px;
    display: inline-block;
    padding: 20px 25px;
    -webkit-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;
    -webkit-transform: translateY(150%);
    -ms-transform: translateY(150%);
    transform: translateY(150%);
    opacity: 0; }
    .project-card.style-3 .info .h5 {
      font-weight: bold;
      font-size: 19px;
      margin-bottom: 0;
      color: #000; }
    .project-card.style-3 .info .small {
      font-size: 11px;
      color: #999; }
  .project-card.style-3:hover .img img {
    -webkit-transform: scale(1.1) rotate(-5deg);
    -ms-transform: scale(1.1) rotate(-5deg);
    transform: scale(1.1) rotate(-5deg); }
  .project-card.style-3:hover .info {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0); }

/* --------------- projects style-6 --------------- */
.projects.style-6 {
  padding: 0 30px; }
  .projects.style-6 .content {
    background-color: #f1f2fa;
    position: relative;
    padding: 100px 0;
    border-radius: 30px; }

.project-card.style-6 {
  border-radius: 8px;
  overflow: hidden;
  display: block;
  margin: 0 15px; }
  .project-card.style-6:hover {
    -webkit-box-shadow: 0px 40px 35px 0px #51558622;
    box-shadow: 0px 40px 35px 0px #51558622; }
  .project-card.style-6 a:hover {
    color: var(--color-blue6); }
  .project-card.style-6 .img {
    height: 240px;
    overflow: hidden; }
    .project-card.style-6 .img img {
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover; }
  .project-card.style-6 .info {
    background-color: #fff;
    padding: 40px;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease; }
    .project-card.style-6 .info .title {
      color: #000;
      font-size: 21px; }
      .project-card.style-6 .info .title:hover {
        color: var(--color-blue6); }
        .project-card.style-6 .info .title:hover a {
          color: var(--color-blue6); }
    .project-card.style-6 .info small {
      font-size: 11px;
      color: var(--color-blue6);
      text-transform: uppercase;
      margin-bottom: 10px; }
    .project-card.style-6 .info .text {
      font-size: 12px;
      color: #666;
      margin-bottom: 25px; }
    .project-card.style-6 .info .tags span {
      color: #010101;
      font-size: 10px;
      padding: 3px 7px;
      border-radius: 4px;
      background-color: #f1f2fa; }

/* --------------- projects style-7 --------------- */
.projects.style-7 {
  background-color: #f0eff5; }
  .projects.style-7 .content {
    padding-left: calc(calc(100vw - 1170px) / 2); }
  .projects.style-7 .projects-tabs {
    position: relative;
    padding-bottom: 80px; }
    .projects.style-7 .projects-tabs .nav-pills .nav-item .nav-link {
      font-size: 16px;
      border-radius: 0;
      background: transparent;
      color: #000;
      margin: 5px 0;
      text-transform: capitalize;
      margin-left: -20px;
      -webkit-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      transition: all 0.3s ease; }
      .projects.style-7 .projects-tabs .nav-pills .nav-item .nav-link i {
        opacity: 0;
        -webkit-transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
        transition: all 0.5s ease; }
      .projects.style-7 .projects-tabs .nav-pills .nav-item .nav-link:hover, .projects.style-7 .projects-tabs .nav-pills .nav-item .nav-link.active {
        text-decoration: underline;
        margin-left: 0; }
        .projects.style-7 .projects-tabs .nav-pills .nav-item .nav-link:hover i, .projects.style-7 .projects-tabs .nav-pills .nav-item .nav-link.active i {
          opacity: 1; }
    .projects.style-7 .projects-tabs .swiper-button-next,
    .projects.style-7 .projects-tabs .swiper-button-prev {
      width: 50px;
      height: 50px;
      background: #fff;
      color: #000;
      border-radius: 50%;
      top: auto;
      bottom: 0;
      -webkit-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      transition: all 0.3s ease; }
      .projects.style-7 .projects-tabs .swiper-button-next i,
      .projects.style-7 .projects-tabs .swiper-button-prev i {
        line-height: 50px; }
      .projects.style-7 .projects-tabs .swiper-button-next:hover,
      .projects.style-7 .projects-tabs .swiper-button-prev:hover {
        background: var(--color-blue7) !important;
        color: #fff; }
      .projects.style-7 .projects-tabs .swiper-button-next::after,
      .projects.style-7 .projects-tabs .swiper-button-prev::after {
        display: none; }
    .projects.style-7 .projects-tabs .swiper-button-next, .projects.style-7 .projects-tabs .swiper-container-rtl .swiper-button-prev {
      right: auto;
      left: 70px; }

@media screen and (min-width: 991px) {
  .projects-slider7 {
    position: relative; }
    .projects-slider7 .swiper-slide {
      width: 475px !important; } }

.project-card.style-7 {
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
  display: block; }
  .project-card.style-7 .info {
    padding: 55px; }
    .project-card.style-7 .info h6 {
      color: #a44cee;
      font-weight: 400;
      margin-bottom: 10px; }
    .project-card.style-7 .info h3 {
      font-size: 24px; }
  .project-card.style-7 .img {
    height: 400px; }
    .project-card.style-7 .img img {
      width: 100%;
      height: 100%;
      -o-object-fit: contain;
      object-fit: contain;
      -o-object-position: bottom center;
      object-position: bottom center; }

/* --------------- projects style-8 --------------- */
.projects.style-8 {
  overflow: hidden; }
  .projects.style-8 .section-head {
    position: relative; }
    .projects.style-8 .section-head .swiper-button-next,
    .projects.style-8 .section-head .swiper-button-prev {
      border-radius: 50%;
      width: 50px;
      height: 50px;
      -webkit-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      transition: all 0.3s ease;
      font-size: 18px;
      color: #000; }
      .projects.style-8 .section-head .swiper-button-next:hover,
      .projects.style-8 .section-head .swiper-button-prev:hover {
        background: var(--color-orange1);
        color: #fff; }
      .projects.style-8 .section-head .swiper-button-next::after,
      .projects.style-8 .section-head .swiper-button-prev::after {
        display: none; }
    .projects.style-8 .section-head .swiper-button-prev, .projects.style-8 .section-head .swiper-container-rtl .swiper-button-next {
      left: auto;
      right: 75px; }
  .projects.style-8 .projects-slider8 .swiper-container {
    overflow: visible; }
    .projects.style-8 .projects-slider8 .swiper-container .project-card {
      opacity: 0;
      -webkit-transition: all 0.5s ease;
      -o-transition: all 0.5s ease;
      transition: all 0.5s ease;
      -webkit-transform: scale(0.8);
      -ms-transform: scale(0.8);
      transform: scale(0.8); }
    .projects.style-8 .projects-slider8 .swiper-container .swiper-slide-active .project-card {
      opacity: 1;
      -webkit-transform: scale(1);
      -ms-transform: scale(1);
      transform: scale(1); }
  .projects.style-8 .img {
    border-radius: 10px;
    position: relative;
    height: 410px; }
    .projects.style-8 .img .main-img {
      border-radius: 10px;
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover; }
    .projects.style-8 .img .tags {
      position: absolute;
      z-index: 10;
      top: 30px;
      right: 30px; }
      .projects.style-8 .img .tags a {
        text-transform: uppercase;
        background-color: #fff;
        color: var(--color-main);
        padding: 5px 20px;
        border-radius: 30px; }
        .projects.style-8 .img .tags a:hover {
          background-color: var(--color-main);
          color: #fff; }
    .projects.style-8 .img .img-chart {
      position: absolute;
      top: 120px;
      z-index: 2;
      width: 200px;
      left: -100px; }
  .projects.style-8 .info {
    -webkit-padding-start: 50px;
    padding-inline-start: 50px; }
    .projects.style-8 .info .logo img {
      width: 90px;
      margin-bottom: 40px; }
    .projects.style-8 .info h4.title {
      font-size: 30px;
      font-weight: bold;
      margin-bottom: 15px; }
    .projects.style-8 .info p {
      font-size: 16px;
      color: #666;
      margin-bottom: 40px; }
    .projects.style-8 .info .proj-det {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      padding: 20px 0;
      border-top: 1px solid #9992;
      width: 100%; }
      .projects.style-8 .info .proj-det .item {
        -webkit-margin-end: 30px;
        margin-inline-end: 30px; }
        .projects.style-8 .info .proj-det .item p {
          color: #999;
          margin-bottom: 5px; }
      .projects.style-8 .info .proj-det .icon {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: var(--color-main);
        line-height: 40px;
        text-align: center;
        color: #fff;
        margin-right: 0;
        margin-left: auto; }

/* ===============================================================
			[ * testimonials Section ] 
================================================================*/
/* --------------- testimonials styles --------------- */
.testimonials.style-1 .content {
  position: relative;
  z-index: 5; }
  .testimonials.style-1 .content .vid_img {
    position: relative;
    height: 400px;
    min-height: 100%; }
    .testimonials.style-1 .content .vid_img::before {
      position: absolute;
      content: "";
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      background: -webkit-gradient(linear, left bottom, left top, from(#0007), to(#0001));
      background: -webkit-linear-gradient(bottom, #0007, #0001);
      background: -o-linear-gradient(bottom, #0007, #0001);
      background: linear-gradient(to top, #0007, #0001); }
    .testimonials.style-1 .content .vid_img img {
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover; }
    .testimonials.style-1 .content .vid_img .play_icon {
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background: var(--color-main-grad);
      z-index: 5; }
      .testimonials.style-1 .content .vid_img .play_icon i {
        color: #fff;
        font-size: 28px;
        margin-left: 3px; }
    .testimonials.style-1 .content .vid_img .img_info {
      position: absolute;
      left: 0;
      bottom: 0;
      padding: 30px;
      width: 100%;
      z-index: 5; }
      .testimonials.style-1 .content .vid_img .img_info h4 {
        color: #fff;
        font-weight: bold; }
      .testimonials.style-1 .content .vid_img .img_info small {
        color: #fff;
        display: block;
        font-size: 11px; }
  .testimonials.style-1 .content .info {
    position: relative;
    padding-left: 75px;
    min-height: 100%; }
    .testimonials.style-1 .content .info::after {
      position: absolute;
      content: "";
      left: 25px;
      top: 0;
      width: 2px;
      height: 100%;
      background-color: #9991; }
    .testimonials.style-1 .content .info .client_card {
      border: 1px solid #9994;
      border-radius: 5px;
      padding: 20px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      background-color: #fff; }
      .testimonials.style-1 .content .info .client_card .user_img {
        margin-right: 25px;
        -ms-flex-negative: 0;
        flex-shrink: 0; }
        .testimonials.style-1 .content .info .client_card .user_img img {
          width: 45px;
          height: 45px;
          border-radius: 50%;
          -o-object-fit: cover;
          object-fit: cover; }
      .testimonials.style-1 .content .info .client_card .inf_content .rate_stars {
        margin-bottom: 5px; }
        .testimonials.style-1 .content .info .client_card .inf_content .rate_stars i {
          color: #ffb400;
          font-size: 10px; }
      .testimonials.style-1 .content .info .client_card .inf_content h6 {
        font-weight: bold;
        font-size: 13px;
        margin-bottom: 15px; }
      .testimonials.style-1 .content .info .client_card .inf_content p {
        font-size: 12px;
        color: #000;
        text-transform: uppercase; }
        .testimonials.style-1 .content .info .client_card .inf_content p span {
          text-transform: capitalize; }

.reviews.style-2 {
  position: relative; }
  .reviews.style-2 .rev_l {
    position: absolute;
    left: 0;
    top: 30%;
    width: 20%;
    -webkit-animation: slide_up_down 2s ease-in-out infinite alternate both;
    animation: slide_up_down 2s ease-in-out infinite alternate both; }
  .reviews.style-2 .rev_r {
    position: absolute;
    right: 0;
    top: 30%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 20%;
    -webkit-animation: slide_up_down 2s ease-in-out infinite alternate both;
    animation: slide_up_down 2s ease-in-out infinite alternate both;
    -webkit-animation-delay: 1s;
    animation-delay: 1s; }

.swiper-slide .reviews_card.style-2 {
  opacity: 0;
  -webkit-transform: scale(0.8) translateY(20px);
  -ms-transform: scale(0.8) translateY(20px);
  transform: scale(0.8) translateY(20px);
  -webkit-transition: all 1.5s ease;
  -o-transition: all 1.5s ease;
  transition: all 1.5s ease;
  -webkit-transition-delay: 0.3s;
  -o-transition-delay: 0.3s;
  transition-delay: 0.3s; }

.swiper-slide.swiper-slide-active .reviews_card.style-2 {
  opacity: 1;
  -webkit-transform: scale(1) translateY(0);
  -ms-transform: scale(1) translateY(0);
  transform: scale(1) translateY(0); }

.reviews_card.style-2 .rev_user {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
  outline: 1px solid var(--color-lightBlue);
  outline-offset: 5px;
  margin: 40px auto 25px; }
  .reviews_card.style-2 .rev_user img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover; }

.reviews_card.style-2 .rev_stars i {
  color: greenyellow; }

.testimonials.style-3 {
  background-color: #f0eff5;
  margin-top: -30px;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  position: relative;
  z-index: 5; }
  .testimonials.style-3 .testi_lines {
    position: absolute;
    bottom: -10px; }
  .testimonials.style-3 .testimonial-card.style-3 .text {
    font-size: 18px;
    line-height: 28px;
    min-height: 112px; }
  .testimonials.style-3 .testimonial-card.style-3 p {
    font-size: 13px;
    line-height: 1.1; }
  .testimonials.style-3 .testimonial-card.style-3 small {
    font-size: 11px; }
  .testimonials.style-3 .testimonial-slider.style-3 {
    position: relative; }
    .testimonials.style-3 .testimonial-slider.style-3 .swiper-button-next {
      top: -65px;
      right: 0;
      width: 35px;
      height: 35px;
      border-radius: 50%; }
      .testimonials.style-3 .testimonial-slider.style-3 .swiper-button-next::after {
        font-size: 12px; }
      .testimonials.style-3 .testimonial-slider.style-3 .swiper-button-next:hover {
        background: var(--color-blue2); }
    .testimonials.style-3 .testimonial-slider.style-3 .swiper-button-prev {
      top: -65px;
      left: auto;
      right: 40px;
      width: 35px;
      height: 35px;
      border-radius: 50%; }
      .testimonials.style-3 .testimonial-slider.style-3 .swiper-button-prev::after {
        font-size: 12px; }
      .testimonials.style-3 .testimonial-slider.style-3 .swiper-button-prev:hover {
        background: var(--color-blue2); }

.testimonials.style-4 .content {
  border-bottom: 1px solid #9993;
  padding-bottom: 80px; }
  .testimonials.style-4 .content .numbs {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; }
    .testimonials.style-4 .content .numbs .num-card .icon {
      height: 55px;
      display: inline-block;
      margin-bottom: 15px; }
    .testimonials.style-4 .content .numbs .num-card h2 {
      color: var(--color-blue4); }
    .testimonials.style-4 .content .numbs .num-card p {
      font-size: 11px;
      color: #666;
      margin-top: 5px; }
    .testimonials.style-4 .content .numbs .num-card:not(:last-of-type) {
      padding-right: 50px;
      margin-right: 50px;
      border-right: 1px solid #9993; }
  .testimonials.style-4 .content .play-btn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
    .testimonials.style-4 .content .play-btn .icon {
      width: 42px;
      height: 42px;
      border: 1px solid #5842bc99;
      display: -webkit-inline-box;
      display: -ms-inline-flexbox;
      display: inline-flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      border-radius: 50%; }
      .testimonials.style-4 .content .play-btn .icon i {
        color: var(--color-blue4);
        font-size: 16px; }
  .testimonials.style-4 .content .stars {
    font-size: 10px;
    color: #fdb900;
    margin-bottom: 5px; }
  .testimonials.style-4 .content .testi-cards {
    position: relative;
    padding: 0 5vw; }
    .testimonials.style-4 .content .testi-cards .client_card {
      position: relative;
      z-index: 10;
      border-radius: 15px;
      padding: 25px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      background-color: #fff;
      -webkit-box-shadow: -4.104px 11.276px 38px 0px rgba(45, 42, 61, 0.1);
      box-shadow: -4.104px 11.276px 38px 0px rgba(45, 42, 61, 0.1);
      margin: 20px; }
      .testimonials.style-4 .content .testi-cards .client_card .user_img {
        margin-right: 25px;
        -ms-flex-negative: 0;
        flex-shrink: 0; }
        .testimonials.style-4 .content .testi-cards .client_card .user_img img {
          width: 90px;
          height: 90px;
          border-radius: 50%;
          -o-object-fit: cover;
          object-fit: cover; }
      .testimonials.style-4 .content .testi-cards .client_card .inf_content .rate_stars {
        margin-bottom: 5px; }
        .testimonials.style-4 .content .testi-cards .client_card .inf_content .rate_stars i {
          color: #ffb400;
          font-size: 10px; }
      .testimonials.style-4 .content .testi-cards .client_card .inf_content h6 {
        font-weight: bold;
        font-size: 12px;
        margin-bottom: 15px; }
      .testimonials.style-4 .content .testi-cards .client_card .inf_content p {
        font-size: 10px;
        color: #000;
        text-transform: uppercase; }
        .testimonials.style-4 .content .testi-cards .client_card .inf_content p span {
          text-transform: capitalize;
          color: #2E99F5; }
      .testimonials.style-4 .content .testi-cards .client_card:nth-of-type(2) {
        right: -5vw; }
    .testimonials.style-4 .content .testi-cards .testi-globe {
      position: absolute;
      top: -80px;
      left: 0;
      width: 110%;
      max-height: unset;
      max-width: unset;
      opacity: 0.2;
      -webkit-animation: rotate-center 100s linear infinite both;
      animation: rotate-center 100s linear infinite both; }

.testi-card.style-5 {
  display: block;
  padding: 40px 30px;
  background-color: #fff;
  border-radius: 15px;
  text-align: center;
  margin: 0 15px; }
  .testi-card.style-5:hover {
    -webkit-box-shadow: 0px 30px 60px 0px #0a2b5326;
    box-shadow: 0px 30px 60px 0px #0a2b5326; }
    .testi-card.style-5:hover .card-title {
      color: var(--color-blue5); }
  .testi-card.style-5 .stars i {
    color: #27a700;
    font-size: 15px; }
  .testi-card.style-5 .text {
    font-size: 19px;
    line-height: 1.6;
    color: #000;
    margin-top: 15px; }
  .testi-card.style-5 .user h6 {
    margin: 15px auto 0;
    font-size: 14px;
    font-weight: bold; }
  .testi-card.style-5 .user small {
    font-size: 11px;
    color: #666; }

.testimonials.style-6 {
  padding: 50px 0 120px; }
  .testimonials.style-6 .testi-slider.style-6 .icon {
    width: 95px;
    height: 95px;
    background-color: var(--color-blue6);
    color: #fff;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-radius: 60% 40% 40% 40%;
    font-size: 35px; }
  .testimonials.style-6 .testi-slider.style-6 .testi-card .text {
    color: #000;
    font-size: 23px;
    line-height: 1.4; }
  .testimonials.style-6 .testi-slider.style-6 .swiper-button-next,
  .testimonials.style-6 .testi-slider.style-6 .swiper-button-prev {
    background-color: #f1f2fa;
    width: 40px;
    height: 40px;
    top: 100%;
    margin-top: 40px; }
    .testimonials.style-6 .testi-slider.style-6 .swiper-button-next:hover,
    .testimonials.style-6 .testi-slider.style-6 .swiper-button-prev:hover {
      background-color: var(--color-blue6); }
  .testimonials.style-6 .testi-slider.style-6 .swiper-button-next {
    right: auto;
    left: 50px; }
  .testimonials.style-6 .testi-slider.style-6 .swiper-button-prev {
    left: 0; }
  .testimonials.style-6 .img {
    position: relative;
    margin-top: 50px; }
    .testimonials.style-6 .img img {
      position: relative;
      z-index: 5; }
    .testimonials.style-6 .img .bubbls {
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      -o-object-fit: contain;
      object-fit: contain;
      z-index: 0; }

.testimonials.style-7 {
  position: relative;
  background-color: #f0eff5; }
  .testimonials.style-7 .container {
    position: relative;
    z-index: 10; }
  .testimonials.style-7::after {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    width: 100%;
    height: 80%;
    background: -webkit-gradient(linear, left top, left bottom, from(#fff), to(transparent));
    background: -webkit-linear-gradient(top, #fff, transparent);
    background: -o-linear-gradient(top, #fff, transparent);
    background: linear-gradient(to bottom, #fff, transparent);
    pointer-events: none; }
  .testimonials.style-7 .img_back {
    position: absolute;
    left: 0;
    top: 100px;
    width: 100%;
    height: 70%;
    -o-object-fit: contain;
    object-fit: contain;
    pointer-events: none;
    z-index: 1; }
  .testimonials.style-7 .testi_qout {
    position: absolute;
    top: 150px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    z-index: 0;
    pointer-events: none; }
  .testimonials.style-7 .testi-card {
    z-index: 2; }
    .testimonials.style-7 .testi-card .text {
      font-size: 24px;
      text-align: center; }
    .testimonials.style-7 .testi-card .author {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      margin-top: 40px; }
      .testimonials.style-7 .testi-card .author .inf p {
        color: #946ed6; }
      .testimonials.style-7 .testi-card .author .inf h6 {
        font-size: 18px;
        font-weight: bold; }
  .testimonials.style-7 .get-qoute-banner {
    background-color: #fff;
    border-radius: 10px;
    padding: 0 50px;
    margin-top: 130px; }
    .testimonials.style-7 .get-qoute-banner .info p {
      color: #666;
      margin-bottom: 5px; }
    .testimonials.style-7 .get-qoute-banner .info h6 {
      font-size: 24px;
      font-weight: bold; }
    .testimonials.style-7 .get-qoute-banner .img {
      text-align: center; }
      .testimonials.style-7 .get-qoute-banner .img img {
        width: 200px;
        margin-top: -30px; }

.testimonials.style-8 .testi-card .info {
  background-color: #fff;
  padding: 30px;
  border-radius: 10px;
  position: relative; }
  .testimonials.style-8 .testi-card .info::after {
    position: absolute;
    content: "";
    bottom: -20px;
    left: 100px;
    width: 50px;
    height: 20px;
    border-left: 25px solid #fff;
    border-right: 25px solid transparent;
    border-top: 10px solid #fff;
    border-bottom: 10px solid transparent; }
  .testimonials.style-8 .testi-card .info .stars {
    color: var(--color-orange1);
    margin-bottom: 25px;
    position: relative;
    z-index: 5; }
  .testimonials.style-8 .testi-card .info p {
    font-size: 20px;
    position: relative;
    z-index: 5; }
  .testimonials.style-8 .testi-card .info .icon {
    position: absolute;
    width: 100px;
    right: 30px;
    top: 30px;
    pointer-events: none; }

.testimonials.style-8 .testi-card .author {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }
  .testimonials.style-8 .testi-card .author .inf p {
    color: var(--color-main);
    text-transform: uppercase; }
  .testimonials.style-8 .testi-card .author .inf h6 {
    font-size: 18px;
    font-weight: bold; }

.testimonials.style-9 {
  background-image: url(../img/testimonials/testi9_back.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat; }
  .testimonials.style-9 .testi-head h3 {
    font-size: 30px;
    color: var(--color-darkBlue2); }
  .testimonials.style-9 .testi-head .reviews-numb {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
    .testimonials.style-9 .testi-head .reviews-numb .img {
      -webkit-padding-end: 30px;
      padding-inline-end: 30px;
      -webkit-margin-end: 30px;
      margin-inline-end: 30px;
      border-right: 1px solid #9993; }
      .testimonials.style-9 .testi-head .reviews-numb .img .logo {
        width: 125px;
        margin-bottom: 3px; }
      .testimonials.style-9 .testi-head .reviews-numb .img .stars {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex; }
        .testimonials.style-9 .testi-head .reviews-numb .img .stars .star {
          width: 25px;
          -webkit-margin-end: 1px;
          margin-inline-end: 1px; }
  .testimonials.style-9 .testi-card .stars {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 25px; }
    .testimonials.style-9 .testi-card .stars .star {
      width: 20px;
      -webkit-margin-end: 1px;
      margin-inline-end: 1px; }
  .testimonials.style-9 .testi-card .text {
    font-size: 17px;
    color: var(--color-darkBlue2);
    margin-bottom: 40px;
    font-weight: 500;
    line-height: 1.7;
    min-height: 150px; }
  .testimonials.style-9 .testi-card .author {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
    .testimonials.style-9 .testi-card .author .inf p {
      color: #666; }
    .testimonials.style-9 .testi-card .author .inf h6 {
      font-size: 18px;
      font-weight: bold;
      color: var(--color-darkBlue2); }

.testimonials.style-11 {
  background-color: #f6f6f6; }
  .testimonials.style-11 .testimonials-slider11 {
    position: relative; }
    .testimonials.style-11 .testimonials-slider11::after {
      position: absolute;
      content: "";
      left: 0;
      bottom: 0;
      width: 100%;
      height: 50%;
      background-color: #fff; }
    .testimonials.style-11 .testimonials-slider11 .swiper-container {
      padding-bottom: 30px; }
    .testimonials.style-11 .testimonials-slider11 .swiper-slide {
      padding: 30px; }
    .testimonials.style-11 .testimonials-slider11 .swiper-pagination-bullet {
      cursor: pointer;
      width: 20px;
      height: 3px;
      border-radius: 0; }
    .testimonials.style-11 .testimonials-slider11 .swiper-pagination-bullet-active {
      background-color: #000;
      border-radius: 0;
      width: 30px !important; }
  .testimonials.style-11 .testimonial-card {
    background-color: #ffff;
    border-radius: 15px;
    -webkit-box-shadow: 0 0 30px #0001;
    box-shadow: 0 0 30px #0001;
    overflow: hidden; }
    .testimonials.style-11 .testimonial-card .img {
      height: 100%;
      position: relative; }
      .testimonials.style-11 .testimonial-card .img .play_icon {
        width: 100px;
        height: 100px;
        background-color: #fff;
        border-radius: 50%;
        line-height: 100px;
        text-align: center;
        color: #000;
        font-size: 18px;
        position: absolute;
        bottom: 50px;
        left: 50px;
        z-index: 10; }
    .testimonials.style-11 .testimonial-card .info {
      padding: 5vw; }
      .testimonials.style-11 .testimonial-card .info .icon {
        width: 130px;
        margin-bottom: 30px; }
      .testimonials.style-11 .testimonial-card .info h4 {
        font-size: 30px;
        font-weight: bold; }
      .testimonials.style-11 .testimonial-card .info .author {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        margin-top: 40px; }
        .testimonials.style-11 .testimonial-card .info .author .inf p {
          color: #999; }
        .testimonials.style-11 .testimonial-card .info .author .inf h6 {
          font-size: 20px;
          font-weight: bold; }
  .testimonials.style-11 .logos-content {
    background-color: #fff; }
    .testimonials.style-11 .logos-content .logos {
      border-bottom: 1px solid #9993; }
      .testimonials.style-11 .logos-content .logos .row > div {
        text-align: center; }
        .testimonials.style-11 .logos-content .logos .row > div:first-of-type {
          text-align: start; }
        .testimonials.style-11 .logos-content .logos .row > div:last-of-type {
          text-align: end; }
      .testimonials.style-11 .logos-content .logos .logo {
        opacity: 30%;
        margin-bottom: 30px; }
        .testimonials.style-11 .logos-content .logos .logo:hover {
          opacity: 1; }
    .testimonials.style-11 .logos-content p {
      font-size: 16px;
      margin-top: 30px;
      text-align: center;
      color: #777; }
      .testimonials.style-11 .logos-content p strong {
        color: #000; }

/* ===============================================================
			[ * testimonials Section ] 
================================================================*/
/* --------------- pricing styles --------------- */
.pricing.style-2 {
  background: -webkit-gradient(linear, left top, left bottom, from(#010049), to(#157aa6));
  background: -webkit-linear-gradient(top, #010049, #157aa6);
  background: -o-linear-gradient(top, #010049, #157aa6);
  background: linear-gradient(to bottom, #010049, #157aa6);
  position: relative; }
  .pricing.style-2 .shap_l {
    position: absolute;
    left: 0;
    top: 20%;
    max-width: 30%; }
  .pricing.style-2 .shap_r {
    position: absolute;
    right: 0;
    top: 10%;
    height: 80%;
    max-width: 30%; }
  .pricing.style-2 .toggle_switch .form-check-label {
    pointer-events: none; }
  .pricing.style-2 .yearly_price,
  .pricing.style-2 .monthly_price {
    display: none; }
    .pricing.style-2 .yearly_price.show,
    .pricing.style-2 .monthly_price.show {
      display: block; }

.pricing_card.style-2 {
  position: relative;
  text-align: center;
  color: #fff;
  padding: 85px 50px 50px;
  border: 1px solid #3d9dd1; }
  .pricing_card.style-2 p {
    text-transform: uppercase;
    margin: 30px 0 15px;
    font-size: 16px; }
  .pricing_card.style-2 .price .h2 small {
    font-size: 13px;
    position: relative;
    top: -12px; }
  .pricing_card.style-2 ul {
    min-height: 164px; }
    .pricing_card.style-2 ul li {
      border-color: #3d9dd181 !important; }
  .pricing_card.style-2 .hint {
    position: absolute;
    top: 25px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    font-size: 11px;
    color: #fff;
    padding: 5px 8px;
    border-radius: 3px;
    background-color: #1f3f85;
    text-transform: capitalize; }
  .pricing_card.style-2.recommended::before {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    width: 100%;
    height: 10px;
    background: var(--color-lightBlue); }

.pricing.style-3 {
  position: relative; }
  .pricing.style-3 .testi_lines {
    position: absolute;
    bottom: -10px; }

.pricing-card.style-3 {
  padding: 30px;
  border-radius: 20px;
  background-color: #f3f2f7; }
  .pricing-card.style-3 .card-head {
    border-bottom: 1px solid #9993;
    padding-bottom: 25px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between; }
    .pricing-card.style-3 .card-head h4 {
      font-size: 19px;
      font-weight: bold;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center; }
      .pricing-card.style-3 .card-head h4 small {
        font-size: 10px;
        font-weight: 400;
        padding: 3px 5px;
        border-radius: 3px;
        color: #fff !important;
        background-color: #6e27ff;
        margin: 0 5px; }
    .pricing-card.style-3 .card-head small {
      font-size: 11px;
      color: #999999; }
    .pricing-card.style-3 .card-head .price {
      text-align: right; }
      .pricing-card.style-3 .card-head .price h5 {
        font-size: 20px;
        color: var(--color-blue2);
        font-weight: bold; }
  .pricing-card.style-3 .card-body {
    min-height: 180px;
    padding: 20px 0; }
    .pricing-card.style-3 .card-body li {
      line-height: 1.2;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center; }
      .pricing-card.style-3 .card-body li small {
        font-size: 11px;
        margin: 5px 0; }
      .pricing-card.style-3 .card-body li i {
        -ms-flex-negative: 0;
        flex-shrink: 0;
        color: var(--color-blue2);
        font-size: 20px;
        margin-right: 10px; }
  .pricing-card.style-3.dark-card {
    background-color: var(--color-blue2); }
    .pricing-card.style-3.dark-card .card-head {
      border-bottom: 1px solid #9999ff; }
      .pricing-card.style-3.dark-card .card-head h4, .pricing-card.style-3.dark-card .card-head h5 {
        color: #fff; }
      .pricing-card.style-3.dark-card .card-head small {
        color: #9999ff; }
    .pricing-card.style-3.dark-card .card-body li {
      color: #fff; }
      .pricing-card.style-3.dark-card .card-body li i {
        color: #9999ff; }
    .pricing-card.style-3.dark-card .btn {
      background-color: #fff !important; }
      .pricing-card.style-3.dark-card .btn span {
        color: #000; }

.pricing.style-4 .form-check-input.bg-blue4 {
  background-color: var(--color-blue4);
  border-color: var(--color-blue4); }

.pricing.style-4 .content .row .col-lg-6:nth-of-type(1) .price-card {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 0;
  margin-right: -1px; }

.pricing.style-4 .content .row .col-lg-6:nth-of-type(2) .price-card {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  background-color: #f8f7fd; }

.pricing.style-4 .content .price-card {
  position: relative;
  border: 1px solid #e9e8ee;
  border-radius: 15px;
  padding: 40px; }
  .pricing.style-4 .content .price-card .off {
    position: absolute;
    width: 85px;
    height: 85px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
    color: #fff;
    background-image: url(../img/pricing/off.png);
    background-size: contain;
    background-repeat: no-repeat;
    top: -20px;
    right: 45px;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase; }
  .pricing.style-4 .content .price-card .price-header {
    border-bottom: 1px solid #e9e8ee; }
    .pricing.style-4 .content .price-card .price-header h6 {
      font-size: 13px;
      font-weight: bold;
      text-transform: uppercase;
      letter-spacing: 1px; }
      .pricing.style-4 .content .price-card .price-header h6 .icon {
        width: 30px;
        height: 30px;
        margin-right: 8px; }
    .pricing.style-4 .content .price-card .price-header h2 {
      font-size: 37px;
      color: #000;
      margin: 12px 0 5px; }
      .pricing.style-4 .content .price-card .price-header h2 small {
        font-size: 15px;
        font-weight: 300; }
    .pricing.style-4 .content .price-card .price-header p {
      font-size: 12px;
      color: #666; }

.pricing.style-5 .pricing-tabsHead .price-radios {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  background-color: #edf2fa;
  padding: 7px;
  border-radius: 50px; }
  .pricing.style-5 .pricing-tabsHead .price-radios .form-check {
    margin: 0;
    padding: 0; }
    .pricing.style-5 .pricing-tabsHead .price-radios .form-check .form-check-label {
      color: #000;
      background-color: transparent;
      font-size: 12px;
      font-weight: bold;
      border-radius: 30px;
      padding: 10px 15px;
      text-transform: capitalize;
      cursor: pointer; }
      .pricing.style-5 .pricing-tabsHead .price-radios .form-check .form-check-label small {
        padding: 1px 5px;
        font-size: 10px; }
    .pricing.style-5 .pricing-tabsHead .price-radios .form-check .form-check-input {
      display: none; }
    .pricing.style-5 .pricing-tabsHead .price-radios .form-check .form-check-input:checked ~ .form-check-label {
      background-color: #fff;
      -webkit-box-shadow: 0 5px 5px #0001;
      box-shadow: 0 5px 5px #0001; }

.pricing.style-5 .content {
  min-width: 800px;
  margin-top: 50px; }
  .pricing.style-5 .content .price-head {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    border-bottom: 1px solid #7f7f7f; }
    .pricing.style-5 .content .price-head .price-headTitle {
      width: 34%; }
    .pricing.style-5 .content .price-head .price-headItem {
      position: relative;
      width: 22%;
      text-align: center;
      padding: 30px 0;
      border-top-left-radius: 15px;
      border-top-right-radius: 15px; }
      .pricing.style-5 .content .price-head .price-headItem .label {
        position: absolute;
        top: -30px;
        right: 15px;
        z-index: 5;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        width: 60px;
        height: 60px;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        text-align: center;
        text-transform: uppercase;
        font-size: 9px;
        font-weight: bold;
        background-image: url(../img/pricing/label_success.png);
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        color: #fff; }
      .pricing.style-5 .content .price-head .price-headItem h6 {
        font-size: 12px;
        font-weight: bold;
        text-transform: uppercase;
        margin-bottom: 10px; }
      .pricing.style-5 .content .price-head .price-headItem h2 {
        font-size: 45px;
        margin-bottom: 5px; }
        .pricing.style-5 .content .price-head .price-headItem h2 span {
          font-size: 18px;
          color: #999;
          font-weight: 400; }
      .pricing.style-5 .content .price-head .price-headItem small {
        font-size: 11px;
        color: #666;
        display: block;
        line-height: 1.5; }
  .pricing.style-5 .content .price-body .price-bodyItems {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    border-bottom: 1px solid #f2f2f2; }
    .pricing.style-5 .content .price-body .price-bodyItems:last-of-type {
      border: 0; }
    .pricing.style-5 .content .price-body .price-bodyItems .price-bodyTitle {
      width: 34%;
      padding: 15px 0;
      font-size: 14px;
      height: 60px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      min-height: -webkit-max-content;
      min-height: -moz-max-content;
      min-height: max-content;
      position: relative; }
      .pricing.style-5 .content .price-body .price-bodyItems .price-bodyTitle .pop-info {
        position: relative;
        display: inline-block;
        cursor: pointer; }
        .pricing.style-5 .content .price-body .price-bodyItems .price-bodyTitle .pop-info i.bttn-info {
          font-size: 11px;
          color: #999;
          margin: 0 3px; }
          .pricing.style-5 .content .price-body .price-bodyItems .price-bodyTitle .pop-info i.bttn-info:hover {
            color: var(--color-blue5); }
        .pricing.style-5 .content .price-body .price-bodyItems .price-bodyTitle .pop-info .hidden_content {
          position: absolute;
          bottom: 15px;
          left: 15px;
          padding: 20px;
          border-radius: 10px;
          background-color: #f7f7f7;
          text-align: center;
          width: 250px;
          -webkit-transition: all 0.4s ease;
          -o-transition: all 0.4s ease;
          transition: all 0.4s ease;
          opacity: 0;
          visibility: hidden; }
          .pricing.style-5 .content .price-body .price-bodyItems .price-bodyTitle .pop-info .hidden_content .title {
            color: var(--color-blue5);
            text-transform: uppercase;
            margin-bottom: 5px;
            font-size: 13px;
            font-weight: 500; }
        .pricing.style-5 .content .price-body .price-bodyItems .price-bodyTitle .pop-info:hover .hidden_content {
          opacity: 1;
          visibility: visible; }
    .pricing.style-5 .content .price-body .price-bodyItems .price-item {
      width: 22%;
      padding: 15px 0;
      text-align: center;
      height: 60px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      min-height: -webkit-max-content;
      min-height: -moz-max-content;
      min-height: max-content; }
      .pricing.style-5 .content .price-body .price-bodyItems .price-item i {
        color: var(--color-blue5);
        font-size: 20px; }
      .pricing.style-5 .content .price-body .price-bodyItems .price-item span {
        color: #000;
        font-size: 14px; }
  .pricing.style-5 .content .price-foot {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; }
    .pricing.style-5 .content .price-foot .price-footTitle {
      width: 34%; }
    .pricing.style-5 .content .price-foot .price-footItem {
      width: 22%;
      text-align: center;
      padding: 30px 0 50px;
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px; }

.pricing.style-5 .monthly_price {
  display: none; }

.pricing.style-8 .pricing-head {
  padding: 100px 0 70px;
  background-color: #000; }
  .pricing.style-8 .pricing-head .ui-widget-content {
    border-radius: 15px !important;
    height: 5px;
    border: 0;
    background-color: #151515;
    position: relative; }
  .pricing.style-8 .pricing-head .ui-state-default,
  .pricing.style-8 .pricing-head .ui-widget-content .ui-state-default,
  .pricing.style-8 .pricing-head .ui-widget-header .ui-state-default,
  .pricing.style-8 .pricing-head .ui-button, .pricing.style-8 .pricing-head html .ui-button.ui-state-disabled:hover,
  .pricing.style-8 .pricing-head html .ui-button.ui-state-disabled:active {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #fff;
    border: 0;
    top: -6px;
    -webkit-transition: 0s;
    -o-transition: 0s;
    transition: 0s; }
  .pricing.style-8 .pricing-head .ui-slider-horizontal .ui-slider-range-min {
    background-color: var(--color-main); }
  .pricing.style-8 .pricing-head .users-number {
    color: #606060;
    display: inline-block;
    position: absolute;
    left: 0; }
    .pricing.style-8 .pricing-head .users-number input {
      background: transparent;
      border: 0;
      width: 35px !important;
      color: #fff;
      margin-top: 15px;
      min-width: none;
      font-weight: bold; }
  .pricing.style-8 .pricing-head .pricing-tabsHead .price-radios {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    background-color: #131313;
    padding: 5px;
    border-radius: 50px; }
    .pricing.style-8 .pricing-head .pricing-tabsHead .price-radios .form-check {
      margin: 0;
      padding: 0; }
      .pricing.style-8 .pricing-head .pricing-tabsHead .price-radios .form-check .form-check-label {
        color: #fff;
        background-color: transparent;
        font-size: 12px;
        font-weight: bold;
        border-radius: 30px;
        padding: 10px 15px;
        text-transform: uppercase;
        cursor: pointer; }
        .pricing.style-8 .pricing-head .pricing-tabsHead .price-radios .form-check .form-check-label small {
          padding: 1px 5px;
          font-size: 10px; }
      .pricing.style-8 .pricing-head .pricing-tabsHead .price-radios .form-check .form-check-input {
        display: none; }
      .pricing.style-8 .pricing-head .pricing-tabsHead .price-radios .form-check .form-check-input:checked ~ .form-check-label {
        background-color: var(--color-orange1);
        -webkit-box-shadow: 0 5px 5px #0001;
        box-shadow: 0 5px 5px #0001; }

.pricing.style-8 .pricing-body {
  position: relative; }
  .pricing.style-8 .pricing-body::after {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    width: 100%;
    height: 60px;
    background-color: #000; }
  .pricing.style-8 .pricing-body .content {
    padding-bottom: 100px;
    border-bottom: 1px solid #9993; }
  .pricing.style-8 .pricing-body .popular-head {
    height: 60px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: var(--color-orange1);
    color: #fff;
    text-transform: uppercase;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center; }
  .pricing.style-8 .pricing-body .pricing-card {
    position: relative;
    padding: 50px;
    background-color: #fff;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    z-index: 5; }
    .pricing.style-8 .pricing-body .pricing-card:hover .price .price-btn {
      opacity: 1;
      -webkit-transform: translateX(0);
      -ms-transform: translateX(0);
      transform: translateX(0);
      visibility: visible; }
    .pricing.style-8 .pricing-body .pricing-card:hover .price p {
      -webkit-transform: translateX(50px);
      -ms-transform: translateX(50px);
      transform: translateX(50px);
      opacity: 1; }
    .pricing.style-8 .pricing-body .pricing-card .pricing-title {
      margin-bottom: 40px; }
      .pricing.style-8 .pricing-body .pricing-card .pricing-title h2 {
        font-weight: bold;
        font-size: 30px;
        text-transform: capitalize; }
      .pricing.style-8 .pricing-body .pricing-card .pricing-title p {
        font-size: 16px;
        color: #666; }
    .pricing.style-8 .pricing-body .pricing-card .price {
      position: relative;
      margin-bottom: 40px;
      height: 60px;
      border-radius: 50px;
      background-color: #eff4fc;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      overflow: hidden; }
      .pricing.style-8 .pricing-body .pricing-card .price p {
        font-size: 16px;
        color: var(--color-main);
        font-weight: bold;
        -webkit-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease; }
      .pricing.style-8 .pricing-body .pricing-card .price .price-btn {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 60px;
        border-radius: 50px;
        background-color: var(--color-main);
        color: #fff;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        z-index: 10;
        opacity: 0;
        -webkit-transform: translateX(-50px);
        -ms-transform: translateX(-50px);
        transform: translateX(-50px);
        visibility: hidden; }
        .pricing.style-8 .pricing-body .pricing-card .price .price-btn i {
          margin-left: 10px;
          position: relative;
          top: 2px; }
    .pricing.style-8 .pricing-body .pricing-card .pricing-info li {
      font-size: 16px;
      font-weight: 500;
      margin: 20px 0; }
      .pricing.style-8 .pricing-body .pricing-card .pricing-info li .icon {
        width: 20px;
        -webkit-margin-end: 20px;
        margin-inline-end: 20px; }

.pricing.style-9 .pricing-card {
  padding: 50px;
  background-color: #fff;
  border-radius: 6px; }
  .pricing.style-9 .pricing-card .card-head {
    margin-bottom: 20px; }
    .pricing.style-9 .pricing-card .card-head h5 {
      font-size: 20px;
      color: var(--color-darkBlue2);
      font-weight: bold;
      margin-bottom: 5px; }
    .pricing.style-9 .pricing-card .card-head p {
      color: #666;
      font-size: 14px; }
  .pricing.style-9 .pricing-card .price {
    font-size: 40px;
    color: var(--color-darkBlue2); }
    .pricing.style-9 .pricing-card .price small {
      font-size: 20px;
      font-weight: 300; }
  .pricing.style-9 .pricing-card .hightLight {
    color: #6c41ff;
    padding: 5px 10px;
    text-align: center;
    background-color: #f1eeff;
    margin-bottom: 15px;
    border-radius: 6px;
    margin-bottom: 20px;
    margin-top: 5px;
    display: inline-block; }
  .pricing.style-9 .pricing-card li {
    font-size: 16px;
    color: #666;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-top: 10px; }

.pricing.style-9 .offer-text {
  text-align: center;
  color: var(--color-darkBlue2);
  font-size: 18px;
  margin-top: 50px; }
  .pricing.style-9 .offer-text a {
    color: #6c41ff;
    text-decoration: underline !important; }

.pricing.style-10 .pricing-table {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }
  .pricing.style-10 .pricing-table .table-titles {
    width: 30%; }
    .pricing.style-10 .pricing-table .table-titles .main-head {
      padding: 45px; }
      .pricing.style-10 .pricing-table .table-titles .main-head .icon {
        margin-bottom: 20px; }
        .pricing.style-10 .pricing-table .table-titles .main-head .icon img {
          height: 80px; }
      .pricing.style-10 .pricing-table .table-titles .main-head p {
        color: #666; }
        .pricing.style-10 .pricing-table .table-titles .main-head p strong {
          color: #000; }
    .pricing.style-10 .pricing-table .table-titles .main-body {
      background-color: #f7f7f7;
      padding: 45px;
      border-radius: 8px 0 0 8px; }
      .pricing.style-10 .pricing-table .table-titles .main-body li {
        font-size: 15px;
        font-weight: bold;
        padding: 20px 0;
        border-bottom: 1px solid #9993; }
        .pricing.style-10 .pricing-table .table-titles .main-body li:first-of-type {
          padding-top: 0; }
        .pricing.style-10 .pricing-table .table-titles .main-body li:last-of-type {
          border: 0;
          padding-bottom: 0; }
  .pricing.style-10 .pricing-table .table-body-card {
    width: calc(70% / 3);
    position: relative;
    border-radius: 8px 8px 0 0; }
    .pricing.style-10 .pricing-table .table-body-card .sub-head {
      height: 237px;
      padding: 40px; }
      .pricing.style-10 .pricing-table .table-body-card .sub-head h2 {
        font-size: 35px;
        margin-bottom: 30px; }
        .pricing.style-10 .pricing-table .table-body-card .sub-head h2 small {
          font-size: 20px;
          color: #666;
          font-weight: 400; }
    .pricing.style-10 .pricing-table .table-body-card .sub-body {
      border: 15px solid #f7f7f7;
      text-align: center; }
      .pricing.style-10 .pricing-table .table-body-card .sub-body ul {
        background-color: #fff;
        padding: 30px;
        border-radius: 8px; }
        .pricing.style-10 .pricing-table .table-body-card .sub-body ul li {
          font-size: 15px;
          font-weight: 400;
          padding: 20px 0;
          border-bottom: 1px solid #9992;
          text-transform: capitalize; }
          .pricing.style-10 .pricing-table .table-body-card .sub-body ul li:first-of-type {
            padding-top: 0; }
          .pricing.style-10 .pricing-table .table-body-card .sub-body ul li:last-of-type {
            border: 0;
            padding-bottom: 0; }
          .pricing.style-10 .pricing-table .table-body-card .sub-body ul li strong {
            font-size: 12px;
            -webkit-margin-end: 10px;
            margin-inline-end: 10px;
            display: none; }
    .pricing.style-10 .pricing-table .table-body-card.recommended-card {
      background-color: #8169f1;
      color: #fff; }
      .pricing.style-10 .pricing-table .table-body-card.recommended-card .sub-head h2 small {
        color: #fff; }
      .pricing.style-10 .pricing-table .table-body-card.recommended-card .sub-body {
        border: 15px solid #8169f1; }
        .pricing.style-10 .pricing-table .table-body-card.recommended-card .sub-body ul {
          background-color: #8169f1; }
          .pricing.style-10 .pricing-table .table-body-card.recommended-card .sub-body ul li {
            border-color: #fff2; }

/* ===============================================================
			[ * team Section ] 
================================================================*/
/* --------------- team styles --------------- */
.team.style-1 {
  position: relative; }
  .team.style-1 .team_shap {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    max-height: none;
    z-index: -1; }
  .team.style-1 .content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
    .team.style-1 .content .team_box {
      width: 18%;
      text-align: center;
      margin-bottom: 20px; }
      .team.style-1 .content .team_box .avatar {
        width: 135px;
        height: 135px;
        border-radius: 50%;
        overflow: hidden;
        margin: 0 auto 15px; }
        .team.style-1 .content .team_box .avatar img {
          width: 100%;
          height: 100%;
          -o-object-fit: cover;
          object-fit: cover; }
      .team.style-1 .content .team_box .info h6 {
        font-weight: bold;
        -webkit-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease; }
      .team.style-1 .content .team_box .info small {
        display: block;
        font-size: 13px;
        color: #888; }
      .team.style-1 .content .team_box .info .social_icons {
        margin-top: 10px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center; }
        .team.style-1 .content .team_box .info .social_icons a {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          display: -webkit-inline-box;
          display: -ms-inline-flexbox;
          display: inline-flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          background-color: #eef4f8;
          color: #666;
          margin: 5px; }
          .team.style-1 .content .team_box .info .social_icons a:hover {
            background: var(--color-main-grad);
            color: #fff; }
      .team.style-1 .content .team_box:hover .info h6 {
        color: var(--color-main); }
  .team.style-1.team-blue2 .content .team_box .social_icons a:hover {
    background: var(--color-blue2);
    color: #fff; }
  .team.style-1.team-blue2 .content .team_box:hover .info h6 {
    color: var(--color-blue2); }

/* --------------- team style-6 --------------- */
.team.style-6 {
  padding: 0 30px; }
  .team.style-6 .content {
    background-color: #f1f2fa;
    position: relative;
    padding: 100px 0;
    border-radius: 30px; }

.team-card.style-6 {
  text-align: center;
  padding: 15px;
  background-color: #fff;
  border-radius: 20px;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease; }
  .team-card.style-6 .img {
    position: relative;
    height: 320px;
    border-radius: 15px;
    overflow: hidden; }
    .team-card.style-6 .img .social-icons {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      padding: 20px;
      z-index: 5; }
      .team-card.style-6 .img .social-icons a {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: #fff;
        color: #666;
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        font-size: 11px;
        -webkit-transform: translateY(80px);
        -ms-transform: translateY(80px);
        transform: translateY(80px);
        opacity: 0;
        -webkit-transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
        transition: all 0.5s ease; }
        .team-card.style-6 .img .social-icons a:hover {
          background-color: var(--color-blue6);
          color: #fff;
          -webkit-transition: all 0.3s ease;
          -o-transition: all 0.3s ease;
          transition: all 0.3s ease;
          -webkit-transition-delay: 0 !important;
          -o-transition-delay: 0 !important;
          transition-delay: 0 !important; }
        .team-card.style-6 .img .social-icons a:nth-of-type(1) {
          -webkit-transition-delay: 0.1s;
          -o-transition-delay: 0.1s;
          transition-delay: 0.1s; }
        .team-card.style-6 .img .social-icons a:nth-of-type(2) {
          -webkit-transition-delay: 0.2s;
          -o-transition-delay: 0.2s;
          transition-delay: 0.2s; }
        .team-card.style-6 .img .social-icons a:nth-of-type(3) {
          -webkit-transition-delay: 0.3s;
          -o-transition-delay: 0.3s;
          transition-delay: 0.3s; }
        .team-card.style-6 .img .social-icons a:nth-of-type(4) {
          -webkit-transition-delay: 0.4s;
          -o-transition-delay: 0.4s;
          transition-delay: 0.4s; }
  .team-card.style-6 .info {
    padding: 25px 0 10px; }
    .team-card.style-6 .info h6 {
      font-size: 15px;
      font-weight: 600;
      color: #000; }
    .team-card.style-6 .info small {
      color: #999;
      font-size: 13px; }
  .team-card.style-6:hover {
    -webkit-box-shadow: 0px 54px 92px 0px rgba(81, 85, 134, 0.2);
    box-shadow: 0px 54px 92px 0px rgba(81, 85, 134, 0.2); }
    .team-card.style-6:hover .img .social-icons a {
      opacity: 1;
      -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
      transform: translateY(0); }
    .team-card.style-6:hover .info h6 {
      color: var(--color-blue6); }

/* --------------- team style-10 --------------- */
.team.style-10 {
  overflow: hidden; }
  .team.style-10 .row {
    margin: 0 -30px !important; }
    .team.style-10 .row .col-lg-4 {
      padding: 0 30px !important; }
  .team.style-10 .team-card .img {
    position: relative;
    height: 400px;
    text-align: center;
    border-bottom-right-radius: 180px;
    overflow: hidden; }
    .team.style-10 .team-card .img .main-img {
      height: 100%;
      width: 90%;
      -o-object-fit: cover;
      object-fit: cover;
      -o-object-position: top;
      object-position: top;
      position: relative;
      z-index: 10; }
    .team.style-10 .team-card .img .bg_color {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: calc(100% - 50px);
      border-top-left-radius: 150px; }
    .team.style-10 .team-card .img .social-icons {
      position: absolute;
      top: 80px;
      right: 20px;
      background-color: #fff;
      z-index: 20;
      width: 50px;
      height: 50px;
      border-radius: 50px;
      cursor: pointer;
      text-align: center;
      -webkit-transition: all 0.5s ease;
      -o-transition: all 0.5s ease;
      transition: all 0.5s ease;
      overflow: hidden; }
      .team.style-10 .team-card .img .social-icons:hover {
        height: 180px; }
        .team.style-10 .team-card .img .social-icons:hover a {
          opacity: 1; }
        .team.style-10 .team-card .img .social-icons:hover .icon {
          height: 45px; }
          .team.style-10 .team-card .img .social-icons:hover .icon::after {
            content: "\f068"; }
      .team.style-10 .team-card .img .social-icons .icon {
        width: 50px;
        height: 50px;
        border-radius: 50px;
        line-height: 50px;
        text-align: center; }
        .team.style-10 .team-card .img .social-icons .icon::after {
          content: "\f067";
          font-weight: 900;
          font-family: "Font Awesome 5 pro"; }
      .team.style-10 .team-card .img .social-icons a {
        background-color: #fff;
        width: 50px;
        height: 30px;
        border-radius: 50px;
        line-height: 30px;
        text-align: center;
        font-size: 12px;
        color: #777;
        opacity: 0; }
        .team.style-10 .team-card .img .social-icons a:hover {
          color: var(--color-blue7); }
        .team.style-10 .team-card .img .social-icons a:nth-of-type(2) {
          -webkit-transition-delay: 0.1s;
          -o-transition-delay: 0.1s;
          transition-delay: 0.1s; }
        .team.style-10 .team-card .img .social-icons a:nth-of-type(3) {
          -webkit-transition-delay: 0.2s;
          -o-transition-delay: 0.2s;
          transition-delay: 0.2s; }
        .team.style-10 .team-card .img .social-icons a:nth-of-type(4) {
          -webkit-transition-delay: 0.3s;
          -o-transition-delay: 0.3s;
          transition-delay: 0.3s; }
  .team.style-10 .team-card .info {
    padding-top: 40px; }
    .team.style-10 .team-card .info h5 {
      font-size: 24px;
      font-weight: bold; }

/* ===============================================================
			[ * blog Section ] 
================================================================*/
/* --------------- blog styles --------------- */
.blog.style-1 .content .blog_slider {
  position: relative; }
  .blog.style-1 .content .blog_slider .swiper-button-next,
  .blog.style-1 .content .blog_slider .swiper-button-prev {
    width: 35px;
    height: 35px; }
  .blog.style-1 .content .blog_slider .swiper-button-next,
  .blog.style-1 .content .blog_slider .swiper-container-rtl .swiper-button-prev {
    right: 120px;
    top: -63px; }
  .blog.style-1 .content .blog_slider .swiper-button-prev,
  .blog.style-1 .content .blog_slider .swiper-container-rtl .swiper-button-next {
    right: 160px;
    left: auto;
    top: -63px; }

.blog.style-1 .content .blog_box {
  position: relative;
  border-radius: 10px;
  overflow: hidden; }
  .blog.style-1 .content .blog_box .img {
    height: 350px;
    position: relative; }
    .blog.style-1 .content .blog_box .img::after {
      position: absolute;
      content: "";
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0.5;
      background-image: -webkit-gradient(linear, left bottom, left top, from(#000), to(transparent));
      background-image: -webkit-linear-gradient(bottom, #000, transparent);
      background-image: -o-linear-gradient(bottom, #000, transparent);
      background-image: linear-gradient(to top, #000, transparent); }
    .blog.style-1 .content .blog_box .img img {
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
      -webkit-transition: all 1s ease;
      -o-transition: all 1s ease;
      transition: all 1s ease; }
  .blog.style-1 .content .blog_box .tags {
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 10; }
    .blog.style-1 .content .blog_box .tags a {
      font-size: 11px;
      padding: 3px 7px;
      border-radius: 4px;
      background: var(--color-main-grad);
      color: #fff;
      text-transform: uppercase; }
  .blog.style-1 .content .blog_box .info {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 20px;
    z-index: 5; }
    .blog.style-1 .content .blog_box .info h6 {
      color: #fff;
      margin-bottom: 10px;
      border-bottom: 2px solid transparent;
      display: inline-block;
      line-height: 1.3;
      -webkit-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      transition: all 0.3s ease; }
      .blog.style-1 .content .blog_box .info h6:hover a {
        color: var(--color-main); }
    .blog.style-1 .content .blog_box .info .auther {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex; }
      .blog.style-1 .content .blog_box .info .auther span {
        padding: 0 10px;
        color: #fff;
        font-size: 12px; }
        .blog.style-1 .content .blog_box .info .auther span:first-of-type {
          padding-left: 0;
          border-right: 1px solid #9993; }
        .blog.style-1 .content .blog_box .info .auther span .auther-img {
          width: 15px;
          height: 15px;
          border-radius: 50%;
          -o-object-fit: cover;
          object-fit: cover;
          margin-right: 3px; }
        .blog.style-1 .content .blog_box .info .auther span i {
          font-size: 11px;
          margin-right: 4px; }
    .blog.style-1 .content .blog_box .info .text {
      margin-top: 20px;
      font-size: 11px;
      color: #fff;
      display: none; }
  .blog.style-1 .content .blog_box:hover .img img {
    -webkit-transform: scale(1.3) rotate(-10deg);
    -ms-transform: scale(1.3) rotate(-10deg);
    transform: scale(1.3) rotate(-10deg); }
  .blog.style-1 .content .blog_box:hover .info h6 {
    border-color: #fff9; }

.blog.style-2 {
  background-image: url(../img/num_back.png);
  background-size: cover;
  position: relative; }
  .blog.style-2 .card-img-top {
    height: 300px;
    width: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    border-radius: 6px; }

.blog.style-3 {
  background-color: #f0eff5;
  position: relative; }
  .blog.style-3 .v_lines {
    position: absolute;
    top: 100px;
    left: 50%;
    height: calc(100% - 340px);
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%); }
  .blog.style-3 .card .img {
    height: 120px; }
  .blog.style-3 .card .card-body .date span {
    font-size: 11px;
    letter-spacing: 1px; }
  .blog.style-3 .card .card-body .card-title {
    font-size: 18px;
    font-weight: bold;
    max-width: 85%;
    margin-top: 10px;
    min-height: 45px; }
  .blog.style-3 .card .card-body .small {
    font-size: 10px; }
  .blog.style-3 .client-logos .img img {
    -webkit-filter: grayscale(1);
    filter: grayscale(1);
    opacity: 0.6; }
  .blog.style-3 .client-logos .img:hover img {
    -webkit-filter: grayscale(0);
    filter: grayscale(0);
    opacity: 1; }

.blog.style-6 .content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between; }
  .blog.style-6 .content .blog-card.style-6 {
    width: 30%;
    position: relative; }
    .blog.style-6 .content .blog-card.style-6.card-center::after {
      position: absolute;
      content: "";
      right: -28px;
      top: 0;
      width: 1px;
      height: 100%;
      background-color: #9994; }
    .blog.style-6 .content .blog-card.style-6.card-center::before {
      position: absolute;
      content: "";
      left: -28px;
      top: 0;
      width: 1px;
      height: 100%;
      background-color: #9994; }

.blog.style-6 a:hover {
  color: var(--color-blue6); }

.blog-card.style-6 {
  position: relative;
  height: 100%; }
  .blog-card.style-6 .img {
    height: 230px;
    border-radius: 20px;
    overflow: hidden; }
  .blog-card.style-6 .info {
    padding: 30px 0; }
    .blog-card.style-6 .info .blog-title {
      color: #000;
      font-size: 22px;
      margin: 10px 0 10px; }
    .blog-card.style-6 .info .text {
      color: #666;
      font-size: 13px; }

.blog.style-7 {
  position: relative;
  background-color: #f0eff5; }
  .blog.style-7 .shap_color {
    position: absolute;
    left: 0;
    bottom: -1px;
    width: 100%; }

.blog-card.style-7 .img {
  height: 240px;
  border-radius: 10px;
  overflow: hidden; }

.blog-card.style-7 .info .date-tags {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }
  .blog-card.style-7 .info .date-tags .tags a {
    font-size: 14px;
    text-transform: capitalize;
    padding: 4px 15px;
    border-radius: 30px;
    color: #fff;
    background-color: var(--color-blue7); }
  .blog-card.style-7 .info .date-tags .author,
  .blog-card.style-7 .info .date-tags .date {
    margin: 0 20px; }

.blog-card.style-7 .info h4.title {
  font-size: 22px;
  margin-top: 20px; }

.blog.style-8 .main-post .img {
  height: 300px;
  border-radius: 10px;
  overflow: hidden;
  position: relative; }
  .blog.style-8 .main-post .img .tags {
    position: absolute;
    z-index: 10;
    top: 30px;
    left: 30px; }
    .blog.style-8 .main-post .img .tags a {
      text-transform: uppercase;
      background-color: #fff;
      color: var(--color-main);
      padding: 5px 20px;
      border-radius: 30px; }
      .blog.style-8 .main-post .img .tags a:hover {
        background-color: var(--color-main);
        color: #fff; }

.blog.style-8 .main-post .info h4.title {
  margin-top: 15px;
  font-size: 22px; }

.blog.style-8 .side-posts .item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 30px; }
  .blog.style-8 .side-posts .item:last-of-type {
    margin-bottom: 0; }
  .blog.style-8 .side-posts .item .img {
    width: 145px;
    height: 120px;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    -webkit-margin-end: 25px;
    margin-inline-end: 25px;
    -ms-flex-negative: 0;
    flex-shrink: 0; }
  .blog.style-8 .side-posts .item .info h4.title {
    margin-top: 15px;
    font-size: 22px; }

.blog.style-10 .blog-card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }
  .blog.style-10 .blog-card .img {
    width: 32%; }
    .blog.style-10 .blog-card .img img {
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover; }
  .blog.style-10 .blog-card .info {
    padding: 35px;
    background-color: #fff;
    width: 68%;
    -ms-flex-negative: 0;
    flex-shrink: 0; }
    .blog.style-10 .blog-card .info h5 {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 20px; }
    .blog.style-10 .blog-card .info p {
      color: #777;
      font-size: 14px;
      margin-bottom: 30px; }

/* ===============================================================
			[ * faq Section ] 
================================================================*/
/* --------------- faq styles --------------- */
.faq.style-3 .accordion-item {
  border: 0;
  border-bottom: 1px solid #9995;
  background: transparent; }
  .faq.style-3 .accordion-item .accordion-button {
    font-weight: bold;
    font-size: 14px;
    background-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
    text-transform: capitalize;
    padding: 25px 15px; }
    .faq.style-3 .accordion-item .accordion-button::after {
      background-size: 15px; }
  .faq.style-3 .accordion-item .accordion-button:not(.collapsed) {
    background-color: #fff;
    color: var(--color-blue2);
    padding-top: 30px; }
  .faq.style-3 .accordion-item .accordion-collapse {
    background-color: #fff; }
    .faq.style-3 .accordion-item .accordion-collapse .accordion-body {
      padding-top: 0;
      padding-bottom: 30px;
      font-size: 10px;
      color: #666; }

.faq.style-3.style-4 .accordion-item {
  border: 0;
  border-radius: 7px;
  overflow: hidden; }
  .faq.style-3.style-4 .accordion-item .accordion-button {
    padding: 15px;
    font-size: 15px; }
  .faq.style-3.style-4 .accordion-item .accordion-button:not(.collapsed) {
    background-color: #f4f2fb;
    color: var(--color-blue4);
    padding-bottom: 10px; }
  .faq.style-3.style-4 .accordion-item .accordion-collapse {
    background-color: #f4f2fb; }
    .faq.style-3.style-4 .accordion-item .accordion-collapse .accordion-body {
      font-size: 13px; }

/* --------------- faq style 10 --------------- */
.faq.style-10 {
  position: relative;
  overflow: hidden; }
  .faq.style-10::before {
    position: absolute;
    content: "";
    width: 350px;
    height: 350px;
    border-radius: 50%;
    background: -webkit-linear-gradient(135deg, #fff, transparent);
    background: -o-linear-gradient(135deg, #fff, transparent);
    background: linear-gradient(-45deg, #fff, transparent);
    left: 5%;
    top: -150px; }
  .faq.style-10::after {
    position: absolute;
    content: "";
    width: 200px;
    height: 200px;
    border-radius: 50%;
    background: var(--color-blue7);
    right: 0;
    top: 20%;
    -webkit-transform: translateX(60%);
    -ms-transform: translateX(60%);
    transform: translateX(60%); }
  .faq.style-10 .accordion-item {
    background: transparent;
    border: 0;
    border-bottom: 1px solid #9995; }
  .faq.style-10 .accordion-collapse {
    border-bottom: 1px solid var(--color-blue7); }
  .faq.style-10 .accordion-button {
    background: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 0;
    font-weight: 600;
    font-size: 16px;
    color: #000; }
    .faq.style-10 .accordion-button::after {
      background-size: 15px; }
  .faq.style-10 .accordion-body {
    padding-top: 0; }
  .faq.style-10 p {
    color: #777; }
  .faq.style-10 .faq-form {
    padding: 40px;
    background-color: #fff;
    border-radius: 10px;
    font-size: 14px; }
    .faq.style-10 .faq-form h4 {
      font-size: 20px;
      margin-bottom: 20px; }
    .faq.style-10 .faq-form .form-group {
      position: relative;
      margin-bottom: 15px; }
      .faq.style-10 .faq-form .form-group .form-control {
        min-height: 45px;
        border: 1px solid #9994;
        padding: 15px 40px;
        font-size: 14px; }
        .faq.style-10 .faq-form .form-group .form-control::-webkit-input-placeholder {
          color: #999; }
        .faq.style-10 .faq-form .form-group .form-control:-ms-input-placeholder {
          color: #999; }
        .faq.style-10 .faq-form .form-group .form-control::-ms-input-placeholder {
          color: #999; }
        .faq.style-10 .faq-form .form-group .form-control::placeholder {
          color: #999; }
      .faq.style-10 .faq-form .form-group .icon {
        position: absolute;
        top: 16px;
        left: 20px;
        color: #000; }
  .faq.style-10 .integration-card {
    position: relative; }
    .faq.style-10 .integration-card::after {
      position: absolute;
      content: "";
      left: 0;
      bottom: 0;
      width: 100%;
      height: 50%;
      background-color: #fff; }
    .faq.style-10 .integration-card .content {
      position: relative;
      background-color: var(--color-blue7);
      color: #fff;
      text-align: center;
      padding: 120px 0;
      border-radius: 20px;
      margin-top: 120px; }
      .faq.style-10 .integration-card .content p {
        color: #fff;
        margin-bottom: 20px;
        position: relative;
        z-index: 10; }
      .faq.style-10 .integration-card .content h3 {
        font-size: 40px;
        margin-bottom: 40px;
        position: relative;
        z-index: 10; }
      .faq.style-10 .integration-card .content .btn {
        position: relative;
        z-index: 10; }
    .faq.style-10 .integration-card .icons .icon {
      position: absolute;
      width: 65px;
      height: 65px;
      border-radius: 50%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      background-color: #5042dc;
      padding: 10px;
      pointer-events: none; }
      .faq.style-10 .integration-card .icons .icon:nth-of-type(1) {
        bottom: 85px;
        right: -35px;
        -webkit-animation-delay: -1s;
        animation-delay: -1s; }
      .faq.style-10 .integration-card .icons .icon:nth-of-type(2) {
        top: 100px;
        right: 50px;
        -webkit-animation-delay: -0.8s;
        animation-delay: -0.8s; }
      .faq.style-10 .integration-card .icons .icon:nth-of-type(3) {
        bottom: 60px;
        right: 18%;
        -webkit-animation-delay: -0.6s;
        animation-delay: -0.6s; }
      .faq.style-10 .integration-card .icons .icon:nth-of-type(4) {
        top: 15px;
        right: 25%;
        -webkit-animation-delay: -0.4s;
        animation-delay: -0.4s; }
      .faq.style-10 .integration-card .icons .icon:nth-of-type(5) {
        bottom: 100px;
        right: 33%;
        -webkit-animation-delay: -0.2s;
        animation-delay: -0.2s; }
      .faq.style-10 .integration-card .icons .icon:nth-of-type(6) {
        top: -35px;
        left: 30%;
        -webkit-animation-delay: 0;
        animation-delay: 0; }
      .faq.style-10 .integration-card .icons .icon:nth-of-type(7) {
        bottom: 45%;
        left: 20%;
        -webkit-animation-delay: 0.2s;
        animation-delay: 0.2s; }
      .faq.style-10 .integration-card .icons .icon:nth-of-type(8) {
        bottom: 20px;
        left: 25%;
        -webkit-animation-delay: 0.4s;
        animation-delay: 0.4s; }
      .faq.style-10 .integration-card .icons .icon:nth-of-type(9) {
        bottom: -35px;
        left: 80px;
        -webkit-animation-delay: 0.6s;
        animation-delay: 0.6s; }
      .faq.style-10 .integration-card .icons .icon:nth-of-type(10) {
        top: 80px;
        left: -35px;
        -webkit-animation-delay: 0.8s;
        animation-delay: 0.8s; }

/* ===============================================================
			[ * community Section ] 
================================================================*/
/* --------------- community style-4 --------------- */
.community.style-4 .content {
  border: 1px solid #9993;
  border-radius: 15px;
  padding: 30px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }
  .community.style-4 .content .commun-card {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 33%;
    border-right: 1px solid #9993;
    padding: 20px 0; }
    .community.style-4 .content .commun-card:last-of-type {
      border: 0; }
    .community.style-4 .content .commun-card .icon {
      -ms-flex-negative: 0;
      flex-shrink: 0;
      margin-right: 25px; }
      .community.style-4 .content .commun-card .icon i {
        font-size: 60px;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-image: -webkit-gradient(linear, left top, right top, from(#501e9c), color-stop(30%, #8169f1), color-stop(30%, #8169f1), color-stop(73%, #a44cee), to(#ff847f));
        background-image: -webkit-linear-gradient(left, #501e9c 0%, #8169f1 30%, #8169f1 30%, #a44cee 73%, #ff847f 100%);
        background-image: -o-linear-gradient(left, #501e9c 0%, #8169f1 30%, #8169f1 30%, #a44cee 73%, #ff847f 100%);
        background-image: linear-gradient(to right, #501e9c 0%, #8169f1 30%, #8169f1 30%, #a44cee 73%, #ff847f 100%); }
    .community.style-4 .content .commun-card .inf h5 {
      font-size: 23px;
      color: #000;
      font-weight: bold;
      margin-bottom: 0;
      line-height: 1.1; }
    .community.style-4 .content .commun-card .inf p {
      font-size: 12px;
      color: #666; }

/* --------------- community style-5 --------------- */
.community.style-5 .content {
  border: 1px solid #9993;
  border-radius: 15px;
  padding: 20px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }
  .community.style-5 .content .commun-card {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 33%;
    border-right: 1px solid #9993;
    padding: 10px 0; }
    .community.style-5 .content .commun-card:last-of-type {
      border: 0; }
    .community.style-5 .content .commun-card .icon {
      -ms-flex-negative: 0;
      flex-shrink: 0;
      margin-right: 25px; }
      .community.style-5 .content .commun-card .icon img {
        -webkit-filter: drop-shadow(-10px 10px 20px #084cba77);
        filter: drop-shadow(-10px 10px 20px #084cba77);
        -webkit-animation: scale_up_down 1s ease-in-out infinite alternate both;
        animation: scale_up_down 1s ease-in-out infinite alternate both; }
    .community.style-5 .content .commun-card .inf h5 {
      font-size: 20px;
      color: #000;
      font-weight: bold;
      margin-bottom: 0;
      line-height: 1.1; }

/* ===============================================================
			[ * faq screenshots ] 
================================================================*/
/* --------------- screenshots style-4 --------------- */
.screenshots.style-4 {
  background-color: #f0eff5;
  overflow: hidden;
  padding-top: 100px;
  position: relative;
  height: 700px; }
  .screenshots.style-4::after {
    position: absolute;
    content: "";
    bottom: -1px;
    left: 0;
    width: 100%;
    height: 30px;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    background-color: #fff;
    z-index: 10; }
  .screenshots.style-4 .screenshots-slider {
    position: absolute;
    top: 110px;
    width: calc(100% + 120px);
    left: -60px; }
    .screenshots.style-4 .screenshots-slider .img {
      margin: 0 auto;
      height: 420px;
      width: 190px; }
      .screenshots.style-4 .screenshots-slider .img img {
        width: 100%;
        height: 100%;
        -o-object-fit: cover;
        object-fit: cover;
        border-radius: 20px; }
  .screenshots.style-4 .mob-hand {
    pointer-events: none;
    position: absolute;
    left: 50%;
    bottom: 0;
    -webkit-transform: translateX(-33%);
    -ms-transform: translateX(-33%);
    transform: translateX(-33%);
    height: 600px;
    z-index: 10; }

/* --------------- screenshots style-11 --------------- */
.screenshots.style-11 .links {
  text-transform: capitalize;
  padding-bottom: 130px;
  overflow: hidden;
  position: relative; }
  .screenshots.style-11 .links .img {
    position: relative; }
    .screenshots.style-11 .links .img img {
      width: 50px;
      -webkit-margin-end: 10px;
      margin-inline-end: 10px;
      position: relative;
      z-index: 10; }
    .screenshots.style-11 .links .img::after {
      position: absolute;
      content: "";
      left: 25px;
      top: 25px;
      height: 1000px;
      width: 1px;
      border: 1px dashed #9994;
      -webkit-transform-origin: top;
      -ms-transform-origin: top;
      transform-origin: top; }
  .screenshots.style-11 .links .row .col-lg-2:nth-of-type(1) .img::after {
    -webkit-transform: rotate(-74deg);
    -ms-transform: rotate(-74deg);
    transform: rotate(-74deg); }
  .screenshots.style-11 .links .row .col-lg-2:nth-of-type(2) .img::after {
    -webkit-transform: rotate(-66deg);
    -ms-transform: rotate(-66deg);
    transform: rotate(-66deg); }
  .screenshots.style-11 .links .row .col-lg-2:nth-of-type(3) .img::after {
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg); }
  .screenshots.style-11 .links .row .col-lg-2:nth-of-type(4) .img::after {
    -webkit-transform: rotate(17deg);
    -ms-transform: rotate(17deg);
    transform: rotate(17deg); }
  .screenshots.style-11 .links .row .col-lg-2:nth-of-type(5) .img::after {
    -webkit-transform: rotate(57deg);
    -ms-transform: rotate(57deg);
    transform: rotate(57deg); }
  .screenshots.style-11 .links .row .col-lg-2:nth-of-type(6) .img::after {
    -webkit-transform: rotate(71deg);
    -ms-transform: rotate(71deg);
    transform: rotate(71deg); }

.screenshots.style-11 .main-img {
  position: relative; }
  .screenshots.style-11 .main-img .link-icon {
    position: absolute;
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    background-color: #fff;
    border-radius: 50%;
    -webkit-box-shadow: 0 0 15px #0001;
    box-shadow: 0 0 15px #0001;
    left: calc(50% - 28px);
    top: -25px;
    z-index: 20; }

/* ===============================================================
			[ * features Section ] 
================================================================*/
/* --------------- features style-4 --------------- */
.features.style-4 {
  background-color: #f0eff5;
  position: relative;
  overflow: hidden; }
  .features.style-4 .img-circle {
    position: absolute;
    top: 40px;
    width: 76%;
    left: 12%;
    max-height: unset;
    -webkit-animation: rotate-center 100s linear infinite both;
    animation: rotate-center 100s linear infinite both; }
  .features.style-4 .content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    position: relative;
    z-index: 5; }
    .features.style-4 .content .features-card {
      text-align: center; }
      .features.style-4 .content .features-card .icon {
        width: 125px;
        height: 125px;
        display: block;
        margin: 0 auto 30px;
        position: relative; }
        .features.style-4 .content .features-card .icon .label {
          position: absolute;
          top: 0;
          right: -5px;
          z-index: 2;
          display: -webkit-inline-box;
          display: -ms-inline-flexbox;
          display: inline-flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          font-size: 10px; }
      .features.style-4 .content .features-card h6 {
        font-weight: bold;
        font-size: 15px;
        line-height: 23px; }

/* --------------- features style-5 --------------- */
.features-card.style-5 {
  display: block;
  padding: 40px;
  background-color: #f1f2fa;
  border-radius: 15px;
  text-align: center; }
  .features-card.style-5:hover {
    -webkit-box-shadow: 0px 46px 77px 0px #0a2b5326;
    box-shadow: 0px 46px 77px 0px #0a2b5326; }
    .features-card.style-5:hover .card-title {
      color: var(--color-blue5); }
  .features-card.style-5 .icon {
    margin-bottom: 40px; }
    .features-card.style-5 .icon img {
      width: 130px;
      height: auto;
      -o-object-fit: contain;
      object-fit: contain;
      -webkit-filter: drop-shadow(0 25px 20px #07397235);
      filter: drop-shadow(0 25px 20px #07397235); }
  .features-card.style-5 .card-title {
    color: #000;
    font-size: 17.5px;
    font-weight: bold;
    margin-bottom: 15px;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease; }
  .features-card.style-5 .text {
    font-size: 12.5px;
    line-height: 1.8;
    color: #777; }

/* --------------- features style-7 --------------- */
.features.style-7 {
  position: relative;
  background-color: #f0eff5;
  overflow: hidden; }
  .features.style-7 .img-circle {
    position: absolute;
    top: -120px;
    left: 25%;
    width: 700px;
    height: 700px;
    max-width: 50%;
    pointer-events: none; }
  .features.style-7 .features-slider7 .swiper-wrapper {
    -webkit-transition-timing-function: linear !important;
    -o-transition-timing-function: linear !important;
    transition-timing-function: linear !important;
    position: relative; }
  .features.style-7 .features-slider7 .swiper-slide {
    padding: 30px 0; }

@media screen and (min-width: 991px) {
  .features-slider7 {
    position: relative; }
    .features-slider7 .swiper-slide {
      width: 470px !important; } }

.features-card.style-7 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 40px;
  border-radius: 10px;
  background-color: #fff;
  margin-bottom: 20px;
  top: 0;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease; }
  .features-card.style-7::before {
    position: absolute;
    content: "";
    left: 20px;
    bottom: 0;
    width: calc(100% - 40px);
    height: 100%;
    border-radius: 10px;
    background-color: #fff8;
    z-index: -1;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease; }
  .features-card.style-7:hover {
    top: -20px; }
    .features-card.style-7:hover::before {
      bottom: -20px; }
  .features-card.style-7 .icon {
    -webkit-margin-end: 30px;
    margin-inline-end: 30px;
    -ms-flex-negative: 0;
    flex-shrink: 0; }
    .features-card.style-7 .icon img {
      width: 125px;
      height: 125px;
      border-radius: 50%;
      -o-object-fit: contain;
      object-fit: contain; }
  .features-card.style-7 .info h5 {
    font-size: 24px;
    font-weight: bold; }

/* --------------- features style-10 --------------- */
.features.style-10 {
  border-bottom: 1px solid #9995; }
  .features.style-10 .col-lg-3:last-of-type .feat-card {
    border: 0; }
  .features.style-10 .feat-card {
    padding: calc(15px + 2vw);
    border-right: 1px solid #9995; }
    .features.style-10 .feat-card .icon {
      margin-bottom: 30px; }
      .features.style-10 .feat-card .icon img {
        width: 75px;
        height: 75px;
        -o-object-fit: contain;
        object-fit: contain; }
    .features.style-10 .feat-card .info a {
      font-size: 16px;
      color: #999;
      margin-bottom: 5px; }
    .features.style-10 .feat-card .info h4 {
      font-size: 24px;
      margin-bottom: 20px; }
    .features.style-10 .feat-card .info p {
      color: #666; }

/* --------------- features style-11 --------------- */
.features.style-11 .info li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 40px; }
  .features.style-11 .info li .icon {
    width: 60px;
    -webkit-margin-end: 20px;
    margin-inline-end: 20px; }
  .features.style-11 .info li p {
    font-size: 18px; }

.features.style-11 .features-cards {
  position: relative; }
  .features.style-11 .features-cards .pattern {
    position: absolute;
    left: 5%;
    top: 5%;
    width: 90%;
    height: 90%;
    -o-object-fit: contain;
    object-fit: contain; }
  .features.style-11 .features-cards .cards-side {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 100%;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
    .features.style-11 .features-cards .cards-side .feat-card {
      padding: 30px;
      border-radius: 10px;
      -webkit-box-shadow: 0px 16px 32px 0px #0000000f;
      box-shadow: 0px 16px 32px 0px #0000000f;
      width: 100%;
      position: relative;
      z-index: 10;
      background-color: #fff; }
      .features.style-11 .features-cards .cards-side .feat-card .icon {
        height: 65px;
        margin-bottom: 30px; }
      .features.style-11 .features-cards .cards-side .feat-card h3 {
        font-size: 22px;
        margin-bottom: 10px; }
        .features.style-11 .features-cards .cards-side .feat-card h3 span {
          color: var(--color-green);
          display: block; }

/* --------------- features style-9 --------------- */
.features.style-9 .accordion .accordion-button {
  background: transparent !important;
  color: #000 !important;
  font-size: 18px;
  -webkit-box-shadow: none;
  box-shadow: none;
  font-weight: bold;
  padding: 25px 16px; }
  .features.style-9 .accordion .accordion-button::after {
    background-size: 12px; }
  .features.style-9 .accordion .accordion-button span {
    color: var(--color-green);
    -webkit-margin-end: 15px;
    margin-inline-end: 15px; }

.features.style-9 .accordion .accordion-body {
  font-size: 16px;
  color: #666;
  padding-left: 60px;
  padding-top: 0; }

/* ===============================================================
			[ * numbers Section ] 
================================================================*/
/* --------------- numbers style-6 --------------- */
.number-card.style-6 {
  border-right: 1px solid #9993;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 10px; }
  .number-card.style-6 h2 {
    font-size: 45px;
    color: var(--color-blue6); }
  .number-card.style-6 .text {
    font-size: 15px;
    color: #000;
    line-height: 1.5;
    text-transform: capitalize; }

/* --------------- numbers style-8 --------------- */
.numbers.style-8 {
  position: relative;
  overflow: hidden;
  z-index: 10; }
  .numbers.style-8 .r_shape {
    position: absolute;
    right: 100px;
    bottom: 270px;
    pointer-events: none; }
  .numbers.style-8::after {
    position: absolute;
    content: "";
    width: 230px;
    height: 230px;
    border-radius: 50%;
    left: -150px;
    top: 120px;
    background-color: var(--color-orange1);
    pointer-events: none; }
  .numbers.style-8 .content {
    position: relative;
    padding-top: 240px;
    padding-bottom: 380px; }
    .numbers.style-8 .content .logo-icon {
      text-align: center; }
    .numbers.style-8 .content .plat-icons .icon {
      position: absolute;
      -webkit-animation-duration: 1.5s;
      animation-duration: 1.5s; }
      .numbers.style-8 .content .plat-icons .icon.icon-shadow {
        background-color: #fff;
        border-radius: 50%;
        width: 120px;
        height: 120px;
        -webkit-box-shadow: 0px 10px 15px #0001;
        box-shadow: 0px 10px 15px #0001;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center; }
      .numbers.style-8 .content .plat-icons .icon:nth-of-type(1) {
        top: 90px;
        right: 0;
        -webkit-animation-delay: -0.6s;
        animation-delay: -0.6s; }
      .numbers.style-8 .content .plat-icons .icon:nth-of-type(2) {
        top: 200px;
        right: 345px;
        -webkit-animation-delay: 0;
        animation-delay: 0; }
      .numbers.style-8 .content .plat-icons .icon:nth-of-type(3) {
        top: 110px;
        left: 335px;
        -webkit-animation-delay: 0.3s;
        animation-delay: 0.3s; }
      .numbers.style-8 .content .plat-icons .icon:nth-of-type(4) {
        top: 140px;
        left: 0;
        -webkit-animation-delay: 0.6s;
        animation-delay: 0.6s; }
      .numbers.style-8 .content .plat-icons .icon:nth-of-type(5) {
        width: 100px;
        height: 100px;
        bottom: 110px;
        right: 265px;
        -webkit-animation-delay: 0.9s;
        animation-delay: 0.9s; }
      .numbers.style-8 .content .plat-icons .icon:nth-of-type(6) {
        width: 180px;
        height: 180px;
        bottom: 100px;
        left: 200px;
        -webkit-animation-delay: 1.2s;
        animation-delay: 1.2s; }
    .numbers.style-8 .content .num8_circle {
      position: absolute;
      top: -235px;
      width: 100%;
      max-height: unset;
      max-width: unset;
      z-index: -1;
      pointer-events: none; }
  .numbers.style-8 .numbers-btm {
    position: relative; }
    .numbers.style-8 .numbers-btm::after {
      position: absolute;
      content: "";
      left: 0;
      bottom: 0;
      width: 100%;
      height: 50%;
      background-color: var(--color-gray2); }
    .numbers.style-8 .numbers-btm .numbers-content {
      padding: 40px 50px;
      background-color: #fff;
      border-radius: 10px;
      -webkit-box-shadow: 0 0 15px #0001;
      box-shadow: 0 0 15px #0001;
      position: relative;
      z-index: 10; }
      .numbers.style-8 .numbers-btm .numbers-content .number-card {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex; }
        .numbers.style-8 .numbers-btm .numbers-content .number-card .icon {
          -webkit-margin-end: 25px;
          margin-inline-end: 25px;
          -ms-flex-negative: 0;
          flex-shrink: 0;
          padding-top: 15px; }
          .numbers.style-8 .numbers-btm .numbers-content .number-card .icon img {
            width: 40px; }
        .numbers.style-8 .numbers-btm .numbers-content .number-card .inf h3 {
          font-size: 40px;
          font-weight: bold; }
        .numbers.style-8 .numbers-btm .numbers-content .number-card .inf p {
          color: #666; }

/* ===============================================================
			[ * clients Section ] 
================================================================*/
/* --------------- clients style-4 --------------- */
.clients.style-4 {
  background-color: #f0eff5;
  position: relative;
  z-index: 25; }
  .clients.style-4 .client-logos {
    border-bottom: 1px solid #9994; }
    .clients.style-4 .client-logos .img img {
      -webkit-filter: grayscale(1);
      filter: grayscale(1);
      opacity: 0.6; }
    .clients.style-4 .client-logos .img:hover img {
      -webkit-filter: grayscale(0);
      filter: grayscale(0);
      opacity: 1; }

/* --------------- clients style 5 --------------- */
.clients.style-5 .content .img {
  padding: 30px;
  text-align: center;
  border-radius: 20px;
  border: 1px solid #9994;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 5px;
  height: 150px; }
  .clients.style-5 .content .img.img-card {
    width: 19%; }

.clients.style-5 .swiper-wrapper {
  -webkit-transition-timing-function: linear !important;
  -o-transition-timing-function: linear !important;
  transition-timing-function: linear !important;
  position: relative; }

/* --------------- clients style 9 --------------- */
.clients.style-9 .client-card {
  padding: 50px;
  border: 1px solid #9993;
  border-radius: 5px;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease; }
  .clients.style-9 .client-card:hover {
    border: 1px solid transparent;
    background-color: #fff;
    -webkit-box-shadow: 0px 16px 32px 0px #0000000f;
    box-shadow: 0px 16px 32px 0px #0000000f; }
    .clients.style-9 .client-card:hover .info a {
      margin-left: 0; }
      .clients.style-9 .client-card:hover .info a .l-arrow {
        opacity: 1; }
      .clients.style-9 .client-card:hover .info a .r-arrow {
        opacity: 0; }
  .clients.style-9 .client-card .info h5 {
    font-weight: bold;
    font-size: 20px;
    color: var(--color-darkBlue2);
    margin-bottom: 10px; }
    .clients.style-9 .client-card .info h5 span {
      color: #6c41ff;
      text-decoration: underline !important; }
  .clients.style-9 .client-card .info p {
    color: #666; }
  .clients.style-9 .client-card .info a {
    font-weight: bold;
    color: var(--color-darkBlue2);
    margin-left: -20px; }
    .clients.style-9 .client-card .info a .l-arrow {
      opacity: 0;
      -webkit-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      transition: all 0.3s ease; }
    .clients.style-9 .client-card .info a .r-arrow {
      -webkit-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      transition: all 0.3s ease; }

.clients.style-9 .logos-content {
  margin-top: 100px; }
  .clients.style-9 .logos-content h4 {
    font-size: 24px;
    font-weight: bold;
    color: var(--color-darkBlue2);
    padding-bottom: 20px;
    border-bottom: 1px solid #9993;
    text-align: center; }
    .clients.style-9 .logos-content h4 span {
      color: #6c41ff; }
  .clients.style-9 .logos-content .logos {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-top: 20px; }
    .clients.style-9 .logos-content .logos a {
      margin-top: 30px; }

/* ===============================================================
			[ * chat-banner Section ] 
================================================================*/
/* --------------- chat-banner styles --------------- */
.chat-banner.style-3 {
  background-color: var(--color-blue2);
  position: relative; }
  .chat-banner.style-3::after {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    width: 100%;
    height: 60%;
    background-image: url(../img/0011.png);
    background-size: 80%;
    background-repeat: repeat;
    background-position: bottom;
    opacity: 0.08;
    -webkit-filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(1003%) contrast(103%);
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(1003%) contrast(103%); }
  .chat-banner.style-3 .info h3 {
    font-size: 27px;
    color: #fff;
    text-transform: capitalize; }
    .chat-banner.style-3 .info h3 span {
      font-weight: 400;
      font-style: italic;
      position: relative; }
      .chat-banner.style-3 .info h3 span::before {
        position: absolute;
        content: "";
        left: 0;
        top: 100%;
        width: 240px;
        height: 35px;
        background-image: url(../img/header/info_h1_line1.png);
        background-size: 240px;
        background-repeat: no-repeat;
        background-position: left; }

/* --------------- chat-banner styles --------------- */
.chat-banner.style-7 {
  background-color: #8169f1;
  padding-top: 100px; }
  .chat-banner.style-7 .info {
    text-align: center;
    color: #fff;
    padding-bottom: 100px; }
    .chat-banner.style-7 .info p {
      font-size: 16px;
      font-weight: 300;
      margin-bottom: 15px; }
    .chat-banner.style-7 .info h3 {
      font-size: 40px;
      font-weight: 500; }

/* --------------- chat-banner styles --------------- */
.chat-banner.style-9 {
  position: relative;
  padding: 180px 0;
  background-image: url(../img/header/head9_back.png);
  background-size: cover;
  background-position: center; }
  .chat-banner.style-9 .container {
    position: relative;
    z-index: 10; }
  .chat-banner.style-9 .img_back {
    position: absolute;
    left: 0;
    top: -50px;
    bottom: -50px;
    width: 100%;
    height: calc(100% + 100px);
    max-height: unset;
    pointer-events: none;
    -o-object-fit: contain;
    object-fit: contain; }
  .chat-banner.style-9 .section-head h6::after, .chat-banner.style-9 .section-head h6::before {
    background-color: #fff; }

/* ===============================================================
			[ * contact Section ] 
================================================================*/
/* --------------- contact style-1 --------------- */
.contact.style-1 {
  position: relative;
  overflow: hidden; }
  .contact.style-1 .container {
    position: relative;
    z-index: 5; }
  .contact.style-1 .content .contact_info p {
    color: #fff;
    font-size: 13px; }
  .contact.style-1 .content .contact_info ul {
    margin: 50px 0; }
    .contact.style-1 .content .contact_info ul li {
      color: #fff;
      margin: 15px 0; }
      .contact.style-1 .content .contact_info ul li strong {
        margin-right: 5px; }
  .contact.style-1 .content .contact_info a {
    color: #fff;
    text-decoration: underline !important;
    text-transform: uppercase;
    font-size: 13px; }
  .contact.style-1 .content .contact_form .form-control,
  .contact.style-1 .content .contact_form .form-select {
    border: 0;
    font-size: 13px;
    min-height: 50px; }
  .contact.style-1 .content .contact_form textarea {
    min-height: 125px !important; }
  .contact.style-1 .contact_globe {
    position: absolute;
    left: 0;
    top: -10%;
    width: 47%;
    height: 120%;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: right;
    object-position: right;
    max-height: none;
    -webkit-filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);
    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);
    opacity: 0.3;
    -webkit-animation: rotate-center 100s linear infinite both;
    animation: rotate-center 100s linear infinite both; }

@-webkit-keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

/* --------------- contact style-2 --------------- */
.contact.style-2 {
  position: relative;
  overflow: hidden;
  padding-bottom: 250px; }
  .contact.style-2 .global_2 {
    position: absolute;
    left: 25%;
    bottom: -500px;
    width: 55%;
    z-index: 2;
    opacity: 0.7;
    -webkit-animation: rotate-center 100s linear infinite both;
    animation: rotate-center 100s linear infinite both; }

/* --------------- contact style-6 --------------- */
.contact.style-6 .content {
  position: relative;
  border-top: 10px solid var(--color-blue6);
  border-top-right-radius: 40px;
  border-top-left-radius: 40px;
  padding: 100px 15px; }
  .contact.style-6 .content::before {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: -webkit-linear-gradient(45deg, transparent, #def1fa, transparent, #e8e8f3, transparent);
    background: -o-linear-gradient(45deg, transparent, #def1fa, transparent, #e8e8f3, transparent);
    background: linear-gradient(45deg, transparent, #def1fa, transparent, #e8e8f3, transparent);
    background-size: 400% 400%;
    -webkit-animation: gradient 20s linear infinite;
    animation: gradient 20s linear infinite;
    border-radius: 30px;
    overflow: hidden; }
  .contact.style-6 .content .contact_a {
    position: absolute;
    left: 0;
    bottom: 0;
    border-bottom-left-radius: 30px;
    pointer-events: none;
    max-width: 20%; }
  .contact.style-6 .content .contact_message {
    position: absolute;
    right: -150px;
    top: 100px;
    border-bottom-left-radius: 30px;
    pointer-events: none;
    max-width: 30%; }
  .contact.style-6 .content .form {
    position: relative;
    z-index: 5; }

.contact.style-6 .form-group .form-control,
.contact.style-6 .form-group .form-select {
  border: 1px solid #9994;
  border-radius: 30px;
  min-height: 50px;
  font-size: 12px;
  padding: 10px 25px; }
  .contact.style-6 .form-group .form-control:focus,
  .contact.style-6 .form-group .form-select:focus {
    border-color: var(--color-blue6);
    -webkit-box-shadow: none;
    box-shadow: none; }

@-webkit-keyframes gradient {
  0% {
    background-position: 0% 50%; }
  50% {
    background-position: 100% 50%; }
  100% {
    background-position: 0% 50%; } }

@keyframes gradient {
  0% {
    background-position: 0% 50%; }
  50% {
    background-position: 100% 50%; }
  100% {
    background-position: 0% 50%; } }

/* ===============================================================
			[ * download Section ] 
================================================================*/
/* --------------- download style-5 --------------- */
.download.style-5 .content h2 {
  font-size: 50px; }
  .download.style-5 .content h2 span {
    position: relative; }
    .download.style-5 .content h2 span .head-line {
      position: absolute;
      left: 0;
      bottom: -5px;
      width: 100%; }
    .download.style-5 .content h2 span .head-pen {
      position: absolute;
      right: 102%;
      bottom: -5px;
      -webkit-transform: rotateY(180deg);
      transform: rotateY(180deg); }

/* ===============================================================
			[ * searcing Section ] 
================================================================*/
/* --------------- search styles --------------- */
.domain-search.style-9 .domain-choose {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }
  .domain-search.style-9 .domain-choose .butn {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -webkit-margin-start: 10px;
    margin-inline-start: 10px;
    padding: 20px 40px; }
  .domain-search.style-9 .domain-choose .form-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #fff;
    border-radius: 5px;
    padding: 10px 20px;
    width: 100%; }
    .domain-search.style-9 .domain-choose .form-group .icon {
      margin-right: 10px;
      -ms-flex-negative: 0;
      flex-shrink: 0; }
    .domain-search.style-9 .domain-choose .form-group .form-select {
      width: -webkit-max-content;
      width: -moz-max-content;
      width: max-content;
      border: 0;
      -ms-flex-negative: 0;
      flex-shrink: 0;
      font-weight: bold;
      color: var(--color-darkBlue2); }
    .domain-search.style-9 .domain-choose .form-group input {
      width: 100%;
      border: 0; }

.domain-search.style-9 .domain-names {
  margin-top: 40px; }
  .domain-search.style-9 .domain-names .item {
    margin-top: 10px; }
    .domain-search.style-9 .domain-names .item.dom-tech span {
      color: #6c41ff; }
    .domain-search.style-9 .domain-names .item.dom-net span {
      color: #ff7342; }
    .domain-search.style-9 .domain-names .item.dom-co span {
      color: #cc41ff; }
    .domain-search.style-9 .domain-names .item.dom-com span {
      color: #ff28ac; }
    .domain-search.style-9 .domain-names .item.dom-info span {
      color: #89a829; }
    .domain-search.style-9 .domain-names .item.dom-org span {
      color: #a342ed; }
  .domain-search.style-9 .domain-names p {
    font-weight: bold;
    font-size: 16px; }
  .domain-search.style-9 .domain-names h5 {
    text-align: center;
    border-top: 1px solid #9993;
    padding-top: 20px;
    margin-top: 20px;
    font-size: 18px;
    color: var(--color-darkBlue2);
    font-weight: bold; }

/* ===============================================================
			[ * solutions Section ] 
================================================================*/
/* --------------- solutions styles --------------- */
.solutions.style-10 .solution-card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 15px 20px;
  border-radius: 5px;
  background-color: #fff;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-top: 30px; }
  .solutions.style-10 .solution-card h6 {
    font-size: 14px;
    font-weight: bold; }
  .solutions.style-10 .solution-card .icon img {
    -webkit-filter: invert(54%) sepia(58%) saturate(5618%) hue-rotate(228deg) brightness(99%) contrast(92%);
    filter: invert(54%) sepia(58%) saturate(5618%) hue-rotate(228deg) brightness(99%) contrast(92%); }
  .solutions.style-10 .solution-card:hover {
    background-color: #8169f1;
    color: #fff;
    -webkit-box-shadow: 10px 10px 30px #0004;
    box-shadow: 10px 10px 30px #0004; }
    .solutions.style-10 .solution-card:hover .icon img {
      -webkit-filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(135deg) brightness(107%) contrast(106%);
      filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(135deg) brightness(107%) contrast(106%); }

/* ===============================================================
			[ * sgin_up Section ] 
================================================================*/
/* --------------- sgin up styles --------------- */
.signup.style-11 {
  position: relative; }
  .signup.style-11 .pattern {
    position: absolute;
    left: 10%;
    top: 0;
    width: 80%;
    height: 100%;
    -o-object-fit: contain;
    object-fit: contain; }
  .signup.style-11 .content {
    position: relative;
    z-index: 10; }
    .signup.style-11 .content .form-group {
      position: relative;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      background-color: #fff;
      border-radius: 50px;
      -webkit-box-shadow: 0 0 20px #0001;
      box-shadow: 0 0 20px #0001;
      padding-left: 20px;
      overflow: hidden;
      margin-top: 80px; }
      .signup.style-11 .content .form-group .form-control {
        height: 50px;
        border: 0;
        padding-left: 15px; }
      .signup.style-11 .content .form-group button {
        border: 0;
        background-color: var(--color-green);
        color: #fff;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        padding: 15px 30px;
        text-transform: uppercase; }

/* ===============================================================
			[ * integration Section ] 
================================================================*/
/* --------------- integrations styles --------------- */
.integration.style-11 {
  background-color: #1b1b20; }
  .integration.style-11 .info {
    font-size: 16px;
    color: #9e9eac; }
  .integration.style-11 .icons {
    position: relative;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center; }
    .integration.style-11 .icons .pattern_back {
      max-width: unset;
      max-height: unset;
      width: 130%;
      left: -15%;
      position: absolute; }
    .integration.style-11 .icons .logo {
      width: 100px;
      margin: auto;
      position: relative;
      z-index: 10; }
    .integration.style-11 .icons .icon {
      position: absolute;
      z-index: 10; }
      .integration.style-11 .icons .icon:nth-of-type(1) {
        top: 10%;
        right: 12%; }
      .integration.style-11 .icons .icon:nth-of-type(2) {
        top: 50%;
        right: 15%; }
      .integration.style-11 .icons .icon:nth-of-type(3) {
        bottom: 3%;
        right: 20%; }
      .integration.style-11 .icons .icon:nth-of-type(4) {
        top: 20%;
        left: 30%; }
      .integration.style-11 .icons .icon:nth-of-type(5) {
        top: 20%;
        left: 1%; }
      .integration.style-11 .icons .icon:nth-of-type(6) {
        bottom: 20%;
        left: 1%; }

/* ===============================================================
			[ * footer Section ] 
================================================================*/
/* --------------- footer styles --------------- */
footer.style-1 {
  background-color: #000;
  position: relative; }
  footer.style-1 .foot_l {
    position: absolute;
    left: 0;
    bottom: 0;
    pointer-events: none; }
  footer.style-1 .foot_r {
    position: absolute;
    right: 0;
    top: 0;
    pointer-events: none; }
  footer.style-1 .content {
    padding: 90px 0 70px;
    position: relative;
    z-index: 5; }
  footer.style-1 p,
  footer.style-1 a,
  footer.style-1 li {
    font-size: 13px;
    color: #aaa7a7; }
  footer.style-1 a:hover {
    color: var(--color-main); }
  footer.style-1 .text {
    color: #fff;
    font-size: 14px; }
  footer.style-1 .foot_info li {
    margin: 20px 0; }
    footer.style-1 .foot_info li i {
      color: var(--color-main);
      font-size: 16px; }
  footer.style-1 .social_icons {
    margin-top: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; }
    footer.style-1 .social_icons a {
      width: 33px;
      height: 33px;
      border-radius: 50%;
      display: -webkit-inline-box;
      display: -ms-inline-flexbox;
      display: inline-flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      background-color: #2c2c2c;
      color: #aaa7a7;
      margin: 5px; }
      footer.style-1 .social_icons a:hover {
        background: var(--color-main-grad);
        color: #fff; }
  footer.style-1 .links {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center; }
    footer.style-1 .links li {
      margin: 8px 0; }
  footer.style-1 .link_title {
    color: #fff;
    margin: 10px 0 15px; }
  footer.style-1 .foot_subscribe input {
    background: #1f1f1f;
    color: #fff;
    font-size: 12px;
    border: 0; }
    footer.style-1 .foot_subscribe input::-webkit-input-placeholder {
      color: #fff; }
    footer.style-1 .foot_subscribe input:-ms-input-placeholder {
      color: #fff; }
    footer.style-1 .foot_subscribe input::-ms-input-placeholder {
      color: #fff; }
    footer.style-1 .foot_subscribe input::placeholder {
      color: #fff; }
  footer.style-1 .foot {
    position: relative;
    z-index: 5;
    text-align: center;
    padding: 30px 0;
    border-top: 1px solid #fff3; }

footer.style-2 {
  background-color: #010040; }

footer.style-3 {
  position: relative;
  padding-top: 100px;
  overflow: hidden; }
  footer.style-3 .items .title {
    font-weight: bold;
    margin-bottom: 30px; }
  footer.style-3 .items .socail-icons {
    margin-top: 30px; }
    footer.style-3 .items .socail-icons a:hover {
      background-color: var(--color-blue2) !important;
      color: #fff !important; }
  footer.style-3 .items ul li {
    margin: 5px 0; }
    footer.style-3 .items ul li a {
      font-size: 12px; }
      footer.style-3 .items ul li a:hover {
        color: var(--color-blue2); }
  footer.style-3 .foot {
    padding: 35px 0 30px;
    position: relative;
    margin-top: 80px; }
    footer.style-3 .foot .logo {
      width: 120px; }
    footer.style-3 .foot .testi_lines {
      position: absolute;
      top: -10px; }
  footer.style-3 .contact_globe {
    position: absolute;
    height: 150%;
    max-height: none;
    width: 60%;
    top: -25%;
    left: 20%;
    opacity: 0.15;
    -webkit-animation: rotate-center 100s linear infinite both;
    animation: rotate-center 100s linear infinite both; }

footer.style-4 {
  position: relative;
  background-color: #f0eff5;
  margin-top: 200px; }
  footer.style-4 .wave {
    position: absolute;
    left: -3%;
    bottom: 95%;
    width: 106%;
    max-width: unset;
    height: 240px;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: top;
    object-position: top;
    z-index: 20; }
  footer.style-4 .container {
    position: relative;
    z-index: 25; }
  footer.style-4 .foot {
    border-bottom: 1px solid #9999;
    padding: 30px 0; }
    footer.style-4 .foot .links {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center; }
      footer.style-4 .foot .links li a {
        font-size: 12px;
        margin: 0 15px;
        font-weight: bold; }
        footer.style-4 .foot .links li a:hover, footer.style-4 .foot .links li a.active {
          color: var(--color-blue4); }
  footer.style-4 .copywrite {
    padding: 35px 0; }

footer.style-5 {
  position: relative;
  padding-top: 100px;
  overflow: hidden; }
  footer.style-5 .items .title {
    font-weight: bold;
    margin-bottom: 30px;
    color: #000;
    line-height: 1; }
  footer.style-5 .items .socail-icons {
    margin-top: 30px; }
    footer.style-5 .items .socail-icons a:hover {
      background-color: var(--color-blue5) !important;
      color: #fff !important; }
  footer.style-5 .items ul li {
    margin: 5px 0; }
    footer.style-5 .items ul li a {
      font-size: 12px; }
      footer.style-5 .items ul li a:hover {
        color: var(--color-blue5); }
  footer.style-5 .foot {
    padding: 35px 0 30px;
    position: relative;
    margin-top: 80px; }
    footer.style-5 .foot .logo {
      width: 120px; }

footer.style-6 {
  position: relative;
  padding-top: 100px;
  overflow: hidden; }
  footer.style-6 .items .title {
    font-weight: bold;
    margin-bottom: 30px;
    color: #000; }
  footer.style-6 .items .socail-icons {
    margin-top: 30px; }
    footer.style-6 .items .socail-icons a:hover {
      background-color: var(--color-blue6) !important;
      color: #fff !important; }
  footer.style-6 .items ul li {
    margin: 5px 0; }
    footer.style-6 .items ul li a {
      font-size: 12px; }
      footer.style-6 .items ul li a:hover {
        color: var(--color-blue6); }
  footer.style-6 .form .form-group {
    position: relative; }
    footer.style-6 .form .form-group .icon {
      position: absolute;
      top: 12px;
      left: 15px; }
    footer.style-6 .form .form-group input {
      width: 100%;
      border: 1px solid #9993;
      border-radius: 30px;
      min-height: 45px;
      font-size: 12px;
      padding: 0 40px; }
    footer.style-6 .form .form-group button {
      position: absolute;
      right: 5px;
      top: 5px;
      width: 35px;
      height: 35px;
      border-radius: 50%;
      background-color: var(--color-blue6);
      border: 0;
      color: #fff; }
  footer.style-6 .foot {
    padding: 35px 0 30px;
    position: relative;
    margin-top: 80px; }
    footer.style-6 .foot .logo {
      width: 120px; }

footer.style-7 {
  position: relative;
  background: url(../img/foot_7_pattern.png) #f0eff5;
  padding-bottom: 40px;
  background-size: cover;
  background-position: bottom; }
  footer.style-7 .info-logo {
    -webkit-padding-end: 50px;
    padding-inline-end: 50px; }
    footer.style-7 .info-logo .logo {
      width: 200px;
      margin-bottom: 40px; }
    footer.style-7 .info-logo .text {
      font-size: 16px;
      margin-bottom: 30px; }
    footer.style-7 .info-logo .social-links a {
      width: 50px;
      height: 50px;
      line-height: 50px;
      background-color: #fff;
      border-radius: 50%;
      text-align: center;
      color: #000;
      font-size: 16px;
      -webkit-margin-end: 10px;
      margin-inline-end: 10px; }
      footer.style-7 .info-logo .social-links a:hover {
        background-color: var(--color-blue7);
        color: #fff; }
  footer.style-7 .links-side {
    -webkit-padding-start: 50px;
    padding-inline-start: 50px; }
    footer.style-7 .links-side .links-group h5 {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 30px; }
    footer.style-7 .links-side .links-group .links a {
      margin: 7px 0; }

footer.style-8 {
  position: relative; }
  footer.style-8::after {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    width: 100%;
    height: 70%;
    background: -webkit-gradient(linear, left top, left bottom, from(#fff), to(transparent));
    background: -webkit-linear-gradient(top, #fff, transparent);
    background: -o-linear-gradient(top, #fff, transparent);
    background: linear-gradient(to bottom, #fff, transparent);
    pointer-events: none; }
  footer.style-8 .container {
    position: relative;
    z-index: 10; }
  footer.style-8 .content .logo-social {
    padding-bottom: 30px;
    border-bottom: 1px solid #9993; }
    footer.style-8 .content .logo-social .foot-logo img {
      width: 150px; }
    footer.style-8 .content .logo-social .socials a {
      width: 50px;
      height: 50px;
      line-height: 50px;
      text-align: center;
      -webkit-box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.06);
      box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.06);
      border-radius: 50%;
      background-color: #fff;
      color: #000;
      font-size: 16px;
      -webkit-margin-start: 8px;
      margin-inline-start: 8px; }
      footer.style-8 .content .logo-social .socials a:hover {
        background-color: var(--color-main);
        color: #fff;
        -webkit-box-shadow: none;
        box-shadow: none; }
  footer.style-8 .content .links-content .foot-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 30px;
    margin-top: 60px;
    text-transform: capitalize; }
  footer.style-8 .content .links-content p {
    font-size: 16px;
    color: #666; }
  footer.style-8 .content .links-content .foot-info li {
    font-size: 16px;
    margin: 10px 0; }
  footer.style-8 .content .links-content .links li {
    margin: 15px 0; }
  footer.style-8 .content .links-content .links a {
    font-size: 14px; }
  footer.style-8 .content .links-content .foot-subscribe .form-group {
    position: relative; }
    footer.style-8 .content .links-content .foot-subscribe .form-group .icon {
      position: absolute;
      top: 17px;
      left: 15px; }
    footer.style-8 .content .links-content .foot-subscribe .form-group .form-control {
      padding: 15px 15px 15px 40px;
      border-radius: 50px;
      background-color: #fff;
      border: 0; }
      footer.style-8 .content .links-content .foot-subscribe .form-group .form-control::-webkit-input-placeholder {
        color: #9999; }
      footer.style-8 .content .links-content .foot-subscribe .form-group .form-control:-ms-input-placeholder {
        color: #9999; }
      footer.style-8 .content .links-content .foot-subscribe .form-group .form-control::-ms-input-placeholder {
        color: #9999; }
      footer.style-8 .content .links-content .foot-subscribe .form-group .form-control::placeholder {
        color: #9999; }

footer.style-9 {
  background-color: #111352; }
  footer.style-9 .foot-title {
    font-size: 18px;
    font-weight: 600;
    color: #fff;
    position: relative;
    padding-left: 30px;
    margin-bottom: 35px;
    margin-top: 90px;
    text-transform: capitalize; }
    footer.style-9 .foot-title::before {
      position: absolute;
      content: "";
      left: 0;
      top: 8px;
      width: 8px;
      height: 8px;
      background-color: #6c41ff;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg); }
  footer.style-9 .links ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
  footer.style-9 .links li {
    margin: 7px 0;
    width: 100%; }
  footer.style-9 .links a {
    color: #b6b7d3;
    text-transform: capitalize; }
    footer.style-9 .links a:hover {
      color: #fff; }
    footer.style-9 .links a small {
      font-size: 10px; }
  footer.style-9 .foot {
    margin-top: 80px;
    padding: 40px 0;
    border-top: 1px solid #fff2; }
  footer.style-9 p {
    color: #b6b7d3; }
  footer.style-9 .social-icons a {
    width: 40px;
    height: 40px;
    line-height: 40px;
    background-color: #fff1;
    border-radius: 5px;
    text-align: center;
    color: #fff;
    -webkit-margin-start: 2px;
    margin-inline-start: 2px; }
    footer.style-9 .social-icons a:hover {
      background-color: var(--color-darkBlue2); }

footer.style-10 {
  position: relative;
  background-color: #000;
  color: #fff; }
  footer.style-10 .info-card {
    padding-top: 30px;
    border-top: 1px solid #fff2; }
    footer.style-10 .info-card h5 {
      font-size: 20px;
      font-weight: 500; }
  footer.style-10 .pattern {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover; }
  footer.style-10 h5 {
    font-size: 20px; }
  footer.style-10 .subscribe-card .form-group {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    border-bottom: 1px solid #fff2;
    padding-bottom: 10px; }
    footer.style-10 .subscribe-card .form-group .icon {
      -ms-flex-negative: 0;
      flex-shrink: 0;
      -webkit-margin-end: 5px;
      margin-inline-end: 5px; }
    footer.style-10 .subscribe-card .form-group input {
      width: 100%;
      border: 0;
      background: transparent; }
      footer.style-10 .subscribe-card .form-group input::-webkit-input-placeholder {
        color: #9999; }
      footer.style-10 .subscribe-card .form-group input:-ms-input-placeholder {
        color: #9999; }
      footer.style-10 .subscribe-card .form-group input::-ms-input-placeholder {
        color: #9999; }
      footer.style-10 .subscribe-card .form-group input::placeholder {
        color: #9999; }
    footer.style-10 .subscribe-card .form-group button {
      border: 0;
      background: transparent;
      color: var(--color-blue7); }
  footer.style-10 .subscribe-card p {
    color: #484848;
    margin-top: 5px; }
  footer.style-10 .foot {
    padding: 40px 0;
    position: relative; }
    footer.style-10 .foot::before {
      position: absolute;
      content: "";
      left: 0;
      top: 0;
      width: 100%;
      height: 1px;
      background: -webkit-gradient(linear, left top, right top, from(transparent), color-stop(#fff2), color-stop(#fff2), color-stop(#fff2), to(transparent));
      background: -webkit-linear-gradient(left, transparent, #fff2, #fff2, #fff2, transparent);
      background: -o-linear-gradient(left, transparent, #fff2, #fff2, #fff2, transparent);
      background: linear-gradient(to right, transparent, #fff2, #fff2, #fff2, transparent); }
    footer.style-10 .foot .navbar-brand {
      width: 170px; }
  footer.style-10 .social-icons a {
    width: 40px;
    height: 40px;
    line-height: 40px;
    background-color: transparent;
    border-radius: 5px;
    text-align: center;
    color: #fff;
    -webkit-margin-start: 5px;
    margin-inline-start: 5px;
    border: 1px solid #fff3; }
    footer.style-10 .social-icons a:hover {
      background-color: var(--color-orange2); }

footer.style-11 {
  background-color: #1b1b20;
  color: #fff; }
  footer.style-11 .foot-links {
    position: relative; }
    footer.style-11 .foot-links::before {
      position: absolute;
      content: "";
      left: 0;
      top: 0;
      width: 100%;
      height: 1px;
      background: -webkit-gradient(linear, left top, right top, from(transparent), color-stop(#fff2), color-stop(#fff2), color-stop(#fff2), to(transparent));
      background: -webkit-linear-gradient(left, transparent, #fff2, #fff2, #fff2, transparent);
      background: -o-linear-gradient(left, transparent, #fff2, #fff2, #fff2, transparent);
      background: linear-gradient(to right, transparent, #fff2, #fff2, #fff2, transparent); }
    footer.style-11 .foot-links .links-item {
      margin-top: 40px; }
      footer.style-11 .foot-links .links-item h6 {
        font-size: 18px;
        font-weight: 500;
        color: #fff;
        margin-bottom: 20px; }
  footer.style-11 .btn * {
    line-height: 1; }
  footer.style-11 a {
    font-size: 16px;
    color: #87878e;
    margin: 6px 0; }
    footer.style-11 a:hover {
      color: var(--color-green) !important; }
  footer.style-11 p {
    font-size: 16px;
    color: #87878e;
    margin: 6px 0; }
  footer.style-11 .foot {
    background-color: #131317;
    padding: 20px 0; }
    footer.style-11 .foot .social-icons a {
      width: 40px;
      height: 40px;
      line-height: 40px;
      background-color: transparent;
      border-radius: 5px;
      text-align: center;
      color: #fff;
      -webkit-margin-end: 5px;
      margin-inline-end: 5px;
      border: 1px solid #fff1; }
      footer.style-11 .foot .social-icons a:hover {
        background-color: var(--color-green); }

/* ===============================================================
			[ * inner_header Style ] 
================================================================*/
/* --------------- inner-header style-5 --------------- */
.inner-header.style-5 {
  background-image: url(../img/inner5_back.png);
  background-size: cover;
  background-repeat: no-repeat;
  color: #000; }
  .inner-header.style-5 .content {
    padding: 120px 0;
    position: relative; }
    .inner-header.style-5 .content .links a {
      font-size: 16px;
      color: #999;
      text-transform: capitalize;
      position: relative;
      padding-right: 20px;
      border-right: 1px solid #9993;
      margin-right: 20px; }
      .inner-header.style-5 .content .links a:last-of-type {
        font-weight: bold;
        color: var(--color-main);
        border: 0;
        padding: 0;
        margin: 0; }
    .inner-header.style-5 .content h2 {
      font-size: 60px;
      text-transform: capitalize;
      margin-top: 20px; }
    .inner-header.style-5 .content .side-img {
      position: absolute;
      right: 0;
      top: 30%;
      height: 40%;
      pointer-events: none; }

/* ===============================================================
			[ * portfolio_page Style ] 
================================================================*/
/* --------------- portfolio-page style-1 --------------- */
.portfolio-page.style-1 .portfolio-projects.style-1 {
  background-color: #e7f1ff; }
  .portfolio-page.style-1 .portfolio-projects.style-1 .controls {
    text-align: center;
    margin-bottom: 50px; }
    .portfolio-page.style-1 .portfolio-projects.style-1 .controls button {
      border: 0;
      background: transparent;
      font-weight: bold;
      text-transform: capitalize;
      margin: 10px; }
      .portfolio-page.style-1 .portfolio-projects.style-1 .controls button.mixitup-control-active {
        color: var(--color-blue5); }

.portfolio-page.style-1 .download.style-5 {
  position: relative;
  overflow: hidden; }
  .portfolio-page.style-1 .download.style-5 .content h2 span .head-pen {
    right: auto;
    left: 102%;
    -webkit-transform: rotateY(0);
    transform: rotateY(0); }
  .portfolio-page.style-1 .download.style-5 .contact_globe {
    position: absolute;
    height: 150%;
    max-height: none;
    width: 60%;
    top: -25%;
    left: 20%;
    opacity: 0.15;
    -webkit-animation: rotate-center 100s linear infinite both;
    animation: rotate-center 100s linear infinite both; }

/* ===============================================================
			[ * single_project Style ] 
================================================================*/
/* --------------- single-project style-5 --------------- */
.single-project.style-5 .about-app.style-5 h1 {
  font-size: 55px; }

.single-project.style-5 .about-app.style-5 .about-cards .about-card {
  text-align: center;
  padding: 15px; }
  .single-project.style-5 .about-app.style-5 .about-cards .about-card small {
    font-size: 11px;
    color: #999;
    margin-bottom: 10px;
    text-transform: uppercase; }
  .single-project.style-5 .about-app.style-5 .about-cards .about-card h6 {
    color: #000;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.6; }

.single-project.style-5 .about-app.style-5 .img-content .img {
  position: relative; }
  .single-project.style-5 .about-app.style-5 .img-content .img .main-img {
    position: relative;
    z-index: 5; }
  .single-project.style-5 .about-app.style-5 .img-content .img .circle {
    position: absolute;
    z-index: 0;
    left: 0;
    top: 15%;
    height: 70%;
    -o-object-fit: contain;
    object-fit: contain;
    -o-object-position: center;
    object-position: center;
    -webkit-animation: rotate-center 100s linear infinite both reverse;
    animation: rotate-center 100s linear infinite both reverse; }
  .single-project.style-5 .about-app.style-5 .img-content .img .bubbls {
    position: absolute;
    z-index: 0;
    left: 0;
    top: 0;
    width: 100%;
    -o-object-fit: contain;
    object-fit: contain;
    -o-object-position: center;
    object-position: center;
    -webkit-animation: rotate-center 100s linear infinite both;
    animation: rotate-center 100s linear infinite both; }

.single-project.style-5 .screenshots {
  position: relative; }
  .single-project.style-5 .screenshots::after {
    position: absolute;
    content: "";
    background-image: url(../img/about/about_s4_wave.png);
    background-repeat: repeat-x;
    width: 100%;
    height: 30px;
    top: -1px;
    left: 0;
    z-index: 10;
    -webkit-transform: rotateX(180deg);
    transform: rotateX(180deg); }

.single-project.style-5 .share .social-icon {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: #f3f7fe;
  color: #666;
  font-size: 12px;
  margin: 20px 3px 0; }
  .single-project.style-5 .share .social-icon:hover {
    background-color: var(--color-blue5);
    color: #fff; }
  .single-project.style-5 .share .social-icon.hover-blue4:hover {
    background-color: var(--color-blue4);
    color: #fff; }

.single-project.style-5 .projects .project-card.style-6:hover .info .title {
  color: var(--color-blue5); }

.single-project.style-5 .projects .project-card.style-6 .info small {
  color: var(--color-blue5); }

.single-project.style-5 .slider-style-6 .swiper-button-next:hover, .single-project.style-5 .slider-style-6 .swiper-button-prev:hover {
  background-color: var(--color-blue5); }

/* ===============================================================
			[ * services page Style ] 
================================================================*/
/* --------------- services page styles --------------- */
.services-page .features {
  background-color: #e7f1ff; }

/* ===============================================================
			[ * services page Style ] 
================================================================*/
/* --------------- services page styles --------------- */
.about-page header.style-5 {
  position: relative; }
  .about-page header.style-5 .info h1 {
    font-size: 55px; }
  .about-page header.style-5 .main-vid {
    position: relative;
    height: 570px;
    border-radius: 20px;
    overflow: hidden;
    border: 10px solid #ffffff01;
    margin-top: 150px;
    -webkit-box-shadow: 0px 30px 40px 0px rgba(109, 121, 141, 0.3);
    box-shadow: 0px 30px 40px 0px rgba(109, 121, 141, 0.3); }
    .about-page header.style-5 .main-vid img {
      border-radius: 15px;
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover; }
    .about-page header.style-5 .main-vid .play-icon {
      position: absolute;
      left: calc(50% - 50px);
      top: calc(50% - 50px);
      width: 100px;
      height: 100px;
      display: -webkit-inline-box;
      display: -ms-inline-flexbox;
      display: inline-flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      border-radius: 50%;
      color: #fff;
      font-size: 25px;
      background-color: var(--color-blue5);
      -webkit-box-shadow: 0px 5px 0px 0px var(--color-blue4);
      box-shadow: 0px 5px 0px 0px var(--color-blue4);
      -webkit-filter: drop-shadow(0 13px 20px #0008);
      filter: drop-shadow(0 13px 20px #0008);
      -webkit-animation: scale_up_down 1s ease-in-out infinite alternate both;
      animation: scale_up_down 1s ease-in-out infinite alternate both; }
  .about-page header.style-5 .hand-mega {
    position: absolute;
    width: 17%;
    top: 9%;
    left: 0;
    -o-object-fit: contain;
    object-fit: contain;
    -o-object-position: left;
    object-position: left;
    z-index: 2; }
  .about-page header.style-5 .head6-rating {
    position: absolute;
    width: 18%;
    top: 27%;
    left: 15%;
    -o-object-fit: contain;
    object-fit: contain;
    z-index: 2; }
  .about-page header.style-5 .head6-charts {
    position: absolute;
    width: 14%;
    top: 7%;
    right: 10%;
    -o-object-fit: contain;
    object-fit: contain;
    z-index: 2; }
  .about-page header.style-5 .head6-rocket {
    position: absolute;
    width: 20%;
    top: 25%;
    right: -30px;
    z-index: 2; }

.about-page .contact .content {
  border-top: 10px solid var(--color-blue5); }

.about-page .contact.style-6 .form-group .form-control:focus, .about-page .contact.style-6 .form-group .form-select:focus {
  border-color: var(--color-blue5); }

.culture.style-5 .culture-card {
  position: relative;
  border-radius: 20px;
  overflow: hidden;
  max-height: 500px; }
  .culture.style-5 .culture-card::after {
    position: absolute;
    content: "";
    height: 50px;
    width: 3px;
    background-color: #fff;
    top: 0%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 10;
    border-radius: 3px;
    opacity: 0;
    -webkit-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out; }
  .culture.style-5 .culture-card::before {
    position: absolute;
    content: "";
    height: 3px;
    width: 50px;
    background-color: #fff;
    top: 50%;
    left: 0%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 10;
    border-radius: 3px;
    opacity: 0;
    -webkit-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out; }
  .culture.style-5 .culture-card .overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0; }
  .culture.style-5 .culture-card:hover .overlay {
    opacity: 0.3; }
  .culture.style-5 .culture-card:hover::after {
    top: 50%;
    opacity: 1; }
  .culture.style-5 .culture-card:hover::before {
    left: 50%;
    opacity: 1; }

/* ===============================================================
			[ * contact page Style ] 
================================================================*/
/* --------------- contact page styles --------------- */
.contact-page.style-5 .contact.style-6 .form-group .form-control:focus, .contact-page.style-5 .contact.style-6 .form-group .form-select:focus {
  border-color: var(--color-blue5); }

.contact-page.style-5 .map iframe {
  width: 100%;
  margin-bottom: -6px; }

/* ===============================================================
			[ * blog page Style ] 
================================================================*/
/* --------------- blog-page style-1 --------------- */
.blog-page.style-5 .blog-details-slider {
  position: relative;
  overflow: hidden; }
  .blog-page.style-5 .blog-details-slider .content-card .img {
    position: relative;
    border-radius: 30px;
    overflow: hidden; }
    .blog-page.style-5 .blog-details-slider .content-card .img.overlay::after {
      position: absolute;
      content: "";
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-image: -webkit-gradient(linear, left bottom, left top, from(#000000e1), color-stop(#0005), to(transparent));
      background-image: -webkit-linear-gradient(bottom, #000000e1, #0005, transparent);
      background-image: -o-linear-gradient(bottom, #000000e1, #0005, transparent);
      background-image: linear-gradient(to top, #000000e1, #0005, transparent); }
    .blog-page.style-5 .blog-details-slider .content-card .img img {
      -o-object-fit: cover;
      object-fit: cover;
      width: 100%;
      height: 500px; }
  .blog-page.style-5 .blog-details-slider .content-card .info {
    position: absolute;
    width: 100%;
    bottom: 0;
    padding: 4vw;
    color: #fff; }
    .blog-page.style-5 .blog-details-slider .content-card .info .date {
      opacity: 0;
      -webkit-transform: translateY(30px);
      -ms-transform: translateY(30px);
      transform: translateY(30px);
      -webkit-transition: all 0.6s ease;
      -o-transition: all 0.6s ease;
      transition: all 0.6s ease; }
    .blog-page.style-5 .blog-details-slider .content-card .info .title {
      opacity: 0;
      -webkit-transform: translateY(30px);
      -ms-transform: translateY(30px);
      transform: translateY(30px);
      -webkit-transition: all 0.6s ease;
      -o-transition: all 0.6s ease;
      transition: all 0.6s ease;
      -webkit-transition-delay: 0.1s;
      -o-transition-delay: 0.1s;
      transition-delay: 0.1s; }
    .blog-page.style-5 .blog-details-slider .content-card .info .text-info {
      opacity: 0;
      -webkit-transform: translateY(30px);
      -ms-transform: translateY(30px);
      transform: translateY(30px);
      -webkit-transition: all 0.6s ease;
      -o-transition: all 0.6s ease;
      transition: all 0.6s ease;
      -webkit-transition-delay: 0.2s;
      -o-transition-delay: 0.2s;
      transition-delay: 0.2s; }
  .blog-page.style-5 .blog-details-slider .content-card a:hover {
    color: var(--color-blue5); }
  .blog-page.style-5 .blog-details-slider .swiper-slide-active .content-card .info small, .blog-page.style-5 .blog-details-slider .swiper-slide-active .content-card .info h2, .blog-page.style-5 .blog-details-slider .swiper-slide-active .content-card .info p {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0); }
  .blog-page.style-5 .blog-details-slider .swiper-pagination {
    width: 100%;
    bottom: 0;
    text-align: right;
    padding: 30px 4vw; }
    .blog-page.style-5 .blog-details-slider .swiper-pagination .swiper-pagination-bullet {
      background-color: #fff;
      opacity: 1; }
      .blog-page.style-5 .blog-details-slider .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
        background-color: var(--color-blue5); }
  .blog-page.style-5 .blog-details-slider .swiper-button-next, .blog-page.style-5 .blog-details-slider .swiper-button-prev {
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    border-radius: 15px;
    width: 50px;
    height: 50px; }
  .blog-page.style-5 .blog-details-slider .swiper-button-next::after, .blog-page.style-5 .blog-details-slider .swiper-button-prev::after {
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg); }
  .blog-page.style-5 .blog-details-slider .swiper-button-next, .blog-page.style-5 .blog-details-slider .swiper-container-rtl .swiper-button-prev {
    right: -35px; }
  .blog-page.style-5 .blog-details-slider .swiper-button-next::after, .blog-page.style-5 .blog-details-slider .swiper-container-rtl .swiper-button-prev::after {
    font-size: 14px;
    margin-right: 20px;
    margin-top: 23px; }
  .blog-page.style-5 .blog-details-slider .swiper-button-prev, .blog-page.style-5 .blog-details-slider .swiper-container-rtl .swiper-button-next {
    left: -35px; }
  .blog-page.style-5 .blog-details-slider .swiper-button-prev::after, .blog-page.style-5 .blog-details-slider .swiper-container-rtl .swiper-button-next::after {
    font-size: 14px;
    margin-left: 25px;
    margin-bottom: 22px; }

.blog-page.style-5 .popular-posts .post-sc-title {
  font-weight: 700;
  letter-spacing: 2px;
  font-size: 20px; }

.blog-page.style-5 .popular-posts .card {
  padding: 0 15px; }
  .blog-page.style-5 .popular-posts .card .img {
    height: 200px;
    overflow: hidden; }
    .blog-page.style-5 .popular-posts .card .img img {
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover; }
  .blog-page.style-5 .popular-posts .card .title a:hover {
    color: var(--color-blue5); }
  .blog-page.style-5 .popular-posts .card a:hover {
    color: var(--color-blue5); }

.blog-page.style-5 .all-news .card .img {
  height: 200px; }

.blog-page.style-5 .all-news .card .card-body .card-title {
  min-height: unset;
  max-width: unset; }

.blog-page.style-5 .all-news .card .card-body {
  position: relative;
  padding-bottom: 40px !important;
  height: 100%; }
  .blog-page.style-5 .all-news .card .card-body .auther-comments {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%; }

.blog-page.style-5 .all-news .card a:hover {
  color: var(--color-blue5); }

.blog-page.style-5 .side-blog {
  position: -webkit-sticky;
  position: sticky;
  top: 30px; }
  .blog-page.style-5 .side-blog .title {
    font-size: 16px;
    font-weight: 600 !important;
    letter-spacing: 2px; }
  .blog-page.style-5 .side-blog .search-form .form-group .form-control {
    min-height: 50px;
    font-size: 12px;
    padding: 10px 50px 10px 20px; }
  .blog-page.style-5 .side-blog .search-form .form-group .search-btn {
    position: absolute;
    right: 10px;
    bottom: 12px; }
  .blog-page.style-5 .side-blog .side-recent-post .post-card {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; }
    .blog-page.style-5 .side-blog .side-recent-post .post-card .img {
      height: 60px;
      width: 30%;
      border-radius: 10px;
      overflow: hidden;
      -ms-flex-negative: 0;
      flex-shrink: 0; }
      .blog-page.style-5 .side-blog .side-recent-post .post-card .img img {
        width: 100%;
        height: 100%;
        -o-object-fit: cover;
        object-fit: cover; }
    .blog-page.style-5 .side-blog .side-recent-post .post-card .inf h6 {
      color: #000;
      font-size: 13px;
      font-weight: bold;
      margin-bottom: 5px; }
    .blog-page.style-5 .side-blog .side-recent-post .post-card .inf p {
      font-size: 10px;
      color: #666; }
    .blog-page.style-5 .side-blog .side-recent-post .post-card:hover .inf h6 {
      color: var(--color-blue5); }
  .blog-page.style-5 .side-blog .side-categories .cat-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    color: #666;
    font-size: 11px;
    text-transform: uppercase;
    padding: 10px 0;
    border-bottom: 1px solid #9995; }
    .blog-page.style-5 .side-blog .side-categories .cat-item:hover {
      color: var(--color-blue5);
      font-weight: bold; }
  .blog-page.style-5 .side-blog .side-newsletter {
    background-color: #eaeef2;
    padding: 50px 30px;
    border-radius: 20px; }
    .blog-page.style-5 .side-blog .side-newsletter .text {
      font-size: 11px;
      color: #666;
      line-height: 1.5; }
  .blog-page.style-5 .side-blog .side-share .social-icon {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-color: #f3f7fe;
    color: #666;
    font-size: 12px;
    margin: 0 3px; }
    .blog-page.style-5 .side-blog .side-share .social-icon:hover {
      background-color: var(--color-blue5);
      color: #fff; }
  .blog-page.style-5 .side-blog .side-insta .insta-img {
    height: 80px;
    width: 31%;
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 10px; }
    .blog-page.style-5 .side-blog .side-insta .insta-img::after {
      position: absolute;
      content: "";
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background-color: #000;
      opacity: 0;
      z-index: 2;
      -webkit-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      transition: all 0.3s ease; }
    .blog-page.style-5 .side-blog .side-insta .insta-img .icon {
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      z-index: 3;
      color: #fff;
      margin-top: 15px;
      opacity: 0;
      -webkit-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      transition: all 0.3s ease; }
    .blog-page.style-5 .side-blog .side-insta .insta-img:hover::after {
      opacity: 0.3; }
    .blog-page.style-5 .side-blog .side-insta .insta-img:hover .icon {
      opacity: 1;
      margin: 0; }

.blog-page.style-5 .blog-content-info .info-imgs .img img {
  height: 300px;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 20px; }

.blog-page.style-5 .blog-content-info .twitter-info .twitter-card {
  padding: 5vw;
  border-top: 2px solid #000; }
  .blog-page.style-5 .blog-content-info .twitter-info .twitter-card .twitter-header .twitter-icon {
    font-size: 25px;
    color: #00ccff; }

.blog-page.style-5 .side-tags a {
  font-size: 11px;
  padding: 4px 8px;
  border-radius: 4px;
  background-color: #eef4f8;
  margin-bottom: 4px; }
  .blog-page.style-5 .side-tags a:hover {
    background-color: var(--color-blue5);
    color: #fff; }

.blog-page.style-5 .blog-share .share-icons a i {
  width: 25px;
  height: 25px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #fff;
  font-size: 12px;
  -webkit-margin-end: 5px;
  margin-inline-end: 5px;
  border-radius: 50%; }
  .blog-page.style-5 .blog-share .share-icons a i.fa-facebook-f {
    background-color: #3b5999; }
  .blog-page.style-5 .blog-share .share-icons a i.fa-twitter {
    background-color: #55acee; }
  .blog-page.style-5 .blog-share .share-icons a i.fa-tumblr {
    background-color: #2b4b6a; }
  .blog-page.style-5 .blog-share .share-icons a i.fa-rss {
    background-color: #fb7000; }

.blog-page.style-5 .blog-comments .comment-card {
  background-color: #f4f8fc; }
  .blog-page.style-5 .blog-comments .comment-card .social-icons a {
    background-color: #dce1e5; }
    .blog-page.style-5 .blog-comments .comment-card .social-icons a:hover {
      background-color: var(--color-blue5);
      color: #fff; }

.blog-page.style-5 .related-postes-slider .swiper-slide-prev {
  position: relative; }
  .blog-page.style-5 .related-postes-slider .swiper-slide-prev::after {
    position: absolute;
    content: "";
    right: -40px;
    top: 0;
    width: 1px;
    height: 100%;
    background-color: #9994; }

.blog-page.style-5 .related-postes-slider .swiper-slide-active::after {
  position: absolute;
  content: "";
  right: -40px;
  top: 0;
  width: 1px;
  height: 100%;
  background-color: #9994; }

.blog-page.style-5 .related-postes-slider .swiper-button-next, .blog-page.style-5 .related-postes-slider .swiper-button-prev {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: #fff; }
  .blog-page.style-5 .related-postes-slider .swiper-button-next:hover, .blog-page.style-5 .related-postes-slider .swiper-button-prev:hover {
    background-color: var(--color-blue5);
    color: #fff; }

.blog-page.style-5 .related-postes-slider .swiper-button-next, .blog-page.style-5 .related-postes-slider .swiper-container-rtl .swiper-button-prev {
  right: 0;
  top: -60px; }

.blog-page.style-5 .related-postes-slider .swiper-button-prev, .blog-page.style-5 .related-postes-slider .swiper-container-rtl .swiper-button-next {
  left: auto;
  right: 50px;
  top: -60px; }

.blog-page.style-5 .related-postes-slider .swiper-button-next::after, .blog-page.style-5 .related-postes-slider .swiper-button-prev::after {
  font-size: 13px; }

.blog-page.style-5.color-4 .side-tags a:hover,
.blog-page.style-5.color-4 .blog-page.style-5 .blog-comments .comment-card .social-icons a:hover,
.blog-page.style-5.color-4 .side-blog .side-share .social-icon:hover,
.blog-page.style-5.color-4 .related-postes-slider .swiper-button-next:hover,
.blog-page.style-5.color-4 .related-postes-slider .swiper-button-prev:hover,
.blog-page.style-5.color-4 .blog-details-slider .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: var(--color-blue4); }

.blog-page.style-5.color-4 .side-blog .side-recent-post .post-card:hover .inf h6,
.blog-page.style-5.color-4 .side-blog .side-categories .cat-item:hover,
.blog-page.style-5.color-4 .popular-posts .card a:hover,
.blog-page.style-5.color-4 .blog-details-slider .content-card a:hover {
  color: var(--color-blue4); }

.blog-page.style-5.color-4 .bg-main {
  background-color: var(--color-blue4) !important; }

/* ===============================================================
			[ * shop page Style ] 
================================================================*/
/* --------------- services page styles --------------- */
.shop-page .filter .filter-card {
  padding: 20px 15px;
  border-radius: 10px;
  background-color: #f0f4f8;
  text-transform: capitalize; }
  .shop-page .filter .filter-card .card-title {
    font-size: 15px;
    color: #000;
    font-weight: 800;
    margin-bottom: 15px; }
  .shop-page .filter .filter-card .form-check-label {
    cursor: pointer; }
  .shop-page .filter .filter-card .cat-link {
    color: #000;
    font-size: 11px;
    font-weight: 600; }
  .shop-page .filter .filter-card .category-checkRadio {
    padding: 0;
    line-height: 1; }
    .shop-page .filter .filter-card .category-checkRadio .form-check-input {
      display: none; }
    .shop-page .filter .filter-card .category-checkRadio .form-check-input:checked ~ .form-check-label {
      color: var(--color-blue5); }
  .shop-page .filter .filter-card .accordion .accordion-button {
    color: #000;
    font-size: 11px;
    font-weight: 600;
    padding: 0;
    background: transparent;
    margin-bottom: 10px;
    -webkit-box-shadow: none;
    box-shadow: none; }
  .shop-page .filter .filter-card .accordion .accordion-button::after {
    background-size: 0.8rem;
    background-position: center; }
  .shop-page .filter .filter-card .accordion .accordion-button:not(.collapsed) {
    color: var(--color-blue5); }
  .shop-page .filter .filter-card .filter-card-item {
    padding-bottom: 15px;
    border-bottom: 1px solid #9994;
    margin-bottom: 20px; }
    .shop-page .filter .filter-card .filter-card-item .sub-tilte {
      font-size: 11px;
      color: #000;
      font-weight: 800;
      position: relative;
      cursor: pointer;
      margin-bottom: 15px; }
    .shop-page .filter .filter-card .filter-card-item .filter-card-body .filter-card-scroll {
      max-height: 130px;
      overflow-x: hidden;
      overflow-y: auto; }
      .shop-page .filter .filter-card .filter-card-item .filter-card-body .filter-card-scroll::-webkit-scrollbar {
        height: 10px;
        width: 5px;
        background: #dfdfdf;
        border-radius: 10px; }
      .shop-page .filter .filter-card .filter-card-item .filter-card-body .filter-card-scroll::-webkit-scrollbar-thumb {
        background: var(--color-blue5);
        -webkit-border-radius: 0;
        border-radius: 10px; }
      .shop-page .filter .filter-card .filter-card-item .filter-card-body .filter-card-scroll::-webkit-scrollbar-corner {
        background: var(--color-blue5);
        border-radius: 10px; }
    .shop-page .filter .filter-card .filter-card-item .search-group {
      position: relative;
      margin-bottom: 25px; }
      .shop-page .filter .filter-card .filter-card-item .search-group input {
        width: 100%;
        background-color: #fff;
        border: 0;
        border-radius: 5px;
        padding: 10px;
        font-size: 11px;
        min-height: 40px;
        -webkit-padding-end: 20px;
        padding-inline-end: 20px; }
      .shop-page .filter .filter-card .filter-card-item .search-group button {
        position: absolute;
        right: 8px;
        top: 10px;
        font-size: 13px;
        border: 0;
        background: transparent; }
  .shop-page .filter .filter-card .category-checkBox {
    font-size: 11px;
    color: #666;
    text-transform: capitalize; }
  .shop-page .filter .filter-card .slider-range-content .amount-input {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between; }
    .shop-page .filter .filter-card .slider-range-content .amount-input .amount {
      width: 35%;
      -webkit-margin-end: 5%;
      margin-inline-end: 5%;
      border-radius: 7px;
      background-color: #fff;
      font-size: 12px;
      overflow: hidden;
      padding: 6px 10px; }
      .shop-page .filter .filter-card .slider-range-content .amount-input .amount small {
        font-size: 10px;
        color: #999999; }
      .shop-page .filter .filter-card .slider-range-content .amount-input .amount input {
        border: 0;
        width: 100%; }
    .shop-page .filter .filter-card .slider-range-content .amount-input button {
      width: 50px;
      height: 50px;
      background-color: var(--color-blue5);
      border: 0;
      color: #fff;
      border-radius: 7px;
      text-transform: capitalize; }
  .shop-page .filter .filter-card .slider-range-content .ui-slider-horizontal {
    margin-top: 25px;
    height: 6px;
    border: 0;
    background-color: #dfdfdf; }
    .shop-page .filter .filter-card .slider-range-content .ui-slider-horizontal .ui-slider-range {
      background-color: var(--color-blue5); }
    .shop-page .filter .filter-card .slider-range-content .ui-slider-horizontal .ui-state-default, .shop-page .filter .filter-card .slider-range-content .ui-slider-horizontal .ui-widget-content .ui-state-default, .shop-page .filter .filter-card .slider-range-content .ui-slider-horizontal .ui-widget-header .ui-state-default, .shop-page .filter .filter-card .slider-range-content .ui-slider-horizontal .ui-button, .shop-page .filter .filter-card .slider-range-content .ui-slider-horizontal html .ui-button.ui-state-disabled:hover, .shop-page .filter .filter-card .slider-range-content .ui-slider-horizontal html .ui-button.ui-state-disabled:active {
      border: 0;
      background: var(--color-blue5);
      border-radius: 50%;
      width: 1em;
      height: 1em; }

.shop-page .products-content .top-filter {
  padding: 15px 0; }
  .shop-page .products-content .top-filter .r-side .form .form-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 11px; }
    .shop-page .products-content .top-filter .r-side .form .form-group label {
      color: #999;
      -ms-flex-negative: 0;
      flex-shrink: 0;
      -webkit-margin-end: 15px;
      margin-inline-end: 15px; }
    .shop-page .products-content .top-filter .r-side .form .form-group .form-select {
      border: 0;
      background-color: #f0f4f8;
      font-size: 11px;
      min-height: 35px;
      border-radius: 12px; }
  .shop-page .products-content .top-filter .r-side .grid-list-btns {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end; }
    .shop-page .products-content .top-filter .r-side .grid-list-btns .bttn {
      width: 35px;
      height: 35px;
      font-size: 17px;
      border-radius: 10px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      background-color: #f0f4f8;
      color: #000;
      -webkit-margin-start: 10px;
      margin-inline-start: 10px;
      cursor: pointer; }
      .shop-page .products-content .top-filter .r-side .grid-list-btns .bttn:hover, .shop-page .products-content .top-filter .r-side .grid-list-btns .bttn.active {
        color: #fff;
        background-color: var(--color-blue5); }

.shop-page .products-content .products .product-card {
  border: 1px solid #ededed;
  padding: 30px 15px 15px;
  position: relative;
  border-radius: 12px;
  padding-bottom: 15px;
  overflow: hidden;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  margin-bottom: 24px; }
  .shop-page .products-content .products .product-card:hover {
    padding-bottom: 60px; }
    .shop-page .products-content .products .product-card:hover .img img {
      -webkit-transform: scale(0.8);
      -ms-transform: scale(0.8);
      transform: scale(0.8); }
    .shop-page .products-content .products .product-card:hover .info {
      margin-top: -25px; }
    .shop-page .products-content .products .product-card:hover .btn {
      -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
      transform: translateY(0);
      opacity: 1; }
  .shop-page .products-content .products .product-card .img {
    height: 130px; }
    .shop-page .products-content .products .product-card .img img {
      width: 100%;
      height: 100%;
      -o-object-fit: contain;
      object-fit: contain;
      -webkit-transition: all 0.4s ease;
      -o-transition: all 0.4s ease;
      transition: all 0.4s ease;
      -webkit-transform-origin: top;
      -ms-transform-origin: top;
      transform-origin: top; }
  .shop-page .products-content .products .product-card .info {
    margin-top: 20px;
    min-height: 77px;
    -webkit-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease; }
    .shop-page .products-content .products .product-card .info .category {
      font-size: 10px;
      font-weight: bold;
      text-transform: uppercase;
      margin-bottom: 7px; }
    .shop-page .products-content .products .product-card .info .title {
      font-size: 12px;
      text-transform: capitalize;
      font-weight: 400;
      margin-bottom: 0; }
    .shop-page .products-content .products .product-card .info .stars {
      display: -webkit-inline-box;
      display: -ms-inline-flexbox;
      display: inline-flex;
      font-size: 9px;
      color: #d0d0d0; }
      .shop-page .products-content .products .product-card .info .stars .active {
        color: #ffba00; }
    .shop-page .products-content .products .product-card .info .rev {
      color: #999;
      font-size: 9px; }
  .shop-page .products-content .products .product-card .price {
    margin-top: 15px;
    font-size: 15px;
    font-weight: bold; }
    .shop-page .products-content .products .product-card .price .price-sale {
      color: #f73312; }
    .shop-page .products-content .products .product-card .price .old-price {
      font-size: 11px;
      color: #999999;
      text-decoration: line-through !important;
      -webkit-margin-start: 5px;
      margin-inline-start: 5px; }
  .shop-page .products-content .products .product-card .btn {
    position: absolute;
    bottom: 15px;
    left: 15px;
    width: calc(100% - 30px);
    background-color: var(--color-blue5);
    padding: 7px 15px;
    -webkit-transform: translateY(70px);
    -ms-transform: translateY(70px);
    transform: translateY(70px);
    opacity: 0;
    -webkit-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease; }
    .shop-page .products-content .products .product-card .btn span {
      font-size: 11px;
      color: #fff;
      font-weight: bold; }
    .shop-page .products-content .products .product-card .btn:hover {
      background-color: #000; }
  .shop-page .products-content .products .product-card .label {
    position: absolute;
    left: 10px;
    top: 10px;
    font-size: 9px;
    border-radius: 4px;
    background-color: #f0f0f0;
    color: #000;
    padding: 3px 10px;
    text-transform: uppercase; }
    .shop-page .products-content .products .product-card .label.new {
      background-color: #ffb500; }
    .shop-page .products-content .products .product-card .label.sale-off {
      background-color: #f73312;
      color: #fff; }
  .shop-page .products-content .products .product-card .fav-btn {
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 14px;
    color: #ccc;
    cursor: pointer; }
    .shop-page .products-content .products .product-card .fav-btn.active {
      color: #f73312; }

.shop-page .products-content .products.list-view .card-width {
  width: 50%; }

.shop-page .products-content .products.list-view .product-card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }
  .shop-page .products-content .products.list-view .product-card .img {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    width: 35%;
    margin-right: 10px; }
  .shop-page .products-content .products.list-view .product-card:hover {
    padding-top: 12px;
    padding-bottom: 35px; }
    .shop-page .products-content .products.list-view .product-card:hover .img img {
      -webkit-transform: scale(1);
      -ms-transform: scale(1);
      transform: scale(1); }
    .shop-page .products-content .products.list-view .product-card:hover .info {
      margin-top: 25px; }

.shop-page.style-grad .filter .filter-card .category-checkRadio .form-check-input:checked ~ .form-check-label {
  color: var(--color-blue4); }

.shop-page.style-grad .filter .filter-card .accordion .accordion-button:not(.collapsed) {
  color: var(--color-blue4); }

.shop-page.style-grad .filter .filter-card .filter-card-item .filter-card-body .filter-card-scroll::-webkit-scrollbar-thumb {
  background: var(--color-blue4); }

.shop-page.style-grad .filter .filter-card .filter-card-item .filter-card-body .filter-card-scroll::-webkit-scrollbar-corner {
  background: var(--color-blue4); }

.shop-page.style-grad .filter .filter-card .slider-range-content .amount-input button {
  background-color: var(--color-blue4); }

.shop-page.style-grad .filter .filter-card .slider-range-content .ui-slider-horizontal .ui-slider-range {
  background-color: var(--color-blue4); }

.shop-page.style-grad .filter .filter-card .slider-range-content .ui-slider-horizontal .ui-state-default, .shop-page.style-grad .filter .filter-card .slider-range-content .ui-slider-horizontal .ui-widget-content .ui-state-default, .shop-page.style-grad .filter .filter-card .slider-range-content .ui-slider-horizontal .ui-widget-header .ui-state-default, .shop-page.style-grad .filter .filter-card .slider-range-content .ui-slider-horizontal .ui-button, .shop-page.style-grad .filter .filter-card .slider-range-content .ui-slider-horizontal html .ui-button.ui-state-disabled:hover, .shop-page.style-grad .filter .filter-card .slider-range-content .ui-slider-horizontal html .ui-button.ui-state-disabled:active {
  background: var(--color-blue4); }

.shop-page.style-grad .products-content .top-filter .r-side .grid-list-btns .bttn:hover, .shop-page.style-grad .products-content .top-filter .r-side .grid-list-btns .bttn.active {
  background-color: var(--color-blue4); }

.shop-page.style-grad .products-content .products .product-card .btn {
  background-color: var(--color-blue4); }

/* ===============================================================
			[ * shop product Style ] 
================================================================*/
/* --------------- services product styles --------------- */
.product-page .product-slider {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }
  .product-page .product-slider .gallery-thumbs {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -webkit-margin-end: 20px;
    margin-inline-end: 20px;
    height: 500px; }
    .product-page .product-slider .gallery-thumbs .swiper-slide {
      min-height: 75px;
      cursor: pointer; }
      .product-page .product-slider .gallery-thumbs .swiper-slide .img {
        width: 80px;
        height: 75px;
        border: 1px solid #9993;
        border-radius: 7px;
        padding: 8px; }
        .product-page .product-slider .gallery-thumbs .swiper-slide .img img {
          width: 100%;
          height: 100%;
          -o-object-fit: contain;
          object-fit: contain; }
      .product-page .product-slider .gallery-thumbs .swiper-slide.swiper-slide-thumb-active .img {
        border-color: var(--color-blue5); }
  .product-page .product-slider .gallery-top .swiper-slide .img {
    height: 500px;
    padding: 3vw;
    border: 1px solid #9993;
    border-radius: 7px; }
    .product-page .product-slider .gallery-top .swiper-slide .img img {
      width: 100%;
      height: 100%;
      -o-object-fit: contain;
      object-fit: contain; }

.product-page .product-info .category {
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 7px; }

.product-page .product-info .title {
  font-size: 20px;
  text-transform: capitalize;
  font-weight: 400;
  margin-bottom: 0; }

.product-page .product-info .stars {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-size: 10px;
  color: #d0d0d0; }
  .product-page .product-info .stars .active {
    color: #ffba00; }

.product-page .product-info .rev {
  color: #999;
  font-size: 10px; }

.product-page .product-info .price {
  font-size: 25px;
  color: #000;
  font-weight: bold;
  margin-top: 30px; }

.product-page .product-info .info-text {
  font-size: 14px;
  color: #666;
  margin-top: 20px; }

.product-page .product-info .info-list {
  margin-top: 15px;
  font-size: 14px;
  color: #666; }
  .product-page .product-info .info-list li {
    margin-bottom: 5px; }

.product-page .product-info .color-quantity {
  padding: 20px 0;
  border-top: 1px solid #9993;
  margin-top: 25px; }
  .product-page .product-info .color-quantity .select-color {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
    .product-page .product-info .color-quantity .select-color .colors-content .form-check {
      padding: 0;
      margin-bottom: 0; }
      .product-page .product-info .color-quantity .select-color .colors-content .form-check .form-check-input {
        display: none; }
      .product-page .product-info .color-quantity .select-color .colors-content .form-check .form-check-label .color-circle {
        width: 28px;
        height: 28px;
        border-radius: 50%;
        border: 3px solid #fff; }
        .product-page .product-info .color-quantity .select-color .colors-content .form-check .form-check-label .color-circle.gray {
          background-color: #e3e3e3; }
        .product-page .product-info .color-quantity .select-color .colors-content .form-check .form-check-label .color-circle.black {
          background-color: #000; }
        .product-page .product-info .color-quantity .select-color .colors-content .form-check .form-check-label .color-circle.blue {
          background-color: #1941c4; }
        .product-page .product-info .color-quantity .select-color .colors-content .form-check .form-check-label .color-circle.green {
          background-color: #9ac419; }
      .product-page .product-info .color-quantity .select-color .colors-content .form-check .form-check-input:checked ~ .form-check-label .color-circle {
        outline: 1px solid var(--color-blue5); }

.product-page .product-info .qyt-addCart {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 20px;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }
  .product-page .product-info .qyt-addCart .add-more {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 12px;
    padding: 10px 15px;
    border: 1px solid #9993;
    width: 30%;
    border-radius: 30px; }
    .product-page .product-info .qyt-addCart .add-more .qt-minus,
    .product-page .product-info .qyt-addCart .add-more .qt-plus {
      cursor: pointer; }
    .product-page .product-info .qyt-addCart .add-more .qt {
      font-size: 14px; }
  .product-page .product-info .qyt-addCart .btn {
    background-color: var(--color-blue5);
    padding: 10px 30px;
    width: 40%; }
    .product-page .product-info .qyt-addCart .btn span {
      font-size: 12px;
      color: #fff;
      font-weight: bold; }
    .product-page .product-info .qyt-addCart .btn:hover {
      background-color: #000; }

.product-page .product-info .fav-btn {
  width: 45px;
  height: 45px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid #0002;
  font-size: 15px;
  color: #ccc;
  cursor: pointer; }
  .product-page .product-info .fav-btn.active {
    color: #f73312; }

.product-page .product-info .socail-icons {
  margin-top: 40px; }
  .product-page .product-info .socail-icons a:hover {
    background-color: var(--color-blue5) !important;
    color: #fff !important; }

.product-page .product-details .nav-pills {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-bottom: 1px solid #0002;
  padding-bottom: 10px; }
  .product-page .product-details .nav-pills .nav-link {
    border: 0;
    background: transparent;
    font-size: 25px;
    color: #666;
    text-transform: capitalize; }
    .product-page .product-details .nav-pills .nav-link.active {
      color: #000;
      font-weight: bold; }

.product-page .product-details .content-info {
  padding: 50px 8vw; }
  .product-page .product-details .content-info .text {
    color: #666; }

.product-page .product-details .additional-info ul {
  border: 1px solid #0002; }
  .product-page .product-details .additional-info ul li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 15px 25px;
    font-style: 14px;
    color: #666;
    border-bottom: 1px solid #0002; }
    .product-page .product-details .additional-info ul li:last-of-type {
      border: 0; }

.product-page .product-details .stars {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-size: 12px;
  color: #d0d0d0; }
  .product-page .product-details .stars .active {
    color: #ffba00; }

.product-page .product-details .rate-stars {
  display: block;
  position: relative;
  text-align: end;
  margin-bottom: 30px;
  direction: rtl; }
  .product-page .product-details .rate-stars input {
    border-radius: 0;
    visibility: hidden;
    width: 20px;
    cursor: pointer; }
    .product-page .product-details .rate-stars input:after {
      content: "\f005";
      font-family: "Font Awesome 5 pro";
      font-weight: 900;
      font-size: 20px;
      color: #eee;
      visibility: visible;
      display: inline-block;
      width: 20px;
      height: 20px;
      -webkit-transition: background 0.3s;
      -o-transition: background 0.3s;
      transition: background 0.3s;
      -webkit-transition: -webkit-transform 0.6s;
      transition: -webkit-transform 0.6s;
      -o-transition: transform 0.6s;
      transition: transform 0.6s;
      transition: transform 0.6s, -webkit-transform 0.6s; }
    .product-page .product-details .rate-stars input:hover:after {
      opacity: 0.8; }
    .product-page .product-details .rate-stars input:checked:after {
      color: #fc0;
      -webkit-transform: scale(1.3);
      -ms-transform: scale(1.3);
      transform: scale(1.3); }
    .product-page .product-details .rate-stars input:checked ~ input:after {
      color: #fc0; }
    .product-page .product-details .rate-stars input:not(:checked):after {
      color: #eee; }

.product-page .related-products .title {
  font-size: 25px;
  margin-bottom: 35px; }

.product-page .related-products .related-products-slider .swiper-button-next, .product-page .related-products .related-products-slider .swiper-button-prev {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #d8dee4; }
  .product-page .related-products .related-products-slider .swiper-button-next:hover, .product-page .related-products .related-products-slider .swiper-button-prev:hover {
    background: var(--color-blue5); }

.product-page .related-products .related-products-slider .swiper-button-next, .product-page .related-products .related-products-slider .swiper-container-rtl .swiper-button-prev {
  right: -50px; }

.product-page .related-products .related-products-slider .swiper-button-prev, .product-page .related-products .related-products-slider .swiper-container-rtl .swiper-button-next {
  left: -50px; }

.product-page .related-products .related-products-slider .swiper-button-next:after, .product-page .related-products .related-products-slider .swiper-button-prev:after {
  font-size: 10px; }

.product-page .product-card {
  border: 1px solid #ededed;
  padding: 30px 15px 15px;
  position: relative;
  border-radius: 12px;
  padding-bottom: 15px;
  overflow: hidden;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  margin-bottom: 24px; }
  .product-page .product-card:hover {
    padding-bottom: 60px; }
    .product-page .product-card:hover .img img {
      -webkit-transform: scale(0.8);
      -ms-transform: scale(0.8);
      transform: scale(0.8); }
    .product-page .product-card:hover .info {
      margin-top: -25px; }
    .product-page .product-card:hover .btn {
      -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
      transform: translateY(0);
      opacity: 1; }
  .product-page .product-card .img {
    height: 130px; }
    .product-page .product-card .img img {
      width: 100%;
      height: 100%;
      -o-object-fit: contain;
      object-fit: contain;
      -webkit-transition: all 0.4s ease;
      -o-transition: all 0.4s ease;
      transition: all 0.4s ease;
      -webkit-transform-origin: top;
      -ms-transform-origin: top;
      transform-origin: top; }
  .product-page .product-card .info {
    margin-top: 20px;
    min-height: 77px;
    -webkit-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease; }
    .product-page .product-card .info .category {
      font-size: 10px;
      font-weight: bold;
      text-transform: uppercase;
      margin-bottom: 7px; }
    .product-page .product-card .info .title {
      font-size: 12px;
      text-transform: capitalize;
      font-weight: 400;
      margin-bottom: 0; }
    .product-page .product-card .info .stars {
      display: -webkit-inline-box;
      display: -ms-inline-flexbox;
      display: inline-flex;
      font-size: 9px;
      color: #d0d0d0; }
      .product-page .product-card .info .stars .active {
        color: #ffba00; }
    .product-page .product-card .info .rev {
      color: #999;
      font-size: 9px; }
  .product-page .product-card .price {
    margin-top: 15px;
    font-size: 15px;
    font-weight: bold; }
    .product-page .product-card .price .price-sale {
      color: #f73312; }
    .product-page .product-card .price .old-price {
      font-size: 11px;
      color: #999999;
      text-decoration: line-through !important;
      -webkit-margin-start: 5px;
      margin-inline-start: 5px; }
  .product-page .product-card .btn {
    position: absolute;
    bottom: 15px;
    left: 15px;
    width: calc(100% - 30px);
    background-color: var(--color-blue5);
    padding: 7px 15px;
    -webkit-transform: translateY(70px);
    -ms-transform: translateY(70px);
    transform: translateY(70px);
    opacity: 0;
    -webkit-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease; }
    .product-page .product-card .btn span {
      font-size: 11px;
      color: #fff;
      font-weight: bold; }
    .product-page .product-card .btn:hover {
      background-color: #000; }
  .product-page .product-card .label {
    position: absolute;
    left: 10px;
    top: 10px;
    font-size: 9px;
    border-radius: 4px;
    background-color: #f0f0f0;
    color: #000;
    padding: 3px 10px;
    text-transform: uppercase; }
    .product-page .product-card .label.new {
      background-color: #ffb500; }
    .product-page .product-card .label.sale-off {
      background-color: #f73312;
      color: #fff; }
  .product-page .product-card .fav-btn {
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 14px;
    color: #ccc;
    cursor: pointer; }
    .product-page .product-card .fav-btn.active {
      color: #f73312; }

/* ===============================================================
			[ * faq page Style ] 
================================================================*/
/* --------------- faq-page style-5 --------------- */
.faq-page.style-5 .faq-tabs {
  margin-bottom: 80px; }
  .faq-page.style-5 .faq-tabs .nav {
    margin: 0 -15px; }
    .faq-page.style-5 .faq-tabs .nav .nav-item {
      width: 25%;
      padding: 0 15px; }
      .faq-page.style-5 .faq-tabs .nav .nav-item .nav-card {
        display: block;
        background-color: #fff;
        border: 1px solid #9992;
        border-radius: 10px;
        padding: 50px 15px;
        text-align: center;
        width: 100%;
        -webkit-box-shadow: 0px 16px 32px 0px #0000000a;
        box-shadow: 0px 16px 32px 0px #0000000a; }
        .faq-page.style-5 .faq-tabs .nav .nav-item .nav-card .icon {
          width: 60px;
          height: 60px;
          margin: 0 auto 30px; }
        .faq-page.style-5 .faq-tabs .nav .nav-item .nav-card h5 {
          font-size: 18px;
          text-transform: capitalize; }
        .faq-page.style-5 .faq-tabs .nav .nav-item .nav-card p {
          color: #999; }
        .faq-page.style-5 .faq-tabs .nav .nav-item .nav-card.active {
          border-color: var(--color-blue5);
          -webkit-box-shadow: none;
          box-shadow: none; }

.faq-page.style-5 .faq-body .faq-category {
  padding: 50px;
  background-color: var(--color-blue5);
  border-radius: 5px;
  color: #fff;
  position: -webkit-sticky;
  position: sticky;
  top: 100px; }
  .faq-page.style-5 .faq-body .faq-category h5 {
    margin-bottom: 20px; }
  .faq-page.style-5 .faq-body .faq-category li {
    margin: 10px 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    text-transform: capitalize; }
    .faq-page.style-5 .faq-body .faq-category li a {
      position: relative;
      font-weight: 500;
      color: #fff; }
      .faq-page.style-5 .faq-body .faq-category li a::before {
        position: absolute;
        content: "";
        left: 0;
        top: 50%;
        height: 1px;
        width: 0;
        background-color: #fff;
        opacity: 0;
        -webkit-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease; }
      .faq-page.style-5 .faq-body .faq-category li a:hover, .faq-page.style-5 .faq-body .faq-category li a.active {
        color: #fff;
        padding-left: 35px; }
        .faq-page.style-5 .faq-body .faq-category li a:hover::before, .faq-page.style-5 .faq-body .faq-category li a.active::before {
          width: 20px;
          opacity: 1; }
    .faq-page.style-5 .faq-body .faq-category li span {
      font-weight: 300;
      opacity: 0.5; }

.faq-page.style-5 .faq-body .faq-questions .sec-title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 30px; }
  .faq-page.style-5 .faq-body .faq-questions .sec-title span {
    color: #999; }

.faq-page.style-5 .faq-body .faq-questions .accordion {
  padding-top: 80px; }

.faq-page.style-5 .faq-body .faq-questions .accordion-item {
  border: 0;
  border-radius: 0;
  margin-bottom: 20px; }
  .faq-page.style-5 .faq-body .faq-questions .accordion-item .accordion-button {
    background-color: #f4f6f9;
    border-radius: 10px;
    font-weight: bold;
    color: #000;
    -webkit-box-shadow: none;
    box-shadow: none;
    padding: 12px 20px; }
  .faq-page.style-5 .faq-body .faq-questions .accordion-item .accordion-button:not(.collapsed) {
    background-color: var(--color-blue5);
    color: #fff; }
    .faq-page.style-5 .faq-body .faq-questions .accordion-item .accordion-button:not(.collapsed)::after {
      content: "\f068"; }
  .faq-page.style-5 .faq-body .faq-questions .accordion-item .accordion-body {
    font-size: 16px;
    color: #666;
    font-weight: 500; }
  .faq-page.style-5 .faq-body .faq-questions .accordion-item .accordion-button::after {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background: #fff;
    border-radius: 50%;
    color: #000;
    content: "\f067";
    font-weight: 900;
    font-family: "Font Awesome 5 pro";
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: 12px; }

/* ===============================================================
			[ * careers page Style ] 
================================================================*/
/* --------------- faq-page style-5 --------------- */
.careers-page.style-5 .careers-features .careers-feat-card {
  padding: 40px 30px;
  border: 1px solid #9992;
  border-radius: 10px;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  margin-bottom: 30px; }
  .careers-page.style-5 .careers-features .careers-feat-card:hover {
    -webkit-box-shadow: 0px 16px 32px 0px #0000000f;
    box-shadow: 0px 16px 32px 0px #0000000f; }
  .careers-page.style-5 .careers-features .careers-feat-card .icon {
    margin-bottom: 30px; }
    .careers-page.style-5 .careers-features .careers-feat-card .icon img {
      width: 80px;
      height: 80px;
      -o-object-fit: contain;
      object-fit: contain; }
  .careers-page.style-5 .careers-features .careers-feat-card .info h4 {
    font-size: 20px;
    margin-bottom: 10px; }
  .careers-page.style-5 .careers-features .careers-feat-card .info p {
    color: #777; }

.careers-page.style-5 .careers-images .swiper-wrapper {
  -webkit-transition-timing-function: linear !important;
  -o-transition-timing-function: linear !important;
  transition-timing-function: linear !important;
  position: relative; }

.careers-page.style-5 .careers-positions .position-card {
  padding: 30px;
  border: 1px solid #9993;
  border-radius: 5px;
  background-color: #fff;
  display: block;
  position: relative; }
  .careers-page.style-5 .careers-positions .position-card h5 {
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 15px; }
  .careers-page.style-5 .careers-positions .position-card p {
    font-size: 16px;
    color: #666;
    margin-bottom: 50px; }
  .careers-page.style-5 .careers-positions .position-card .time {
    font-size: 16px;
    color: #000; }
  .careers-page.style-5 .careers-positions .position-card .trend-mark {
    position: absolute;
    width: 36px;
    height: 60px;
    line-height: 50px;
    top: 0;
    right: 30px;
    background-color: var(--color-blue5);
    color: #fff;
    text-align: center; }
    .careers-page.style-5 .careers-positions .position-card .trend-mark::after {
      position: absolute;
      content: "";
      right: 0;
      bottom: 0;
      width: 36px;
      height: 36px;
      border-top: 18px solid transparent;
      border-right: 18px solid transparent;
      border-left: 18px solid transparent;
      border-bottom: 18px solid #fff; }

.careers-page.style-5 .career-form {
  position: relative;
  background-color: var(--color-blue5);
  color: #fff; }
  .careers-page.style-5 .career-form .container {
    position: relative;
    z-index: 10; }
  .careers-page.style-5 .career-form .map_img {
    position: absolute;
    left: 0;
    top: 70px;
    pointer-events: none;
    opacity: 0.3;
    width: 50%; }
  .careers-page.style-5 .career-form h2 {
    margin-bottom: 10px;
    font-size: 44px; }
  .careers-page.style-5 .career-form p {
    font-size: 16px; }
  .careers-page.style-5 .career-form .mum-card {
    padding: 20px 0;
    margin-top: 20px;
    border-top: 1px solid #fff2; }
    .careers-page.style-5 .career-form .mum-card h3 {
      font-size: 50px; }
    .careers-page.style-5 .career-form .mum-card small {
      font-size: 14px;
      color: #9dc7f9; }
  .careers-page.style-5 .career-form .form .form-group {
    position: relative; }
    .careers-page.style-5 .career-form .form .form-group .icon {
      position: absolute;
      top: 15px;
      left: 15px;
      color: #fff; }
    .careers-page.style-5 .career-form .form .form-group .form-control {
      background-color: #fff1;
      border-radius: 5px;
      min-height: 50px;
      border: 0;
      font-weight: 300;
      color: #fff;
      padding-left: 40px; }
      .careers-page.style-5 .career-form .form .form-group .form-control::-webkit-input-placeholder {
        color: #9ecbff;
        font-weight: 300; }
      .careers-page.style-5 .career-form .form .form-group .form-control:-ms-input-placeholder {
        color: #9ecbff;
        font-weight: 300; }
      .careers-page.style-5 .career-form .form .form-group .form-control::-ms-input-placeholder {
        color: #9ecbff;
        font-weight: 300; }
      .careers-page.style-5 .career-form .form .form-group .form-control::placeholder {
        color: #9ecbff;
        font-weight: 300; }
      .careers-page.style-5 .career-form .form .form-group .form-control:focus {
        border: 1px solid #fff;
        background-color: transparent; }
    .careers-page.style-5 .career-form .form .form-group textarea.form-control {
      padding-top: 10px; }
    .careers-page.style-5 .career-form .form .form-group.upload-card {
      text-align: center; }
      .careers-page.style-5 .career-form .form .form-group.upload-card .form-control {
        position: relative;
        color: #fff3;
        background-color: #0002;
        padding: 12px; }
      .careers-page.style-5 .career-form .form .form-group.upload-card .upload_input {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        z-index: 5; }
  .careers-page.style-5 .career-form .form .btn span {
    font-size: 16px; }

/* ===============================================================
			[ * careers details page Style ] 
================================================================*/
/* --------------- career-details-page style-5 --------------- */
.career-details-page.style-5 {
  color: #000; }
  .career-details-page.style-5 p {
    font-size: 16px;
    color: #666; }
  .career-details-page.style-5 a {
    font-size: 16px; }
  .career-details-page.style-5 h6 {
    font-size: 20px;
    font-weight: bold; }
  .career-details-page.style-5 .jop-details .jop-info .tags a {
    font-weight: 600;
    padding: 6px 15px;
    border-radius: 30px;
    background-color: #edf5ff;
    color: var(--color-blue5); }
  .career-details-page.style-5 .summary-card {
    border: 1px solid #9993;
    border-radius: 5px;
    padding: 40px;
    position: -webkit-sticky;
    position: sticky;
    top: 120px; }
    .career-details-page.style-5 .summary-card li {
      font-size: 14px;
      color: #999;
      margin: 15px 0; }
      .career-details-page.style-5 .summary-card li span {
        color: #000; }
  .career-details-page.style-5 .careers-positions .position-card {
    padding: 30px;
    border: 1px solid #9993;
    border-radius: 5px;
    background-color: #fff;
    display: block;
    position: relative; }
    .career-details-page.style-5 .careers-positions .position-card h5 {
      font-weight: bold;
      font-size: 20px;
      margin-bottom: 15px; }
    .career-details-page.style-5 .careers-positions .position-card p {
      font-size: 16px;
      color: #666;
      margin-bottom: 50px; }
    .career-details-page.style-5 .careers-positions .position-card .time {
      font-size: 16px;
      color: #000; }
    .career-details-page.style-5 .careers-positions .position-card .trend-mark {
      position: absolute;
      width: 36px;
      height: 60px;
      line-height: 50px;
      top: 0;
      right: 30px;
      background-color: var(--color-blue5);
      color: #fff;
      text-align: center; }
      .career-details-page.style-5 .careers-positions .position-card .trend-mark::after {
        position: absolute;
        content: "";
        right: 0;
        bottom: 0;
        width: 36px;
        height: 36px;
        border-top: 18px solid transparent;
        border-right: 18px solid transparent;
        border-left: 18px solid transparent;
        border-bottom: 18px solid #fff; }
  .career-details-page.style-5 .careers-positions-slider5 {
    position: relative; }
    .career-details-page.style-5 .careers-positions-slider5 .swiper-button-next,
    .career-details-page.style-5 .careers-positions-slider5 .swiper-button-prev {
      width: 55px;
      height: 55px;
      top: 0;
      border: 1px solid #9993;
      top: -100px;
      -webkit-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      transition: all 0.3s ease; }
      .career-details-page.style-5 .careers-positions-slider5 .swiper-button-next:hover,
      .career-details-page.style-5 .careers-positions-slider5 .swiper-button-prev:hover {
        border: 1px solid var(--color-blue5);
        background: var(--color-blue5); }
    .career-details-page.style-5 .careers-positions-slider5 .swiper-button-next, .career-details-page.style-5 .careers-positions-slider5 .swiper-container-rtl .swiper-button-prev {
      right: 0; }
    .career-details-page.style-5 .careers-positions-slider5 .swiper-button-prev, .career-details-page.style-5 .careers-positions-slider5 .swiper-container-rtl .swiper-button-next {
      right: 75px;
      left: auto; }
  .career-details-page.style-5 .career-form {
    position: relative;
    background-color: var(--color-blue5);
    color: #fff; }
    .career-details-page.style-5 .career-form p {
      color: #fff; }
    .career-details-page.style-5 .career-form .container {
      position: relative;
      z-index: 10; }
    .career-details-page.style-5 .career-form .map_img {
      position: absolute;
      left: 0;
      top: 70px;
      pointer-events: none;
      opacity: 0.3;
      width: 50%; }
    .career-details-page.style-5 .career-form h2 {
      margin-bottom: 10px;
      font-size: 44px; }
    .career-details-page.style-5 .career-form p {
      font-size: 16px; }
    .career-details-page.style-5 .career-form .mum-card {
      padding: 20px 0;
      margin-top: 20px;
      border-top: 1px solid #fff2; }
      .career-details-page.style-5 .career-form .mum-card h3 {
        font-size: 50px; }
      .career-details-page.style-5 .career-form .mum-card small {
        font-size: 14px;
        color: #9dc7f9; }
    .career-details-page.style-5 .career-form .form .form-group {
      position: relative; }
      .career-details-page.style-5 .career-form .form .form-group .icon {
        position: absolute;
        top: 15px;
        left: 15px;
        color: #fff; }
      .career-details-page.style-5 .career-form .form .form-group .form-control {
        background-color: #fff1;
        border-radius: 5px;
        min-height: 50px;
        border: 0;
        font-weight: 300;
        color: #fff;
        padding-left: 40px; }
        .career-details-page.style-5 .career-form .form .form-group .form-control::-webkit-input-placeholder {
          color: #9ecbff;
          font-weight: 300; }
        .career-details-page.style-5 .career-form .form .form-group .form-control:-ms-input-placeholder {
          color: #9ecbff;
          font-weight: 300; }
        .career-details-page.style-5 .career-form .form .form-group .form-control::-ms-input-placeholder {
          color: #9ecbff;
          font-weight: 300; }
        .career-details-page.style-5 .career-form .form .form-group .form-control::placeholder {
          color: #9ecbff;
          font-weight: 300; }
        .career-details-page.style-5 .career-form .form .form-group .form-control:focus {
          border: 1px solid #fff;
          background-color: transparent; }
      .career-details-page.style-5 .career-form .form .form-group textarea.form-control {
        padding-top: 10px; }
      .career-details-page.style-5 .career-form .form .form-group.upload-card {
        text-align: center; }
        .career-details-page.style-5 .career-form .form .form-group.upload-card .form-control {
          position: relative;
          color: #fff3;
          background-color: #0002;
          padding: 12px; }
        .career-details-page.style-5 .career-form .form .form-group.upload-card .upload_input {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
          z-index: 5; }
    .career-details-page.style-5 .career-form .form .btn span {
      font-size: 16px; }

/* ===============================================================
			[ * about 2 page Style ] 
================================================================*/
/* --------------- about2 page style-5 --------------- */
.about-page.style-2 {
  color: #000; }
  .about-page.style-2 .about.style-2 {
    position: relative; }
    .about-page.style-2 .about.style-2 .img {
      height: 530px;
      border-radius: 10px;
      overflow: hidden; }
    .about-page.style-2 .about.style-2 .info .text {
      color: #666;
      font-size: 16px; }
    .about-page.style-2 .about.style-2 .info .nav {
      padding: 15px;
      border-radius: 30px;
      border: 1px solid #9993;
      -webkit-box-shadow: 0 0 20px #0001;
      box-shadow: 0 0 20px #0001;
      -ms-flex-pack: distribute;
      justify-content: space-around;
      margin: 40px 0 30px; }
      .about-page.style-2 .about.style-2 .info .nav .nav-item .nav-link {
        position: relative;
        color: #000;
        border-radius: 0;
        background: transparent;
        padding: 0;
        padding-left: 20px;
        font-weight: bold; }
        .about-page.style-2 .about.style-2 .info .nav .nav-item .nav-link::before {
          position: absolute;
          content: "";
          left: 0;
          top: 7px;
          width: 8px;
          height: 8px;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
          background-color: #9999; }
        .about-page.style-2 .about.style-2 .info .nav .nav-item .nav-link.active {
          color: var(--color-blue5); }
    .about-page.style-2 .about.style-2 .pattern_l {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
      -o-object-position: left center;
      object-position: left center; }
    .about-page.style-2 .about.style-2 .pattern_r {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
      -o-object-position: right center;
      object-position: right center; }
  .about-page.style-2 .timeline {
    position: relative; }
    .about-page.style-2 .timeline .card-year h3 {
      color: var(--color-blue5);
      font-size: 44px; }
    .about-page.style-2 .timeline .timeline-content {
      position: relative; }
      .about-page.style-2 .timeline .timeline-content::after {
        position: absolute;
        content: "";
        left: 50%;
        -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        transform: translateX(-50%);
        top: 0;
        width: 1px;
        height: 100%;
        background-color: #d2e7ff; }
      .about-page.style-2 .timeline .timeline-content .timeline-card {
        position: relative;
        padding: 30px 0; }
        .about-page.style-2 .timeline .timeline-content .timeline-card::after {
          position: absolute;
          content: "";
          left: calc(50% - 14px);
          top: calc(50% - 1px);
          width: 28px;
          height: 2px;
          background-color: var(--color-blue5);
          z-index: 2; }
        .about-page.style-2 .timeline .timeline-content .timeline-card:first-of-type {
          padding-top: 0; }
        .about-page.style-2 .timeline .timeline-content .timeline-card:last-of-type {
          padding-bottom: 0; }
        .about-page.style-2 .timeline .timeline-content .timeline-card .line {
          position: absolute;
          content: "";
          left: 50%;
          -webkit-transform: translateX(-50%);
          -ms-transform: translateX(-50%);
          transform: translateX(-50%);
          top: 0;
          width: 1px;
          height: 100%;
          background-color: var(--color-blue5);
          z-index: 6;
          -webkit-clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
          clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
          -webkit-transition: all 1s ease-in-out;
          -o-transition: all 1s ease-in-out;
          transition: all 1s ease-in-out; }
          .about-page.style-2 .timeline .timeline-content .timeline-card .line.animated {
            -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%); }
      .about-page.style-2 .timeline .timeline-content .card-info {
        position: relative;
        padding: 30px;
        border-radius: 5px;
        background-color: #fff; }
        .about-page.style-2 .timeline .timeline-content .card-info h6 {
          font-weight: bold;
          font-size: 22px;
          margin-bottom: 10px;
          position: relative;
          z-index: 5;
          text-transform: capitalize; }
        .about-page.style-2 .timeline .timeline-content .card-info p {
          color: #666;
          position: relative;
          z-index: 5; }
        .about-page.style-2 .timeline .timeline-content .card-info .num {
          font-size: 100px;
          font-weight: 600;
          line-height: 1;
          position: absolute;
          top: 10px;
          right: 10px;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-image: -webkit-linear-gradient(30deg, transparent 0%, #f6f6f6 100%);
          background-image: -o-linear-gradient(30deg, transparent 0%, #f6f6f6 100%);
          background-image: linear-gradient(60deg, transparent 0%, #f6f6f6 100%);
          pointer-events: none; }
  .about-page.style-2 .clients-imgs .clients-content {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center; }
    .about-page.style-2 .clients-imgs .clients-content .client-logo {
      width: 180px;
      height: 85px;
      display: -webkit-inline-box;
      display: -ms-inline-flexbox;
      display: inline-flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      background-color: #fff;
      -webkit-box-shadow: 0px 16px 32px 0px #0000000f;
      box-shadow: 0px 16px 32px 0px #0000000f;
      border-radius: 5px;
      padding: 25px;
      margin: 15px; }
  .about-page.style-2 .clients-imgs .about2-imgs-slider .img {
    display: block;
    height: 480px;
    border-radius: 10px;
    overflow: hidden; }
  .about-page.style-2 .clients-imgs .about2-imgs-slider .swiper-wrapper {
    -webkit-transition-timing-function: linear !important;
    -o-transition-timing-function: linear !important;
    transition-timing-function: linear !important;
    position: relative; }

/* ===============================================================
			[ * services details page Style ] 
================================================================*/
/* --------------- faq-page style-5 --------------- */
.services-details-page.style-5 .ser-details p {
  color: #666;
  font-size: 16px; }

.services-details-page.style-5 .ser-details .main-info .main-img {
  height: 450px;
  overflow: hidden;
  border-radius: 10px;
  margin-bottom: 50px; }

.services-details-page.style-5 .ser-details .main-info .testi-card {
  text-align: center;
  padding: 4vw;
  background-color: #fff;
  -webkit-box-shadow: 0px 16px 32px 0px #0000000a;
  box-shadow: 0px 16px 32px 0px #0000000a;
  border: 1px solid #9993;
  border-radius: 5px; }
  .services-details-page.style-5 .ser-details .main-info .testi-card .text {
    font-size: 20px;
    margin: 30px auto 40px; }
  .services-details-page.style-5 .ser-details .main-info .testi-card .author {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center; }
    .services-details-page.style-5 .ser-details .main-info .testi-card .author .inf p {
      color: var(--color-blue5); }
    .services-details-page.style-5 .ser-details .main-info .testi-card .author .inf h6 {
      font-size: 18px;
      font-weight: bold; }

.services-details-page.style-5 .side-links {
  position: -webkit-sticky;
  position: sticky;
  top: 120px; }
  .services-details-page.style-5 .side-links .links-card {
    padding: 30px;
    border: 1px solid #9993;
    border-radius: 5px; }
    .services-details-page.style-5 .side-links .links-card h5 {
      font-size: 18px;
      font-weight: bold;
      padding-bottom: 15px;
      margin-bottom: 30px;
      border-bottom: 1px solid #9993; }
    .services-details-page.style-5 .side-links .links-card ul li {
      margin: 15px 0; }
      .services-details-page.style-5 .side-links .links-card ul li a .icon {
        color: var(--color-blue5);
        margin-right: 10px;
        -webkit-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease; }
      .services-details-page.style-5 .side-links .links-card ul li a:hover .icon {
        margin-right: 20px; }
  .services-details-page.style-5 .side-links .banner1 {
    padding: 40px;
    border-radius: 5px;
    text-align: center;
    background-image: url(../img/banner_back1.png);
    background-size: cover;
    position: relative; }
    .services-details-page.style-5 .side-links .banner1 .title {
      margin-bottom: 300px; }
      .services-details-page.style-5 .side-links .banner1 .title h6 {
        font-weight: 500;
        color: var(--color-blue5);
        margin-bottom: 10px; }
      .services-details-page.style-5 .side-links .banner1 .title h3 {
        font-size: 24px; }
    .services-details-page.style-5 .side-links .banner1 .mob {
      position: absolute;
      left: 0;
      bottom: 15px;
      height: 350px; }

.services-details-page.style-5 .contact.style-5 {
  position: relative; }
  .services-details-page.style-5 .contact.style-5::before {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    width: 100%;
    height: 400px;
    background-color: #f6f8fb; }
  .services-details-page.style-5 .contact.style-5 .contact-form {
    position: relative;
    z-index: 10;
    padding: 5vw;
    background-color: #fff;
    -webkit-box-shadow: 0 0 40px #0001;
    box-shadow: 0 0 40px #0001;
    border-radius: 5px; }
    .services-details-page.style-5 .contact.style-5 .contact-form .form-group {
      position: relative;
      margin-bottom: 30px; }
      .services-details-page.style-5 .contact.style-5 .contact-form .form-group .form-control {
        border: 0;
        border-radius: 5px;
        background-color: #f6f8fb;
        min-height: 50px;
        padding: 12px 15px 12px 40px; }
      .services-details-page.style-5 .contact.style-5 .contact-form .form-group .icon {
        position: absolute;
        top: 14px;
        left: 15px; }

/* ===============================================================
			[ * services details page Style ] 
================================================================*/
/* --------------- faq-page style-5 --------------- */
.comming-soon-page.style-5 {
  position: relative;
  color: #000; }
  .comming-soon-page.style-5 .comming-soon {
    background-image: url(../img/comming_back1.png);
    background-size: cover;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    min-height: 100vh;
    padding: 100px 0; }
    .comming-soon-page.style-5 .comming-soon .content {
      padding-top: 125px;
      text-align: center; }
      .comming-soon-page.style-5 .comming-soon .content .info h3 {
        font-size: 40px;
        margin-bottom: 30px; }
      .comming-soon-page.style-5 .comming-soon .content .info p {
        font-size: 16px;
        color: #666; }
      .comming-soon-page.style-5 .comming-soon .content .info .subscribe-form {
        margin-top: 50px; }
        .comming-soon-page.style-5 .comming-soon .content .info .subscribe-form .form-group {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          border-radius: 30px;
          border: 1px solid #9993;
          background-color: #fff;
          -webkit-box-shadow: 0 0 30px #0001;
          box-shadow: 0 0 30px #0001; }
          .comming-soon-page.style-5 .comming-soon .content .info .subscribe-form .form-group .icon {
            padding: 0 10px 0 30px;
            -ms-flex-negative: 0;
            flex-shrink: 0; }
          .comming-soon-page.style-5 .comming-soon .content .info .subscribe-form .form-group .form-control {
            border: 0; }
      .comming-soon-page.style-5 .comming-soon .content .info .timing {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        margin-bottom: 70px; }
        .comming-soon-page.style-5 .comming-soon .content .info .timing .item {
          padding: 20px 0;
          margin: 10px;
          width: 100%;
          background-color: #fff;
          border-radius: 5px;
          border-bottom: 3px solid var(--color-blue5); }
          .comming-soon-page.style-5 .comming-soon .content .info .timing .item h2 {
            font-size: calc(2vw + 20px); }
          .comming-soon-page.style-5 .comming-soon .content .info .timing .item small {
            font-size: 13px;
            color: #999;
            text-transform: capitalize; }
  .comming-soon-page.style-5 .comming_man_shape {
    position: absolute;
    left: 5%;
    top: calc(50% - 250px);
    width: 20%;
    height: 500px;
    -o-object-fit: contain;
    object-fit: contain;
    -o-object-position: center;
    object-position: center; }
  .comming-soon-page.style-5 .comming_chat_shape {
    position: absolute;
    right: 10%;
    top: calc(40% - 50px);
    width: 15%;
    height: 110px;
    -o-object-fit: contain;
    object-fit: contain;
    -o-object-position: center;
    object-position: center;
    -webkit-animation-delay: -1.3s;
    animation-delay: -1.3s; }
  .comming-soon-page.style-5 .comming_light_shape {
    position: absolute;
    right: 30%;
    bottom: 50px;
    width: 5%;
    height: 110px;
    -o-object-fit: contain;
    object-fit: contain;
    -o-object-position: center;
    object-position: center;
    -webkit-animation-delay: -0.7s;
    animation-delay: -0.7s; }

/* ===============================================================
			[ * 404 page Style ] 
================================================================*/
/* --------------- faq-page style-5 --------------- */
.erorr-404-page.style-5 {
  position: relative;
  color: #000; }
  .erorr-404-page.style-5 .erorr-page {
    background-image: url(../img/comming_back1.png);
    background-size: cover;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    min-height: 100vh;
    padding: 100px 0; }
    .erorr-404-page.style-5 .erorr-page .content {
      padding-top: 125px; }
      .erorr-404-page.style-5 .erorr-page .content .info .icon {
        width: 100px;
        margin-bottom: 30px; }
      .erorr-404-page.style-5 .erorr-page .content .img img {
        height: 550px; }

/* ===============================================================
			[ * 404 page Style ] 
================================================================*/
/* --------------- faq-page style-5 --------------- */
.signin-page.style-5 .signin {
  position: relative;
  color: #000;
  background-image: url(../img/comming_back1.png);
  background-size: cover;
  background-attachment: fixed;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  min-height: 100vh;
  padding: 100px 0; }
  .signin-page.style-5 .signin .form-content {
    background-color: #fff;
    -webkit-box-shadow: 0px 30px 60px 0px #0000000f;
    box-shadow: 0px 30px 60px 0px #0000000f;
    border-radius: 10px; }
    .signin-page.style-5 .signin .form-content .info {
      padding: 50px;
      text-align: center; }
      .signin-page.style-5 .signin .form-content .info .logo {
        display: block;
        padding-bottom: 40px;
        border-bottom: 1px solid #9993;
        margin-bottom: 40px; }
        .signin-page.style-5 .signin .form-content .info .logo img {
          width: 200px; }
      .signin-page.style-5 .signin .form-content .info .or-line {
        position: relative;
        text-align: center;
        margin: 25px 0; }
        .signin-page.style-5 .signin .form-content .info .or-line::after {
          position: absolute;
          content: "";
          left: 0;
          top: 50%;
          width: 100%;
          height: 1px;
          background-color: #9995; }
        .signin-page.style-5 .signin .form-content .info .or-line span {
          position: relative;
          width: 40px;
          height: 40px;
          display: -webkit-inline-box;
          display: -ms-inline-flexbox;
          display: inline-flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          border-radius: 50%;
          border: 1px solid #9995;
          background-color: #fff;
          z-index: 10; }
      .signin-page.style-5 .signin .form-content .info .group-icon {
        position: relative;
        font-size: 13px; }
        .signin-page.style-5 .signin .form-content .info .group-icon .form-control {
          padding: 18px 15px 18px 40px;
          font-size: 13px; }
        .signin-page.style-5 .signin .form-content .info .group-icon .icon {
          position: absolute;
          left: 15px;
          top: 20px; }
  .signin-page.style-5 .signin .sign-imgs-slider {
    position: relative;
    background-color: #b6c7e1;
    height: 100%; }
    .signin-page.style-5 .signin .sign-imgs-slider .swiper-pagination {
      position: absolute;
      top: 35px; }
      .signin-page.style-5 .signin .sign-imgs-slider .swiper-pagination .swiper-pagination-bullet {
        margin: 0 5px;
        width: 40px;
        height: 4px;
        border-radius: 0;
        background: #fff; }
        .signin-page.style-5 .signin .sign-imgs-slider .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
          border-radius: 0;
          color: #fff; }
    .signin-page.style-5 .signin .sign-imgs-slider .slide-card {
      text-align: center;
      padding-top: 80px; }
      .signin-page.style-5 .signin .sign-imgs-slider .slide-card h3 {
        font-size: 36px;
        margin-bottom: 50px;
        color: #fff; }
      .signin-page.style-5 .signin .sign-imgs-slider .slide-card .img {
        padding: 0 50px; }
        .signin-page.style-5 .signin .sign-imgs-slider .slide-card .img img {
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
          -o-object-fit: cover;
          object-fit: cover;
          -o-object-position: bottom;
          object-position: bottom; }

@media screen and (max-width: 991px) {
  .signin-page.style-5 .signin .form-content .info {
    padding: 30px 20px; }
    .signin-page.style-5 .signin .form-content .info .butn {
      width: 100%; } }

/* ===============================================================
			[ * responsive style ] 
================================================================*/
/* -------------------- responsive style ------------------------- */
@media screen and (max-width: 1100px) {
  .screenshots.style-4 .screenshots-slider .img {
    margin: 0 auto;
    width: 190px; } }

@media screen and (max-width: 991px) {
  .section-padding {
    padding: 70px 0; }
  .section-head h2 {
    font-size: 25px; }
  .top-navbar.style-1 .top-links {
    display: none; }
  .top-navbar.style-1 .r-side {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 10px 0; }
  .navbar.style-1 {
    border-bottom: 1px solid #9993; }
    .navbar.style-1 .nav-side {
      -ms-flex-wrap: wrap;
      flex-wrap: wrap; }
      .navbar.style-1 .nav-side .hotline {
        width: 100%;
        margin-bottom: 10px;
        border: 0; }
  .portfolio.style-1 .portfolio-slider .swiper-button-next,
  .portfolio.style-1 .portfolio-slider .swiper-button-prev {
    display: none; }
  header.style-1 .info {
    margin-bottom: 30px; }
    header.style-1 .info .text {
      width: 100%; }
  .about.style-1 .content {
    padding: 30px; }
    .about.style-1 .content .num-item {
      padding: 15px;
      border: 1px solid #fff5;
      margin: 5px 0; }
  .testimonials.style-1 .content .vid_img {
    min-height: auto; }
  .testimonials.style-1 .content .info {
    padding: 0; }
    .testimonials.style-1 .content .info::after {
      display: none; }
  .choose_us.style-1 .choose-us-img,
  .choose_us.style-1 .animated_logos,
  .choose_us.style-1 .choose-us-brands,
  .choose_us.style-1 .choose-us-bubbles {
    display: none; }
  .choose_us.style-1 .info .btn {
    margin-top: 30px; }
  .team.style-1 .content .team_box {
    width: 100%; }
  .blog.style-1 .blog_slider .swiper-button-next,
  .blog.style-1 .blog_slider .swiper-button-prev {
    position: static;
    margin-top: 40px !important;
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; }
  .contact.style-1 .content .contact_info ul {
    margin: 15px 0; }
  .contact.style-1 .content .contact_form {
    margin-top: 30px; }
  footer.style-1 .links {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start; }
  .navbar.style-2 {
    padding: 25px 0 !important;
    position: relative !important;
    background: var(--color-main-grad); }
    .navbar.style-2 .navbar-collapse {
      margin-top: 30px; }
    .navbar.style-2 .container {
      border: 0; }
    .navbar.style-2 .qoute-nav .btn {
      margin-left: auto;
      margin-right: 0; }
  header.style-2 br {
    display: none; }
  .section-head h2 {
    letter-spacing: 3px;
    margin-bottom: 30px; }
  .choose_us.style-2 {
    overflow: hidden; }
    .choose_us.style-2 .choose-numbers {
      margin-top: 50px; }
      .choose_us.style-2 .choose-numbers .num-item {
        width: 100%;
        border: 1px solid #fff3;
        margin: 5px 0;
        padding: 15px;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
        .choose_us.style-2 .choose-numbers .num-item br {
          display: none; }
        .choose_us.style-2 .choose-numbers .num-item .num {
          width: 100%;
          text-align: initial; }
  .reviews_card.style-2 br {
    display: none; }
  .pricing.style-2 {
    overflow: hidden; }
    .pricing.style-2 .pricing_card.style-2 {
      border: 1px solid var(--color-lightBlue) !important;
      margin: 10px 0 !important;
      border-radius: 0 !important; }
    .pricing.style-2 .mt-50 {
      margin-top: 30px; }
  .services_projects .service-box {
    border: 0 !important; }
  .blog.style-2 {
    overflow: hidden; }
    .blog.style-2 .card {
      padding: 10px;
      border: 1px solid #fff3 !important; }
      .blog.style-2 .card h4 {
        font-size: 1rem !important; }
    .blog.style-2 .mt-80 {
      margin-top: 40px; }
  .contact.style-2 {
    padding-bottom: 150px; }
    .contact.style-2 h2 {
      letter-spacing: 1px; }
    .contact.style-2 .global_2 {
      bottom: -120px; }
    .contact.style-2 .mb-70 {
      margin-bottom: 30px; }
    .contact.style-2 .mb-50 {
      margin-bottom: 20px; }
  footer.style-2 .foot_links {
    text-align: initial !important;
    margin: 30px 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
    footer.style-2 .foot_links a {
      width: 50%;
      margin: 10px 0 !important; }
  footer.style-2 .text-end,
  footer.style-2 .text-center {
    text-align: initial !important; }
  .navbar.style-3 {
    position: relative !important;
    background-color: var(--color-blue2);
    padding: 20px 0; }
    .navbar.style-3 .navbar-collapse {
      margin-top: 15px; }
  header.style-3 {
    padding: 0 0 30px; }
    header.style-3 .main-img {
      display: none; }
    header.style-3 .content .info .h1 {
      font-size: 28px; }
      header.style-3 .content .info .h1 span:before {
        width: 100px;
        background-size: 100px; }
    header.style-3 .content .info .h5 {
      margin-top: 40px; }
  .style-3 .testi_lines {
    width: calc(100% - 30px) !important; }
  .about.style-3 .img-left {
    position: relative;
    top: 0;
    max-width: 100%;
    margin: 50px 0; }
    .about.style-3 .img-left .info-circle {
      width: 150px;
      height: 150px;
      outline: 7px solid #fff; }
      .about.style-3 .img-left .info-circle h2 {
        font-size: 35px; }
      .about.style-3 .img-left .info-circle small {
        font-size: 10px;
        text-transform: uppercase; }
      .about.style-3 .img-left .info-circle:nth-of-type(2) {
        width: 120px;
        height: 120px; }
        .about.style-3 .img-left .info-circle:nth-of-type(2) h2 {
          font-size: 30px; }
      .about.style-3 .img-left .info-circle:nth-of-type(3) {
        width: 100px;
        height: 100px; }
        .about.style-3 .img-left .info-circle:nth-of-type(3) h2 {
          font-size: 25px; }
      .about.style-3 .img-left .info-circle:nth-of-type(4) {
        width: 110px;
        height: 110px; }
        .about.style-3 .img-left .info-circle:nth-of-type(4) h2 {
          font-size: 20px; }
  .about.style-3 .info .mb-70 {
    margin-bottom: 30px !important; }
  .about.style-3 .btm-content {
    min-height: -webkit-max-content;
    min-height: -moz-max-content;
    min-height: max-content; }
    .about.style-3 .btm-content .mt-60 {
      margin-top: 30px !important; }
  .about.style-3 .btm-content .img-right {
    display: none; }
  .about.style-3 .top-content {
    margin-bottom: 30px; }
  .pricing.style-3 .info {
    margin-bottom: 50px; }
  .pricing.style-3 .text.mb-30 {
    margin-bottom: 15px !important; }
  .pricing.style-3 .mt-60 {
    margin-top: 30px !important; }
  .section-head.style-3 h3 {
    font-size: 21px; }
  .blog.style-3 .card .img {
    height: 150px;
    margin-bottom: 5px; }
  .blog.style-3 .v_lines {
    display: none; }
  .blog.style-3 .client-logos {
    margin-top: 30px !important; }
    .blog.style-3 .client-logos .img {
      margin: 20px 0; }
  .chat-banner.style-3 .bttns {
    margin-top: 60px;
    text-align: start !important; }
  footer.style-3 .items {
    margin-bottom: 50px; }
    footer.style-3 .items .title {
      margin-bottom: 20px; }
  footer.style-3 .foot {
    margin-top: 0; }
    footer.style-3 .foot .logo {
      margin-bottom: 20px; }
  .fs-10px {
    font-size: 8px !important; }
  .navbar.style-4 .navbar-nav .nav-item .nav-link:before {
    display: none; }
  .navbar.style-4 {
    background-color: #f0eff5;
    padding: 20px 0; }
    .navbar.style-4 .navbar-collapse {
      margin-top: 30px; }
  .style-4 .mb-60 {
    margin-bottom: 30px !important; }
  .style-4 .mb-70 {
    margin-bottom: 40px !important; }
  .style-4 br {
    display: none; }
  header.style-4 {
    overflow: hidden; }
    header.style-4 .content .info {
      margin-bottom: 50px; }
      header.style-4 .content .info h1 {
        font-size: 25px;
        line-height: 40px;
        margin-bottom: 15px !important; }
      header.style-4 .content .info .title_small {
        margin-bottom: 20px !important; }
      header.style-4 .content .info .mt-100 {
        margin-top: 50px !important; }
      header.style-4 .content .info .mt-50 {
        margin-top: 30px !important; }
      header.style-4 .content .info br {
        display: none; }
      header.style-4 .content .info .small, header.style-4 .content .info small,
      header.style-4 .content .info .btn {
        font-size: 10px; }
  .clients.style-4 .client-logos .img {
    margin: 20px 0;
    text-align: center; }
  .features.style-4 .content {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column; }
    .features.style-4 .content .features-card {
      margin: 15px 0; }
  .about.style-4 .content {
    padding: 40px 0; }
  .testimonials.style-4 {
    overflow: hidden; }
    .testimonials.style-4 .content .testi-cards .client_card {
      right: 0 !important;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      margin: 20px 0 !important; }
      .testimonials.style-4 .content .testi-cards .client_card .user_img {
        margin-bottom: 30px; }
      .testimonials.style-4 .content .testi-cards .client_card .inf_content {
        text-align: center; }
        .testimonials.style-4 .content .testi-cards .client_card .inf_content br {
          display: none; }
  .pricing.style-4 .content .row .col-lg-6:first-of-type .price-card {
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    border: 1px solid #e9e8ee;
    margin-right: 0;
    margin-bottom: 30px; }
  .pricing.style-4 .content .row .col-lg-6:nth-of-type(2) .price-card {
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px; }
  .faq.style-3.style-4 {
    overflow: hidden; }
    .faq.style-3.style-4 .col-lg-6:nth-of-type(1) .accordion-item.border-0 {
      border-bottom: 1px solid #9995 !important; }
  .community.style-4 .content,
  .community.style-5 .content {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    border-radius: 20px !important; }
    .community.style-4 .content .commun-card,
    .community.style-5 .content .commun-card {
      width: 100%;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: start;
      padding: 30px !important; }
  .section-head.style-4 h2 {
    font-size: 25px; }
  footer.style-4 {
    margin-top: 120px; }
    footer.style-4 .d-flex {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column; }
      footer.style-4 .d-flex .btn {
        width: 100%;
        margin: 10px 0; }
        footer.style-4 .d-flex .btn.me-4 {
          margin-right: 0 !important; }
    footer.style-4 .foot .links {
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      margin: 30px 0; }
      footer.style-4 .foot .links li {
        width: 50%;
        margin: 5px 0; }
        footer.style-4 .foot .links li a {
          margin-left: 0; }
    footer.style-4 .wave {
      left: 0;
      width: 100%; }
  .screenshots.style-4 .screenshots-slider {
    width: 100%;
    left: 0; }
  .screenshots.style-4 .screenshots-slider .img {
    margin: 0 auto;
    width: 190px; }
  footer.style-4 .foot .logo {
    width: 160px; }
  .navbar.style-5 {
    border-bottom: 1px solid #9999; }
  .navbar.style-5 .navbar-collapse {
    margin-top: 20px;
    padding: 15px 0;
    background: #fff9; }
  .navbar.style-5 .nav-item .nav-link {
    padding-left: 0; }
  .section-head.style-5 h2 {
    font-size: 30px; }
  .about.style-5 .content .img {
    overflow: hidden;
    margin-bottom: 30px;
    padding-bottom: 50px; }
  .about.style-5 .content .img.main-img3 .img-body {
    max-height: 300px; }
  .download.style-5 .content h2 {
    font-size: 28px; }
  .download.style-5 .content h2 span {
    padding-bottom: 10px; }
  .download.style-5 .btn {
    margin: 10px 0; }
  .download.style-5 .btn.sm-butn span {
    font-size: 10px; }
  footer.style-5 .items {
    margin-bottom: 50px; }
    footer.style-5 .items .title {
      margin-bottom: 20px; }
  footer.style-5 .foot {
    margin-top: 0 !important; }
    footer.style-5 .foot .logo {
      margin-bottom: 20px; }
  .style-6 br {
    display: none; }
  .navbar.style-6 .container-fluid {
    padding: 0 20px; }
    .navbar.style-6 .container-fluid .navbar-collapse {
      margin-top: 20px; }
  header.style-6 .content {
    padding: 100px 0 20px; }
  header.style-6 .info h1,
  header.style-6 .info h1 span small {
    font-size: 30px;
    line-height: 1.5; }
  header.style-6 .info .text {
    margin-top: 20px; }
    header.style-6 .info .text br {
      display: none; }
  header.style-6 .info .form {
    border-radius: 15px; }
    header.style-6 .info .form .form-group {
      width: 100%;
      margin: 8px 0; }
    header.style-6 .info .form .btn {
      width: 100%;
      margin-top: 15px; }
  .navbar.style-6 .nav-item .nav-link:after {
    display: inline-block !important; }
  header.style-6 {
    padding: 0; }
    header.style-6 .content {
      border-radius: 0; }
  .section-head.style-6 h2,
  .section-head.style-6 h2 span small {
    font-size: 30px; }
  .about.style-6 {
    overflow: hidden; }
    .about.style-6 .info {
      margin-bottom: 30px; }
  .service-card.style-6 {
    margin: 15px; }
  .section-head.d-flex {
    display: block !important; }
  .projects.style-6 {
    overflow: hidden;
    padding: 0; }
    .projects.style-6 .content {
      border-radius: 0; }
      .projects.style-6 .content .project-card.style-6 {
        margin: 0; }
  .testimonials.style-6 {
    overflow: hidden; }
  .choose-us.style-6 {
    overflow: hidden; }
    .choose-us.style-6 .info {
      padding: 0 15px;
      margin-top: 30px; }
  .testimonials.style-6 .img {
    margin-top: 150px; }
  .clients.style-5 .content .img.img-card {
    width: 47%; }
  .number-card.style-6 {
    margin: 15px 0;
    border: 0 !important; }
  .team.style-6 {
    padding: 0; }
    .team.style-6 .content {
      border-radius: 0;
      padding: 70px 0; }
      .team.style-6 .content .text-end {
        text-align: left !important;
        margin-top: 10px; }
  .team-card.style-6 .img .social-icons a {
    -webkit-transform: translateY(0) !important;
    -ms-transform: translateY(0) !important;
    transform: translateY(0) !important;
    opacity: 1; }
  .blog.style-6 .content {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
    .blog.style-6 .content .blog-card {
      width: 100% !important;
      margin-bottom: 30px; }
      .blog.style-6 .content .blog-card:last-of-type {
        margin: 0; }
      .blog.style-6 .content .blog-card::after, .blog.style-6 .content .blog-card::before {
        display: none; }
  footer.style-6 {
    padding-top: 40px; }
    footer.style-6 .items {
      margin-top: 30px; }
      footer.style-6 .items .title {
        margin-bottom: 15px; }
    footer.style-6 .foot {
      padding-top: 30px;
      line-height: 1.8; }
      footer.style-6 .foot .logo {
        margin-bottom: 20px; }
  .blog-page.style-1 .popular-posts .border-end {
    border: 0 !important; }
  .about-page header.style-5 .info h1 {
    font-size: 30px; }
  .about-page header.style-5 .main-vid {
    height: 350px; }
  .about-page .choose-us.style-6 .info {
    padding: 30px 15px; }
  .about-page .clients .section-head {
    padding: 0 15px; }
  .about-page .culture {
    padding: 70px 15px; }
  .contact.style-6 .content .contact_message {
    right: 0;
    top: 30px; }
  .contact-page.style-5 .contact .content .contact_message {
    right: 0;
    top: 30px; }
  .contact-page.style-5 .section-head {
    margin-bottom: 50px !important; }
  .contact-page.style-5 .mb-100 {
    margin-bottom: 70px !important; }
  .contact-page.style-5 .ltspc-20 {
    letter-spacing: 3px !important;
    margin-bottom: 30px !important; }
  .contact-page.style-5 h4 {
    font-size: 18px; }
  .shop-page .filter {
    margin-bottom: 30px; }
  .shop-page .products-content .products.list-view .card-width {
    width: 100%; }
  .product-page .related-products .related-products-slider .swiper-button-next,
  .product-page .related-products .related-products-slider .swiper-container-rtl .swiper-button-prev {
    right: 5px; }
  .product-page .related-products .related-products-slider .swiper-button-prev,
  .product-page .related-products .related-products-slider .swiper-container-rtl .swiper-button-next {
    left: 5px; }
  .product-page .product-slider {
    overflow: hidden; }
  .product-page .product-slider {
    margin-bottom: 50px; }
  .product-page .product-slider .gallery-top .swiper-slide .img,
  .product-page .product-slider .gallery-thumbs {
    height: 370px; }
  .product-page .product-details .nav-pills .nav-link {
    font-size: 18px; }
  .product-page .product-info .qyt-addCart .add-more {
    width: 25%; }
  .top-navbar.style-7 .top_info,
  .top-navbar.style-7 .side_links {
    font-size: 8px; }
  .top-navbar.style-7 .me-4 {
    margin-right: 5px !important; }
  .navbar.style-7 .navbar-nav,
  .navbar.style-9 .navbar-nav {
    padding: 20px 0 !important; }
    .navbar.style-7 .navbar-nav .nav-item .nav-link,
    .navbar.style-9 .navbar-nav .nav-item .nav-link {
      margin: 15px 0;
      border: 0; }
  header.style-7 .info h1 {
    font-size: 35px; }
  header.style-7 .img .rock7,
  .about.style-7 .img .circle {
    right: 0;
    width: 30%; }
  header.style-7 .sponsers br {
    display: none; }
  header.style-7 .sponsers .sponsers-content a {
    margin: 10px 10px 10px 0;
    width: 20%; }
  .services.style-7 .service-item-style7 {
    padding: 30px;
    background-color: #fff; }
  .projects.style-7 .content {
    padding: 0 15px; }
  .projects.style-7 .projects-slider7 .swiper-slide {
    margin-top: 50px; }
  .testimonials.style-7 .testi-card .text {
    font-size: 18px; }
  .testimonials.style-7 .get-qoute-banner {
    padding: 30px; }
    .testimonials.style-7 .get-qoute-banner .btns {
      margin-top: 20px; }
  .choose-us.style-7 .info {
    padding: 30px 0; }
  .section-head.style-4 h2 {
    font-size: 18px; }
  .chat-banner.style-7 .info h3 {
    font-size: 30px; }
  footer.style-7 {
    overflow: hidden; }
    footer.style-7 .links-side {
      padding: 30px 0; }
      footer.style-7 .links-side .links-group {
        margin-top: 30px; }
    footer.style-7 .foot p {
      font-size: 12px; }
  .features.style-7 {
    padding: 100px 15px; }
    .features.style-7 .features-card.style-7 {
      padding: 15px; }
      .features.style-7 .features-card.style-7 .icon img {
        width: 80px;
        height: 80px; }
      .features.style-7 .features-card.style-7 .info h5 {
        font-size: 18px; }
  header.style-8 .info h1 {
    font-size: 40px; }
  .services.style-8 h5 {
    font-size: 15px;
    margin-bottom: 10px; }
  .section-head.style-8 h3 {
    font-size: 30px; }
  .numbers.style-8 .content .plat-icons .icon {
    width: 50px !important;
    height: 50px !important;
    padding: 10px !important; }
  .projects.style-8 .section-head {
    position: relative;
    padding-bottom: 65px; }
  .projects.style-8 .section-head .swiper-button-next,
  .projects.style-8 .section-head .swiper-button-prev {
    bottom: 0;
    top: auto; }
  .projects.style-8 .section-head .swiper-button-prev,
  .projects.style-8 .section-head .swiper-container-rtl .swiper-button-next {
    left: 0;
    right: auto; }
  .projects.style-8 .section-head .swiper-button-next,
  .projects.style-8 .section-head .swiper-container-rtl .swiper-button-prev {
    left: 75px;
    right: auto; }
  .projects.style-8 .info {
    padding: 30px 0 0 0; }
  .projects.style-8 .info .proj-det .item p {
    font-size: 11px; }
  .projects.style-8 .info .proj-det .item h6 {
    font-size: 14px; }
  .pricing.style-8 .pricing-tabsHead {
    margin-top: 80px; }
  .pricing.style-8 .pricing-body .pricing-card {
    border-radius: 10px; }
  .blog.style-8 .main-post {
    margin-bottom: 50px; }
  .blog.style-8 .side-posts .item .info h4.title {
    font-size: 18px; }
  footer.style-8 .content .logo-social .foot-logo img {
    margin-bottom: 30px; }
  header.style-9 {
    overflow: hidden; }
    header.style-9 .img {
      width: 100%;
      margin-top: 50px; }
    header.style-9 .info h1 {
      font-size: 30px; }
  .hosting-links a {
    margin: 10px 5px !important; }
  .chat-banner.style-9 .img_back {
    display: none; }
  .top-navbar.style-9 a {
    font-size: 12px; }
  .top-navbar.style-9 .side_links a {
    margin: 5px !important;
    font-size: 12px; }
  .top-navbar.style-9 .ps-4 {
    padding-left: 0.5rem !important; }
  .testimonials.style-9 {
    overflow: hidden; }
  .section-head.style-9 h2 {
    font-size: 30px; }
    .section-head.style-9 h2 br {
      display: none; }
  .domain-search.style-9 .domain-choose .butn {
    padding: 15px; }
  .about.style-9 .info {
    padding: 30px 0 0; }
  .services.style-9 .services-content .service-card {
    margin-top: 30px; }
  .services.style-9 .services-content::before {
    display: none; }
  .chat-banner.style-9 {
    padding: 100px 0; }
  .choose-us.style-9 .nav .nav-item {
    width: 100%;
    border-bottom: 1px solid #9993; }
    .choose-us.style-9 .nav .nav-item .nav-link {
      width: 100%;
      text-align: start; }
  .testimonials.style-9 .testi-head h3 {
    margin-bottom: 20px;
    font-size: 25px; }
  .testimonials.style-9 .testi-card {
    margin-bottom: 50px;
    border: 1px solid #9993;
    border-radius: 10px;
    padding: 30px; }
  .testimonials.style-9 .testi-card .text {
    min-height: -webkit-max-content;
    min-height: -moz-max-content;
    min-height: max-content;
    margin-bottom: 30px; }
  .clients.style-9 .logos-content .logos a {
    width: 180px;
    height: 100px;
    background-color: #fff;
    border-radius: 10px;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center; }
  footer .foot {
    margin-top: 40px !important; }
    footer .foot .info {
      padding: 30px 0 !important; }
  footer.style-9 .foot-title {
    margin-bottom: 20px;
    margin-top: 40px; }
  .inner-header.style-5 .content {
    padding-right: 50px; }
    .inner-header.style-5 .content h2 {
      font-size: 30px; }
  .faq-page.style-5 .faq-tabs .nav .nav-item {
    width: 50%;
    margin-top: 30px; }
  .inner-header.style-5 .content .side-img {
    top: 40%;
    height: 20%; }
  .careers-page.style-5 .career-form .mum-card h3,
  .career-details-page.style-5 .career-form .mum-card h3 {
    font-size: 30px; }
  .careers-page.style-5 .career-form .form,
  .career-details-page.style-5 .career-form .form {
    margin-top: 30px; }
  .careers-page.style-5 .career-form,
  .career-details-page.style-5 .career-form {
    overflow: hidden; }
    .careers-page.style-5 .career-form h2,
    .career-details-page.style-5 .career-form h2 {
      font-size: 30px; }
  .career-details-page p {
    width: 100%; }
  .career-details-page.style-5 .careers-positions-slider5 {
    padding-bottom: 100px; }
  .career-details-page.style-5 .careers-positions-slider5 .swiper-button-next,
  .career-details-page.style-5 .careers-positions-slider5 .swiper-button-prev {
    top: calc(100% - 60px); }
  .pricing.style-10 .pricing-table .table-titles,
  .pricing.style-10 .pricing-table .table-body-card {
    width: 100%; }
  .pricing.style-10 .pricing-table .table-titles .main-head {
    background-color: #f9f9f9;
    border-radius: 15px; }
  .pricing.style-10 .pricing-table .table-body-card {
    padding: 15px;
    border: 1px solid #9994;
    border-radius: 10px !important;
    margin-top: 30px; }
  .pricing.style-10 .pricing-table .table-body-card .sub-body ul li strong {
    display: block;
    margin-bottom: 10px;
    -webkit-margin-end: 0;
    margin-inline-end: 0; }
  .pricing.style-10 .pricing-table .sub-head {
    text-align: center; }
  .pricing.style-10 .pricing-table .table-body-card.recommended-card .sub-body {
    border: 15px solid #fff2; }
  header.style-10 .img {
    width: 100%;
    margin: 30px 0; }
  header.style-10 .info h1 {
    font-size: 35px; }
  header.style-10 .info .butn, header.style-10 .info .btn {
    padding: 12px 48px 12px 15px; }
  header.style-10 .info .butn small, header.style-10 .info .btn small {
    font-size: 10px; }
  header.style-10 .info .butn.btn-icon-circle i, header.style-10 .info .btn.btn-icon-circle i {
    bottom: -6px; }
  header.style-10 .info .me-4 {
    margin-right: 0.5rem !important; }
  header.style-10 .logos .logos-content {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
    header.style-10 .logos .logos-content .logo_img {
      padding: 10px;
      width: 45px; }
  .features.style-10 .feat-card {
    border-bottom: 1px solid #9995;
    border-right: 0; }
  .about.style-10 .imgs .img {
    margin: 0 0 30px !important;
    height: 250px !important; }
  .about.style-10 .info {
    padding: 0; }
    .about.style-10 .info h2 {
      font-size: 25px; }
  .services.style-10 {
    overflow: hidden; }
    .services.style-10 .content {
      padding: 60px 15px 15px; }
      .services.style-10 .content .services-card {
        position: relative;
        z-index: 10;
        padding: 20px 15px;
        border-radius: 10px;
        background-color: #f9f9f9;
        margin-top: 15px; }
  .faq.style-10::after {
    display: none; }
  .faq.style-10 .integration-card .content {
    padding: 50px 30px; }
    .faq.style-10 .integration-card .content .icons {
      margin-top: 40px; }
      .faq.style-10 .integration-card .content .icons .icon {
        position: relative;
        top: 0;
        left: 0;
        bottom: 0;
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
        margin: 5px;
        width: 50px;
        height: 50px;
        padding: 15px; }
  .blog.style-10 .blog-card .img {
    width: 100%; }
    .blog.style-10 .blog-card .img img {
      width: 100%;
      height: 220px; }
  .blog.style-10 .blog-card .info {
    width: 100%; }
  footer.style-10 .foot {
    margin-top: 0 !important; }
  footer.style-10 .links {
    text-align: start;
    margin: 30px 0; }
    footer.style-10 .links a {
      margin: 15px 0 !important;
      display: block;
      width: -webkit-max-content;
      width: -moz-max-content;
      width: max-content; }
  .home-style-11 .section-head h2 {
    font-size: 20px; }
  .home-style-11 .top-navbar.style-11 .links a {
    font-size: 11px; }
  .home-style-11 .top-navbar.style-11 .top-nav-side {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    margin-top: 10px; }
    .home-style-11 .top-navbar.style-11 .top-nav-side a, .home-style-11 .top-navbar.style-11 .top-nav-side p {
      font-size: 11px; }
  .home-style-11 .navbar.style-11 .navbar-nav {
    padding: 20px 0 !important; }
  .home-style-11 header.style-11 {
    overflow: hidden; }
    .home-style-11 header.style-11 .info h1 {
      font-size: 30px; }
    .home-style-11 header.style-11 .img {
      width: 100%; }
  .home-style-11 .signup.style-11 {
    overflow: hidden; }
    .home-style-11 .signup.style-11 .text {
      -ms-flex-wrap: wrap;
      flex-wrap: wrap; }
      .home-style-11 .signup.style-11 .text span {
        width: 100%;
        margin: 5px 0; }
  .home-style-11 .screenshots.style-11 {
    overflow: hidden; }
    .home-style-11 .screenshots.style-11 .links {
      margin-bottom: 60px; }
      .home-style-11 .screenshots.style-11 .links .img {
        margin: 10px 0; }
        .home-style-11 .screenshots.style-11 .links .img::after {
          display: none; }
  .home-style-11 .features.style-11 {
    overflow: hidden; }
    .home-style-11 .features.style-11 .features-cards .cards-side .feat-card {
      margin-top: 30px; }
  .home-style-11 .integration.style-11 .icons {
    display: none; }
  .home-style-11 .features.style-9 {
    overflow: hidden; }
    .home-style-11 .features.style-9 .accordion .accordion-button {
      -webkit-box-align: start;
      -ms-flex-align: start;
      align-items: start; }
    .home-style-11 .features.style-9 .img {
      margin-top: 50px; }
  .home-style-11 .testimonials.style-11 {
    overflow: hidden; }
    .home-style-11 .testimonials.style-11 .testimonial-card .img .play_icon {
      width: 70px;
      height: 70px;
      border-radius: 50%;
      line-height: 70px;
      bottom: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, 50%);
      -ms-transform: translate(-50%, 50%);
      transform: translate(-50%, 50%); }
    .home-style-11 .testimonials.style-11 .testimonial-card .info h4 {
      font-size: 18px; }
  .home-style-11 footer.style-11 {
    overflow: hidden; }
    .home-style-11 footer.style-11 .top-foot-info strong {
      width: 100%;
      display: block;
      margin-top: 30px;
      margin-bottom: 20px; }
    .home-style-11 footer.style-11 .top-foot-info a {
      margin: 10px 0 !important; } }

/* ======== */
/* ======= */
